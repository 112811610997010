import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  RootContainer,
  PlayerTopNav,
  MenuContainer,
  MenuBtn,
  TopbarMenuHoverContainer,
  MenuItem,
  MenuEmbedIcon,
  MenuShareIcon,
  MenuLockIcon,
  MenuHomeIcon,
  TopbarSpaceFiller,
  TopbarRightContent,
  SearchIcon,
  SearchInput,
  EmbedIcon,
  ShareIcon,
  KeyboardShortcutKeyIcon,
  PlayerContainer,
  BulkUploadInput,
  SummaryContainer,
  VideoContainer,
  ChaptersTitleDiv,
  SummaryContainerHeader,
  SummaryContainerText,
  SummaryListContainer,
  SearchContainer,
  NoSummariesContainer,
  NoSummariesText,
  AddChapterButton,
  SummaryInput,
  VideoTitleContainer,
  VideoTitleText,
  ChannelTitleText,
  CopyContentContainer,
  CopyTextHeading,
  CopyTextContent,
  ModalContainer,
  KeyboardShortcutContainer,
  KeyboardShortcutCard,
  KeyboardShortcutName,
  KeyboardShortcutKey,
  CopyIconContainer,
  CopyTextContainer,
  MLLoadingContainer,
  Circle,
  FeedBackIcon,
  SignupFormContainer,
  TextInputLabel,
  TextInput,
  Errors,
  Button,
} from "./Garage.styles";
import SquareLoader from "react-spinners/SquareLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Summary from "./SummaryCard";
import { VideoPlayer } from "../../components/VideoPlayer.js";
import {
  LoadingOverlay,
  Modal,
  HorizontalSpace,
  VerticalSpace,
} from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { summaryActions } from "modules/summaries";
import { authActions } from "modules/auth";
import { useParams, useLocation } from "react-router-dom";
import {
  BiMenuAltRight,
  BiCheck,
  BiEditAlt,
  BiLink,
  BiPaste,
} from "react-icons/bi";
import { HiCode } from "react-icons/hi";
import { FaKeyboard } from "react-icons/fa";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import keyboardShortcuts from "./assignedKeyboardSortcuts";
import { default as Loginpage } from "../Login/Login";
import { default as Signuppage } from "../Signup/Signup";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import validate from "validate.js";
import { signupValidations } from "./constraints";

const windowLocation = window.location.href;

let showBackToHome = false,
  postAction,
  showedDetectedSummary = false,
  showedMlModel = false,
  searchText = "";
let bulkUploadText = "";

// console.log("LOCATION", windowLocation);

const Input = ({ placeholder, onChange, label, value }) => {
  return (
    <>
      <TextInputLabel>{label}</TextInputLabel>
      <TextInput
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
      />
    </>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const mode = query.get("mode");
  const [videoTitle, setVideoTitle] = useState("");
  const [channelTitle, setChannelTitle] = useState("");
  const [editVideoTitle, setEditVideoTitle] = useState(false);
  const [previousState, setPreviousState] = useState(false);
  const [detectedSummaryModal, setDetectedSummaryModal] = useState(false);
  const [useMLModal, setUseMLModal] = useState(false);
  const [MLLoadingModal, setMLLoadingModel] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [detectedSummary, setDetectedSummary] = useState([]);
  const [currentMlStatus, setCurrentMlStatus] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [mlChapters, setMlChapters] = useState([]);
  const [currentTime, setCurrentTime] = useState(0);
  const [isSignUp, setIsSignUp] = useState(true);
  const [showTestPilotModel, setShowTestPilotModel] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ name: [], password: [] });
  const summaryHeader = useRef(0);
  const currentChapterCard = useRef(0);
  const addChapterBtn = useRef(0);
  const saveChangesBtn = useRef(0);
  const playFunction = useRef();
  const videeTitleInputRef = useRef(null);
  const colorElementsAry = [
    summaryHeader,
    currentChapterCard,
    addChapterBtn,
    saveChangesBtn,
  ];
  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const playerState = useSelector((state) => state.playerReducer, shallowEqual);
  const authState = useSelector((state) => state.authReducer, shallowEqual);
  const { videoId } = useParams();
  const db = getFirestore();

  const onRequestSummary = () => {
    const body = {
      accountId: authState.accountId,
      videoId,
      videoTitle: summaryObject.metadata.title,
      platform: "youtube",
      createdOn: moment(),
    };
    dispatch(summaryActions.createRequestAsyncActions.request(body));
  };

  const onSearch = (text, summaries) => {
    searchText = text;

    const searchResults = summaries.filter((summary) => {
      let toInclude = false;

      // if (text.length == 0)
      // 	return true;

      if (summary && summary.type && summary.type === "INSERT") {
        toInclude = true;
      }

      if (summary.subpoints) {
        const subpointArray = summary.subpoints.map((subpoint) =>
          subpoint.title.toLowerCase().includes(text.toLowerCase())
        );
        toInclude = subpointArray.some((res) => res === true);
      }

      if (
        summary.title &&
        summary.title.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.summary &&
        summary.summary.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.description &&
        summary.description.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }
      return toInclude;
    });

    setFilteredSummaries(searchResults || []);
  };

  const welcomeToast = (logginIn) => {
    let placeholder;
    if (logginIn) placeholder = "back " + authState.name;
    else placeholder = authState.name;

    toast.success(`Welcome ${placeholder} to ChapterMe 🎉`);
  };

  const createNewChapter = () => {
    // console.log(playFunction.current)
    playFunction.current.pauseVideo();
    let source = [];
    if (filteredSummaries) source = [...filteredSummaries];

    setPreviousState([...source]);
    const newChapter = {
      title: "",
      description: "",
      start: playerState.currentPlayingTime,
      type: "INSERT",
    };
    source.push(newChapter);
    const sortedChapters = [...source].sort((a, b) => a.start - b.start);
    setCurrentTime(playerState.currentPlayingTime);
    // dispatch(summaryActions.mutateSummariesAction.action({summaries: sortedChapters}));
    setFilteredSummaries(sortedChapters);
    onVideoTitleChange();
  };

  const updateChapterContent = (index, field, value) => {
    const source = [...filteredSummaries];
    source[index] = { ...source[index], [field]: value };
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: source })
    );
    setFilteredSummaries(source);
  };

  const saveSummaryAfterEdit = (index, object) => {
    // console.log(index, object);
    if (!authState.loginSuccess) {
      postAction = () => {
        // console.log('saving');
        // saveSummaryAfterEdit(index, object)
        document.getElementById("overlayContainer").focus();
        const source = [...filteredSummaries];
        source[index] = object;
        const sortedChapters = [...source].sort((a, b) => a.start - b.start);
        setCurrentTime(playerState.currentPlayingTime);
        dispatch(
          summaryActions.mutateSummariesAction.action({
            summaries: sortedChapters,
          })
        );
        setFilteredSummaries(sortedChapters);
        playFunction.current.playVideo();
      };
      setShowLoginDialog(true);
      return;
    }
    const source = [...filteredSummaries];
    source[index] = object;
    const sortedChapters = [...source].sort((a, b) => a.start - b.start);
    setCurrentTime(playerState.currentPlayingTime);
    setFilteredSummaries(sortedChapters);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: sortedChapters })
    );
    playFunction.current.playVideo();
    document.getElementById("overlayContainer").focus();
  };

  const onEditSummary = (index) => {
    const source = [...filteredSummaries];
    setPreviousState([...source]);
    source[index] = { ...source[index], type: "EDIT" };
    setCurrentTime(playerState.currentPlayingTime);
    setFilteredSummaries(source);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: source })
    );
  };

  const onDeleteSummary = (start) => {
    document.getElementById("overlayContainer").focus();
    // using filterSummary as source lends to bug while deleting chapter using search
    const source = [...summaryObject.summaries];
    const UpdatedSummaries = source.filter(
      (chapter) => chapter.start !== start
    );
    // console.log("Other than Deleted One", UpdatedSummaries, summaryObject.summaries, start);
    setCurrentTime(playerState.currentPlayingTime);
    setFilteredSummaries(UpdatedSummaries);
    dispatch(
      summaryActions.mutateSummariesAction.action({
        summaries: UpdatedSummaries,
      })
    );
    onSearch(searchText, UpdatedSummaries);

    return;
  };

  const onChangesCancel = () => {
    playFunction.current.playVideo();
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: previousState })
    );
    setFilteredSummaries(previousState);
    document.getElementById("overlayContainer").focus();
  };

  const postSummary = () => {
    if (authState.role === "TESTPILOT") {
      setShowTestPilotModel(true);
      return;
    }
    if (!authState.loginSuccess) {
      postAction = postSummary;
      setShowLoginDialog(true);
      return;
    }
    showBackToHome = true;
    const requestBody = {
      summary: [...filteredSummaries],
      videoTitle: videoTitle,
      platform: summaryObject.platform || query.get("platform"),
      channel: channelTitle,
    };
    onVideoTitleChange();
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.postSummariesAsyncActions.request({ videoId, requestBody })
    );
    setShowShareDialog(true);
  };

  const onCopyVideo = (showShareDialog) => {
    if (summaryObject.isMutated) {
      toast.warning("Save changes to enable sharing", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (
      summaryObject.summaries === undefined ||
      summaryObject.summaries.length === 0
    ) {
      toast.error(`Add chapters and save them to enable sharing`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (showShareDialog) {
      // console.log('onCopyVideo', showShareDialog)
      setShowShareDialog(true);
      return;
    }
    // console.log(summaryObject.videoTitle);
    copy(`${summaryObject.videoTitle}
	${moment
    .duration(
      summaryObject?.summaries[summaryObject?.summaries?.length - 1].start *
        1000
    )
    .format(
      `${
        summaryObject?.summaries[summaryObject?.summaries?.length - 1].start >
        60
          ? "hh:mm:ss"
          : "mm:ss"
      }`,
      {
        trim:
          summaryObject?.summaries[summaryObject?.summaries?.length - 1].start >
          60
            ? true
            : false,
      }
    )} | ${summaryObject.summaries.length} chapters 🔖
	https://api.chapterme.co/share/video/${videoId}`);
    toast.success("Video link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const copyAllChapters = () => {
    let chapters = "";

    summaryObject.summaries.forEach((summary) => {
      chapters =
        chapters +
        `${moment
          .duration(summary.start * 1000)
          .format(`${summary.start > 60 ? "hh:mm:ss" : "mm:ss"}`, {
            trim: summary.start > 60 ? true : false,
          })} - ${summary.title || summary.summary}
`;
    });
    toast.success("Chapters copied successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    copy("Chapters (Powered by ChapterMe) - \n" + chapters);
  };

  const onVideoTitleChange = () => {
    setEditVideoTitle(false);
    dispatch(
      summaryActions.mutateSummariesAction.action({
        videoTitle,
        channel: channelTitle,
      })
    );
  };

  const keyPressHandling = (e) => {
    if (e.key === "b") createNewChapter();
    if (e.key === "Enter") {
      onVideoTitleChange();
    }
  };

  const onBulkUploadClick = () => {
    if (bulkUpload) {
      if (bulkUploadText.length) {
        const match =
          bulkUploadText.match(
            /([0-9]{1,2}?:[0-9]{1,2}:?[0-9]{1,2})(\W*)(.*)/g
          ) || [];
        const autoChapters = match.map(findForwardMatch);

        if (!autoChapters.length)
          toast.warning("Format not supported", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        dispatch(
          summaryActions.mutateSummariesAction.action({
            summaries: autoChapters,
          })
        );
        // console.log(autoChapters);
      }
      bulkUploadText = "";
      setBulkUpload(false);
    } else setBulkUpload(true);
  };

  useEffect(() => {
    document.title = summaryObject.videoTitle
      ? summaryObject.videoTitle
      : summaryObject.metadata.title
      ? summaryObject.metadata.title
      : "New Summary";
    // console.log(summaryObject.summaries)
    if (searchText === "" && mode !== "ai") {
      // console.log("#30b7fb Executing filtered summaries")
      setFilteredSummaries((p) =>
        p.length
          ? p
          : summaryObject.summaries.length
          ? summaryObject.summaries
          : []
      );
    }

    if (summaryObject?.summaries?.length) setUseMLModal(false);

    setVideoTitle(
      summaryObject.videoTitle
        ? summaryObject.videoTitle
        : summaryObject.metadata.title
        ? summaryObject.metadata.title
        : ""
    );
    setChannelTitle(summaryObject.channel);
  }, [summaryObject]);

  useEffect(() => {
    let title = videoTitle ? `${videoTitle}` : "Untitled Video";
    document.title = title + " | ChapterMe";
    dispatch(summaryActions.setVideoTitleAction.action(title));
  }, [videoTitle, summaryObject.videoTitle]);

  useEffect(() => {
    if (!query.get("fetch"))
      dispatch(
        summaryActions.fetchSummariesAsyncActions.request({
          videoId,
          platform: query.get("platform"),
        })
      );
  }, [videoId]);

  useEffect(() => {
    if (editVideoTitle && videeTitleInputRef && videeTitleInputRef.current)
      videeTitleInputRef.current.focus();
  }, [editVideoTitle]);

  const onMlStart = () => {
    if (mlChapters?.length) {
      setFilteredSummaries(mlChapters);
      return;
    }
    // if(authState.accountId !== "60bd1582d7c788316bb9b1f1")
    if (true) {
      dispatch(
        summaryActions.setVideoTitleAction.action(
          summaryObject.metadata.title
            ? `${summaryObject.metadata.title}`
            : "New Summary"
        )
      );
      dispatch(summaryActions.useMlAsyncActions.request(videoId));
      setUseMLModal(false);
      return;
    }
    // toast.warning(`This feature is in private beta. Reachout to support@chapterme.co`, {
    // 	position: "top-right",
    // 	autoClose: false,
    // 	hideProgressBar: false,
    // 	closeOnClick: false,
    // 	pauseOnHover: true,
    // 	draggable: false,
    // 	progress: undefined,
    // })
    showedMlModel = true;
    setUseMLModal(false);
  };

  const findForwardMatch = (string) => {
    const regex = /([0-9]{1,2}?:[0-9]{1,2}:?[0-9]{1,2})(\W*)(.*)/g;
    const splittedStrings = regex.exec(string);
    if (splittedStrings && splittedStrings.length) {
      if (splittedStrings[1].split(":").length < 3) {
        splittedStrings[1] = "00:" + splittedStrings[1];
      }
      return {
        start: moment.duration(splittedStrings[1]).asSeconds(),
        title: splittedStrings[3],
      };
    }
  };

  useEffect(() => {
    const reatimeListener = onSnapshot(
      doc(db, "lambda-chapters", "youtube_" + videoId),
      (mlChapters) => {
        // console.log("#30b7fb ML CHANGES FROM FIREASE", mlChapters?.data()?.chapters && mlChapters?.data()?.chapters.length)
        setMlChapters(
          mlChapters?.data()?.chapters && mlChapters?.data()?.chapters.length
        );
        setCurrentMlStatus(mlChapters?.data()?.isCompleted);
        if (mlChapters.data() && mlChapters.data().chapters && mode === "ai") {
          setFilteredSummaries(mlChapters.data().chapters);
          dispatch(
            summaryActions.mutateSummariesAction.action({
              summaries: mlChapters.data().chapters,
            })
          );
        }

        //setMlChapters(mlChapters?.data()?.chapters) --reverted-changes
        //if(mlChapters.data() && mlChapters.data().chapters && summaryObject.usingML) {
        //setCurrentMlStatus(mlChapters.data().isCompleted)
        //setFilteredSummaries(mlChapters.data().chapters || [])
        // }
      }
    );

    return () => {
      reatimeListener();
    };
  }, [summaryObject.title, summaryObject.usingML]);

  useEffect(() => {
    // console.log("CURRENT ML STATUS LOG", currentMlStatus, filteredSummaries);
    //if(mode === "ai" && (currentMlStatus === 1 || currentMlStatus === -1) && filteredSummaries && !filteredSummaries.length) { --reverted-changes
    // console.log("CURRENT ML STATUS LOG INSIDE IF", currentMlStatus, currentMlStatus !== 0, filteredSummaries);
    if (
      mode === "ai" &&
      (currentMlStatus === 1 || currentMlStatus === -1) &&
      filteredSummaries &&
      !filteredSummaries.length &&
      !mlChapters
    ) {
      toast.error(
        "Our AI is currently acting up 😔. Please click on this message to request chapters for this video. Feel free to reach us at support@chapterme.co",
        {
          autoClose: false,
          onClose: () => onRequestSummary(),
        }
      );
    }
  }, [currentMlStatus, filteredSummaries.length]); //[currentMlStatus] --reverted-changes

  useEffect(() => {
    // console.log("Summary Object Meta data", summaryObject)
    if (summaryObject.metadata && summaryObject.metadata.description) {
      const match =
        summaryObject.metadata.description.match(
          /([0-9]{1,2}?:[0-9]{1,2}:?[0-9]{1,2})(\W*)(.*)/g
        ) || [];
      const autoChapters = match.map(findForwardMatch);
      if (autoChapters.length)
        setDetectedSummary(autoChapters.filter((chapter) => chapter));
    }
    window.onbeforeunload = (e) => {
      if (summaryObject.isMutated && summaryObject.summaries.length) {
        e.preventDefault();
        e.returnValue = "";
        return;
      }

      delete e["returnValue"];
    };
  }, [summaryObject]);

  // useEffect(() => {
  // console.log(detectedSummaryModal)
  // }, [detectedSummaryModal])

  // this tracks and triggers summary_detected modal and request_chapter modal
  useEffect(() => {
    if (
      (!filteredSummaries || !filteredSummaries.length) &&
      detectedSummary.length &&
      !summaryObject.usingML &&
      !showedDetectedSummary
    ) {
      // console.log('useEffect - true', !filteredSummaries, !filteredSummaries.length, detectedSummary.length, !summaryObject.usingML)
      setDetectedSummaryModal(true);
      setUseMLModal(false);
    } else if (
      (!filteredSummaries || !filteredSummaries.length) &&
      !summaryObject.usingML &&
      !showedMlModel &&
      !searchText.length
    ) {
      // console.log('ml triggered')
      setDetectedSummaryModal(false);
      setUseMLModal(true);
    } else {
      // console.log('useEffect - false')
      setDetectedSummaryModal(false);
    }

    // console.log(filteredSummaries.length)
    if (filteredSummaries.length) setUseMLModal(false);
  }, [[filteredSummaries, detectedSummary.length, summaryObject.title]]);

  // console.log(summaryObject.pl)

  useEffect(() => {
    // console.log("SETTING ML MODAL CONDITION")
    if (
      summaryObject.metadata.title &&
      (!filteredSummaries || !filteredSummaries.length) &&
      !summaryObject.isLoading
    )
      setUseMLModal(true);
  }, [summaryObject.title, summaryObject.isLoading]);

  useEffect(() => {
    // console.log("CURRENT_MODE", mode, filteredSummaries, summaryObject?.descriptionSummaries);
    switch (mode) {
      case "ai": {
        onMlStart();
        break;
      }
      case "diy": {
        return null;
      }
      case "import": {
        setFilteredSummaries(summaryObject.descriptionSummaries);
        dispatch(summaryActions.mutateSummariesAction.action({}));
        break;
      }
      default:
        return null;
    }
  }, [mode, summaryObject?.descriptionSummaries]);

  // useEffect(() => {
  // console.log(useMLModal);
  // }, [useMLModal])

  const onSignup = () => {
    const payload = { name, password };

    const registerErrors = validate(payload, signupValidations);

    if (registerErrors) {
      setErrors(registerErrors);
      return;
    }

    setErrors({ name: [], password: [] });
    dispatch(
      authActions.upgradeAsyncActions.request({
        body: payload,
        callback: () => setShowTestPilotModel(false),
      })
    );
  };

  return (
    <RootContainer id="root-container">
      <PlayerTopNav>
        {/*topbar menu*/}
        <MenuContainer>
          <MenuBtn />
          <TopbarMenuHoverContainer>
            <MenuItem
              onClick={() =>
                !(
                  (summaryObject.isMutated &&
                    summaryObject.summaries &&
                    summaryObject.summaries.length) ||
                  (currentMlStatus && summaryObject.usingML) ||
                  (summaryObject.isMutated && mode === "import")
                )
                  ? window.open(
                      `https://app.chapterme.co/embedSettings/${videoId}`,
                      "_self"
                    )
                  : toast.error("You need to save Chapters before Embedding it")
              }
            >
              <MenuEmbedIcon />
              <span>Customize and Embed</span>
            </MenuItem>
            <MenuItem onClick={() => onCopyVideo(false)}>
              <MenuShareIcon />
              <span>Share</span>
            </MenuItem>
            {/*<MenuItem>*/}
            {/*<MenuLockIcon /><span>Mark as private</span>*/}
            {/*</MenuItem>*/}
            <MenuItem
              onClick={() => {
                window.history.back();
              }}
            >
              <MenuHomeIcon />
              <span>Home</span>
            </MenuItem>
          </TopbarMenuHoverContainer>
        </MenuContainer>

        {/*topbar title*/}
        {editVideoTitle ? (
          <div>
            <VideoTitleContainer>
              <SummaryInput
                onKeyDown={(e) =>
                  e.key === "Enter" ? keyPressHandling(e) : null
                }
                placeholder="Add video title"
                value={videoTitle !== "New Summary" ? videoTitle : ""}
                onChange={(evt) => setVideoTitle(evt.target.value)}
                ref={videeTitleInputRef}
              />
              <BiCheck
                style={{ cursor: "pointer" }}
                size={24}
                color="black"
                onClick={onVideoTitleChange}
              />
            </VideoTitleContainer>
          </div>
        ) : (
          <div>
            <VideoTitleContainer>
              <VideoTitleText>{videoTitle || "New Summary"}</VideoTitleText>
              <BiEditAlt
                style={{ padding: "5px 15px" }}
                size={24}
                color="black"
                onClick={() => setEditVideoTitle(true)}
              />
            </VideoTitleContainer>
          </div>
        )}

        <TopbarSpaceFiller />

        {/*top bar rightside contents*/}
        <TopbarRightContent>
          {showSearch ? (
            <SearchInput
              onChange={(evt) =>
                onSearch(evt.target.value, summaryObject.summaries)
              }
            />
          ) : null}
          <SearchIcon
            className="select-nothing"
            onClick={() => {
              if (showSearch) searchText = "";
              setShowSearch(!showSearch);
            }}
          />
          {/*<FeedBackIcon  onClick={() => {window.open('https://forms.gle/uDKwdNnWip9WhmEy6', '_blank');}}  />						*/}
          <EmbedIcon
            onClick={() =>
              window.open(
                `https://app.chapterme.co/embedSettings/${videoId}`,
                "_self"
              )
            }
          />
          <ShareIcon
            onClick={() => {
              // if (window.innerWidth < 800)
              // {
              // 	onCopyVideo(false);
              // 	return;
              // }
              onCopyVideo(false);
            }}
          />
          <KeyboardShortcutKeyIcon
            onClick={() => setShowKeyboardShortcuts(true)}
          />
        </TopbarRightContent>
      </PlayerTopNav>

      <PlayerContainer>
        {/*video and overlay*/}
        <VideoContainer onKeyDown={keyPressHandling}>
          {videoId
            ? useMemo(
                () => (
                  <VideoPlayer
                    ref={playFunction}
                    isLoggedIn={authState.loginSuccess}
                    selectedTimestamp={{ start: currentTime }}
                    videoId={videoId}
                    platform={
                      query.get("platform")
                        ? query.get("platform")
                        : summaryObject.platform
                    }
                    onCopyVideo={onCopyVideo}
                    colorElementsAry={colorElementsAry}
                  />
                ),
                [
                  summaryObject.platform,
                  currentTime,
                  summaryObject.summaries,
                  summaryObject.isMutated,
                  authState.loginSuccess,
                  summaryObject.title,
                ]
              )
            : null}
        </VideoContainer>

        {/*Chapters container*/}
        {playerState.showSummaries ? (
          <SummaryContainer>
            {/*top Chapter div*/}
            <ChaptersTitleDiv>Chapters</ChaptersTitleDiv>
            {/*ML loader*/}
            {!currentMlStatus && mode === "ai" ? (
              <MLLoadingContainer>
                <ClipLoader color="#7289da" loading={true} size={150} />
                <Circle>Loading...</Circle>
                {/*<HorizontalSpace count={1} />
										<CopyTextContent>Fetching summaries from ML one by one!</CopyTextContent>*/}
              </MLLoadingContainer>
            ) : null}
            <SummaryListContainer id="garage-summarylistcontainer">
              {/*bulk upload textbox and summary card*/}
              {bulkUpload ? (
                <BulkUploadInput
                  onChange={(evt) => {
                    bulkUploadText = evt.target.value;
                  }}
                />
              ) : filteredSummaries ? (
                !query.get("platform") || filteredSummaries.length ? (
                  filteredSummaries.map((summary, index, summaryArray) => (
                    <Summary
                      isLoggedIn={authState.loginSuccess}
                      key={`chapter${index}`}
                      index={index}
                      summaryArray={summaryArray}
                      videoId={videoId}
                      summary={summary}
                      setCurrentTime={setCurrentTime}
                      currentTime={currentTime}
                      updateChapterContent={updateChapterContent}
                      onSaveEdit={saveSummaryAfterEdit}
                      onEditSummary={onEditSummary}
                      onDeleteSummary={onDeleteSummary}
                      onChangesCancel={onChangesCancel}
                      onCopyVideo={onCopyVideo}
                      currentChapterCard={currentChapterCard}
                      isMutated={summaryObject.isMutated}
                    />
                  ))
                ) : (
                  <NoSummariesContainer>
                    <NoSummariesText>
                      Chapters not available for this video.
                    </NoSummariesText>
                  </NoSummariesContainer>
                )
              ) : (
                <NoSummariesContainer>
                  <NoSummariesText>
                    Chapters not available for this video.
                  </NoSummariesText>
                </NoSummariesContainer>
              )}
            </SummaryListContainer>

            {/*bottom buttons*/}
            {currentMlStatus === 0 ? (
              ""
            ) : (
              <NoSummariesContainer>
                {!bulkUpload ? (
                  <AddChapterButton
                    ref={addChapterBtn}
                    onClick={createNewChapter}
                  >
                    Add <span>chapter</span> (b)
                  </AddChapterButton>
                ) : null}
                {(summaryObject.isMutated &&
                  summaryObject.summaries &&
                  summaryObject.summaries.length) ||
                (currentMlStatus && summaryObject.usingML) ||
                (summaryObject.isMutated && mode === "import") ? (
                  <AddChapterButton
                    ref={saveChangesBtn}
                    onClick={postSummary}
                    background="#daa172"
                  >
                    Save all <span>changes</span>
                  </AddChapterButton>
                ) : filteredSummaries.length ? (
                  <AddChapterButton onClick={copyAllChapters}>
                    Copy All<span> Chapters</span>
                  </AddChapterButton>
                ) : (
                  <AddChapterButton onClick={onBulkUploadClick}>
                    {bulkUpload ? "Add" : "Bulk Upload"}
                    <span></span>
                  </AddChapterButton>
                )}
              </NoSummariesContainer>
            )}

            <div
              style={{
                height: "5rem",
              }}
            ></div>
          </SummaryContainer>
        ) : null}

        {/*common loader*/}
        {summaryObject.isLoading ? <LoadingOverlay /> : null}

        {false ? (
          <Modal>
            <ModalContainer>
              <CopyTextHeading>Chapters detected automatically</CopyTextHeading>
              <CopyTextContent>
                We have detected chapters for this video. Would you like to add
                them?
              </CopyTextContent>
              <NoSummariesContainer>
                <CopyContentContainer
                  onClick={() => {
                    showedDetectedSummary = true;
                    dispatch(
                      summaryActions.setVideoTitleAction.action(
                        summaryObject.metadata.title
                          ? `${summaryObject.metadata.title}`
                          : "New Summary"
                      )
                    );
                    dispatch(
                      summaryActions.mutateSummariesAction.action({
                        summaries: detectedSummary,
                      })
                    );
                    setDetectedSummaryModal(false);
                  }}
                >
                  <CopyTextContent>Yes</CopyTextContent>
                </CopyContentContainer>
                <CopyContentContainer
                  onClick={() => {
                    showedDetectedSummary = true;
                    setDetectedSummaryModal(false);
                  }}
                >
                  <CopyTextContent>No</CopyTextContent>
                </CopyContentContainer>
              </NoSummariesContainer>
            </ModalContainer>
          </Modal>
        ) : null}

        {false ? (
          <Modal>
            <ModalContainer>
              <CopyTextHeading>Create chapters using AI</CopyTextHeading>
              <CopyTextContent>
                We use our state-of-the-art AI-powered assistant to create
                chapters faster than ever!
              </CopyTextContent>
              <NoSummariesContainer>
                <CopyContentContainer onClick={onMlStart}>
                  <CopyTextContent>Yes</CopyTextContent>
                </CopyContentContainer>
                <CopyContentContainer
                  onClick={() => {
                    showedMlModel = true;
                    setUseMLModal(false);
                  }}
                >
                  <CopyTextContent>No</CopyTextContent>
                </CopyContentContainer>
              </NoSummariesContainer>
            </ModalContainer>
          </Modal>
        ) : null}

        {/*pop-up login*/}
        {showLoginDialog ? (
          isSignUp ? (
            <Signuppage
              popLogin={true}
              setIsSignUp={setIsSignUp}
              setShowLoginDialog={setShowLoginDialog}
              postAction={postAction}
              welcomeToast={welcomeToast}
            />
          ) : (
            <Loginpage
              popLogin={true}
              setIsSignUp={setIsSignUp}
              setShowLoginDialog={setShowLoginDialog}
              postAction={postAction}
              welcomeToast={welcomeToast}
            />
          )
        ) : null}

        {/*keyboard shortcuts model*/}
        {showKeyboardShortcuts ? (
          <Modal large close={() => setShowKeyboardShortcuts(false)}>
            <ModalContainer>
              <CopyTextHeading>Keyboard Shortcuts</CopyTextHeading>
              <CopyTextContent>
                Note: Except for save and cancel chapter, all other shortcuts
                will only work after you click on the video
              </CopyTextContent>
              <KeyboardShortcutContainer>
                {keyboardShortcuts.map((shortcut) => (
                  <KeyboardShortcutCard>
                    <KeyboardShortcutName>
                      {shortcut.title}
                    </KeyboardShortcutName>
                    <KeyboardShortcutKey
                      type={shortcut.type}
                      dangerouslySetInnerHTML={{ __html: shortcut.key }}
                    >
                      {}
                    </KeyboardShortcutKey>
                  </KeyboardShortcutCard>
                ))}
              </KeyboardShortcutContainer>
            </ModalContainer>
          </Modal>
        ) : null}

        {showTestPilotModel ? (
          <Modal maxContent close={() => setShowTestPilotModel(false)}>
            <ModalContainer>
              <CopyTextHeading>
                Test drive users can't save chapters 😥
              </CopyTextHeading>
              <CopyTextContent>
                Upgrade to an admin account for free to enjoy all features!
              </CopyTextContent>
              <VerticalSpace count={1} />
              <SignupFormContainer>
                <Input
                  onChange={setName}
                  placeholder="John Doe"
                  label="Full Name"
                />
                <Errors>{errors.name && errors.name[0]}</Errors>
                <VerticalSpace count={1} />
                <Input
                  onChange={setPassword}
                  placeholder="*********"
                  label="Password"
                />
                <Errors>{errors.password && errors.password[0]}</Errors>
                <VerticalSpace count={1} />
                {authState.isLoading ? (
                  <SquareLoader size={35} color="#138DCA" />
                ) : (
                  <Button onClick={onSignup}>Upgrade Now</Button>
                )}
              </SignupFormContainer>
            </ModalContainer>
          </Modal>
        ) : null}

        {/*share dialogbox, showing after save changes*/}
        {showShareDialog ? (
          <Modal
            maxContent
            close={() => {
              setShowShareDialog(false);
              showBackToHome = false;
            }}
          >
            <NoSummariesContainer>
              <CopyContentContainer onClick={() => onCopyVideo(false)}>
                <CopyIconContainer>
                  <BiLink color="#7289da" size={36} />
                </CopyIconContainer>
                <CopyTextContainer>
                  <CopyTextHeading>Copy Link</CopyTextHeading>
                  {/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
                </CopyTextContainer>
              </CopyContentContainer>
              <CopyContentContainer
                className="embed-btn"
                onClick={() =>
                  window.open(
                    `https://app.chapterme.co/embedSettings/${videoId}`,
                    "_self"
                  )
                }
              >
                <CopyIconContainer>
                  <HiCode color="#7289da" size={36} />
                </CopyIconContainer>
                <CopyTextContainer>
                  <CopyTextHeading>Customize &amp; Embed</CopyTextHeading>
                  {/*<CopyTextContent>Embed the current video with our player in any site</CopyTextContent>*/}
                </CopyTextContainer>
              </CopyContentContainer>
            </NoSummariesContainer>
            {showBackToHome ? (
              <CopyContentContainer
                className="embed-btn"
                onClick={() => history.replace(`/dashboard`)}
              >
                <CopyIconContainer>
                  <HiCode color="#7289da" size={36} />
                </CopyIconContainer>
                <CopyTextContainer>
                  <CopyTextHeading>Go back Home</CopyTextHeading>
                </CopyTextContainer>
              </CopyContentContainer>
            ) : null}
          </Modal>
        ) : null}
      </PlayerContainer>
    </RootContainer>
  );

  // return (
  // 	<RootContainer id='root-container' >
  // 		<VideoContainer onKeyDown={keyPressHandling} >
  // 			{
  // 				videoId
  // 				?
  // 				useMemo(() => <VideoPlayer ref={playFunction} isLoggedIn={authState.loginSuccess} selectedTimestamp={{start: currentTime}} videoId={videoId} platform={query.get('platform') ? query.get('platform') : summaryObject.platform} onCopyVideo={onCopyVideo} colorElementsAry={colorElementsAry} />, [summaryObject.platform, currentTime, summaryObject.summaries, summaryObject.isMutated, authState.loginSuccess, summaryObject.title])
  // 				:
  // 				null
  // 			}
  // 		</VideoContainer>
  // 		{
  // 			playerState.showSummaries ?
  // 				<SummaryContainer>
  // 				<SummaryContainerHeader ref={summaryHeader} id='summary-header' >
  // 						<SummaryContainerText>CHAPTERS</SummaryContainerText>
  // 						<FaKeyboard style={{cursor: 'pointer'}} color='#FFF' onClick={()=> setShowKeyboardShortcuts(true)} />
  // 				</SummaryContainerHeader>
  // 				<VideoTitleContainer id='videoTitle-container' >
  // 				{
  // 					(editVideoTitle)
  // 					?
  // 					<div>
  // 						<VideoTitleContainer>
  // 							<SummaryInput onKeyDown={e => e.key==='Enter'?keyPressHandling(e):null} placeholder="Add video title" value={videoTitle !== 'New Summary'? videoTitle : ''} onChange={evt => setVideoTitle(evt.target.value)} ref={videeTitleInputRef} />
  // 							<BiCheck style={{cursor: 'pointer'}} size={24} color='#FFF' onClick={onVideoTitleChange} />
  // 						</VideoTitleContainer>
  // 						<VideoTitleContainer>
  // 							<SummaryInput onKeyDown={e => e.key==='Enter'?keyPressHandling(e):null} placeholder="Add channel name" value={channelTitle ? channelTitle : ''} onChange={evt => setChannelTitle(evt.target.value)} />
  // 							<BiCheck size={24} color='transparent' />
  // 						</VideoTitleContainer>
  // 					</div>
  // 					:
  // 					<div>
  // 						<VideoTitleContainer>
  // 							<VideoTitleText>{videoTitle || 'New Summary'}</VideoTitleText>
  // 							<BiEditAlt style={{padding: '5px'}} size={24} color='#FFF' onClick={() => setEditVideoTitle(true)} />
  // 						</VideoTitleContainer>
  // 						<VideoTitleContainer>
  // 							<ChannelTitleText>{channelTitle || 'Click ✍ to add video title & channel name'}</ChannelTitleText>
  // 						</VideoTitleContainer>
  // 					</div>
  // 				}
  // 				</VideoTitleContainer>
  // 				<SummaryListContainer id='garage-summarylistcontainer'>
  // 					{
  // 						filteredSummaries
  // 						?
  // 						!query.get('platform') || filteredSummaries.length
  // 						?
  // 						filteredSummaries.map((summary, index, summaryArray) => <Summary isLoggedIn={authState.loginSuccess} key={`chapter${index}`} index={index} summaryArray={summaryArray} videoId={videoId} summary={summary} setCurrentTime={setCurrentTime} currentTime={currentTime} updateChapterContent={updateChapterContent} onSaveEdit={saveSummaryAfterEdit} onEditSummary={onEditSummary} onDeleteSummary={onDeleteSummary} onChangesCancel={onChangesCancel} onCopyVideo={onCopyVideo} currentChapterCard={currentChapterCard} isMutated={summaryObject.isMutated} />)
  // 						:
  // 						<NoSummariesContainer>
  // 							<NoSummariesText>Chaptes not available for this video.</NoSummariesText>
  // 						</NoSummariesContainer>
  // 						:
  // 						<NoSummariesContainer>
  // 							<NoSummariesText>Chaptes not available for this video.</NoSummariesText>
  // 						</NoSummariesContainer>
  // 					}
  // 				</SummaryListContainer>
  // 				<NoSummariesContainer>
  // 					<AddChapterButton ref={addChapterBtn} onClick={createNewChapter} >Add <span>chapter</span> (b)</AddChapterButton>
  // 					{
  // 						summaryObject.isMutated && summaryObject.summaries && summaryObject.summaries.length
  // 						?
  // 						<AddChapterButton ref={saveChangesBtn} onClick={postSummary} background='#daa172' >Save all <span>changes</span></AddChapterButton>
  // 						:
  // 						null
  // 					}
  // 				</NoSummariesContainer>
  // 				<SearchContainer>
  // 					<SearchInput onChange={(evt) => onSearch(evt.target.value)} />
  // 				</SearchContainer>
  // 			</SummaryContainer>
  // 			: null
  // 		}
  // 		{
  // 			summaryObject.isLoading
  // 			?
  // 			<LoadingOverlay />
  // 			:
  // 			null
  // 		}
  // 		{
  // 			detectedSummaryModal
  // 			?
  // 					<Modal>
  // 						<ModalContainer>
  // 							<CopyTextHeading>Chapters detected automatically</CopyTextHeading>
  // 							<CopyTextContent>We have detected chapters for this video. Would you like to add them?</CopyTextContent>
  // 							<NoSummariesContainer>
  // 								<CopyContentContainer onClick={() => {
  // 									dispatch(summaryActions.setVideoTitleAction.action(summaryObject.metadata.title?`${summaryObject.metadata.title}`:'New Summary'));
  // 									dispatch(summaryActions.mutateSummariesAction.action({summaries: detectedSummary}));
  // 									setDetectedSummaryModal(false);
  // 								}} >
  // 										<CopyTextContent>Yes</CopyTextContent>
  // 								</CopyContentContainer>
  // 								<CopyContentContainer onClick={() => setDetectedSummaryModal(false)} >
  // 										<CopyTextContent>No</CopyTextContent>
  // 								</CopyContentContainer>
  // 							</NoSummariesContainer>
  // 						</ModalContainer>
  // 					</Modal>
  // 			:
  // 			null
  // 		}
  // 		{
  // 			showLoginDialog
  // 			?
  // 				isSignUp
  // 				?
  // 				<Signuppage popLogin={true} setIsSignUp={setIsSignUp} setShowLoginDialog={setShowLoginDialog} postAction={postAction} welcomeToast={welcomeToast} />
  // 				:
  // 				<Loginpage popLogin={true} setIsSignUp={setIsSignUp}  setShowLoginDialog={setShowLoginDialog} postAction={postAction} welcomeToast={welcomeToast} />
  // 			:
  // 			null
  // 		}
  // 		{
  // 			showKeyboardShortcuts
  // 			?
  // 			<Modal large close={() => setShowKeyboardShortcuts(false)} >
  // 				<ModalContainer>
  // 					<CopyTextHeading>Keyboard Shortcuts</CopyTextHeading>
  // 					<CopyTextContent>Note: Except for save and cancel chapter, all other shortcuts will only work after you click on the video</CopyTextContent>
  // 					<KeyboardShortcutContainer>
  // 					{
  // 						keyboardShortcuts.map(shortcut => (
  // 							<KeyboardShortcutCard>
  // 								<KeyboardShortcutName>{shortcut.title}</KeyboardShortcutName>
  // 								<KeyboardShortcutKey type={shortcut.type} dangerouslySetInnerHTML={{__html: shortcut.key}} >{}</KeyboardShortcutKey>
  // 							</KeyboardShortcutCard>
  // 						))
  // 					}
  // 					</KeyboardShortcutContainer>
  // 				</ModalContainer>
  // 			</Modal>
  // 			:
  // 			null
  // 		}
  // 		{
  // 			showShareDialog
  // 			?
  // 			<Modal maxContent close={() => {setShowShareDialog(false); showBackToHome = false;}}  >
  // 				<NoSummariesContainer>
  // 					<CopyContentContainer onClick={() => onCopyVideo(false)} >
  // 						<CopyIconContainer>
  // 							<BiLink color='#7289da' size={36} />
  // 						</CopyIconContainer>
  // 						<CopyTextContainer>
  // 							<CopyTextHeading>Copy Link</CopyTextHeading>
  // 							{/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
  // 						</CopyTextContainer>
  // 					</CopyContentContainer>
  // 					<CopyContentContainer className="embed-btn" onClick={() => window.open(`https://app.chapterme.co/embedSettings/${videoId}`, '_blank')} >
  // 						<CopyIconContainer>
  // 							<HiCode color='#7289da' size={36} />
  // 						</CopyIconContainer>
  // 						<CopyTextContainer>
  // 							<CopyTextHeading>Customize &amp; Embed</CopyTextHeading>
  // 							{/*<CopyTextContent>Embed the current video with our player in any site</CopyTextContent>*/}
  // 						</CopyTextContainer>
  // 					</CopyContentContainer>
  // 				</NoSummariesContainer>
  // 				{
  // 					showBackToHome?
  // 					<CopyContentContainer className="embed-btn" onClick={() => history.replace(`/panel`)} >
  // 						<CopyIconContainer>
  // 							<HiCode color='#7289da' size={36} />
  // 						</CopyIconContainer>
  // 						<CopyTextContainer>
  // 							<CopyTextHeading>Go back Home</CopyTextHeading>
  // 						</CopyTextContainer>
  // 					</CopyContentContainer>
  // 					:null
  // 				}
  // 			</Modal>
  // 			:
  // 			null
  // 		}
  // 	</RootContainer>
  // )
};
