import { useEffect, useMemo, useRef, useState } from "react";
import { summaryActions } from "modules/summaries";
import { Button, Completed, Error, InQueue, Removed, Saved } from "components/Comment";
import { LogoImg } from "components/overlay/overlay.style";
import { useLocalStorage } from "utils";
import { HeroTitle, RootContainer, Search, SearchContainer, TotalProcessingText } from "./comment.styles";
import { filterComments, status, urlParser } from "./helper";
import InProgress from "./InProgress";
import { fetchSummaries } from "modules/summaries/api";
import { useDispatch } from "react-redux";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";



export default function Comment() {

    const dispatch = useDispatch();
    const db = getFirestore();

    const [inProgress, setInProgress] = useState({});
    const [queue, setQueue] = useState([]);
    const [completed, setCompleted] = useState([]);
    const [saved, setSaved] = useState([]);
    const [mlChapters, setMlChapters] = useState([]);
    const [error, setError] = useState([]);
    const [stopped, setStopped] = useState([]);
    const [filteredSummaries, setFilteredSummaries] = useState([]);

    const [comments, setComments] = useLocalStorage('COMMENTS-CHAPTERME', []);

    const searchRef = useRef(null);



    useEffect(() => {
        const { inProgress, queue, completed, saved, error, stopped } = filterComments(comments);
        setInProgress(inProgress);
        setQueue(queue);
        setCompleted(completed);
        setSaved(saved);
        setError(error);
        setStopped(stopped);
        onMlStart(inProgress.videoId)
    }, [comments]);


    useEffect(() => {
        if (queue.length && !inProgress.videoId)
            changeStatus(queue[0].videoId, status.queue, status.inProgress);
    }, [queue.length, inProgress]);


    useEffect(() => {
        if (!inProgress.videoId)
            return;
        const reatimeListener = onSnapshot(doc(db, "lambda-chapters", "youtube_" + inProgress.videoId), (mlChapters) => {
            // console.log(" ML CHANGES FROM FIREASE", mlChapters?.data()?.chapters && mlChapters?.data()?.chapters.length)
            setMlChapters(mlChapters?.data()?.chapters && mlChapters?.data()?.chapters.length)

            if (mlChapters.data() && mlChapters.data().chapters && mlChapters?.data()?.isCompleted) {
                postSummaries(mlChapters.data().chapters);
                changeStatus(inProgress.videoId, status.inProgress, status.completed);
            }
        });

        return () => {
            reatimeListener();
        }
    }, [inProgress.title]);


    async function addToQue() {
        // console.log('here');
        const urls = searchRef.current.value;
        const parsedUrls = urls.split(',').map(url => url.trim());
        // console.log(parsedUrls);
        const existingIds = comments.map(comment => comment.videoId);

        const commentsToAdd = await Promise.all(
            parsedUrls.map(async url => {
                const { valid, videoId } = urlParser(url);
                // console.log({ valid, videoId });
                if (!valid || existingIds.includes(videoId)) {
                    return null;
                }
                const res = await fetchSummaries({ videoId });
                // console.log({ ...res });
                if (res.data.isSuccess) {
                    return {
                        ...res.data.result.metadata,
                        status: status.queue,
                        url,
                        videoId,
                    };
                }
                return null;
            })
        );

        const existingComments = [...comments, ...commentsToAdd.filter(comment => comment)];
        // console.log(existingComments, commentsToAdd);
        setComments(existingComments);

        searchRef.current.value = '';
    }


    function deleteComment(videoId) {
        const modifiedComments = comments.filter(comment => (comment.videoId !== videoId && comment.status !== status.stopped))
        setComments([...modifiedComments]);
    }


    function postSummaries(summaries) {
        const requestBody = {
            summary: [...summaries],
            videoTitle: inProgress.title,
            platform: 'youtube',
            // channel: channelTitle
        }
        // console.log({...requestBody});
        dispatch(summaryActions.postSummariesAsyncActions.request({ videoId: inProgress.videoId, requestBody }))
    }

    function changeStatus(videoId, from, to) {
        if (!videoId || !from || !to)
            return;

        const modifiedComments = comments.map(comment => {
            // console.log(comment.videoId, videoId, comment.status, { from, to });
            if (comment.videoId === videoId && comment.status === from)
                return { ...comment, status: to };
            return comment;
        })
        setComments(modifiedComments);
    }

    // function updateComments(videoId, object) {
    //     const modifiedComments = comments.map(comment => {
    //         if (comment.videoId === videoId) {
    //             return { ...comment, ...object };
    //         }
    //     })
    //     setComments(modifiedComments);
    // }


    function onMlStart(videoId, metadata) {
        if (mlChapters?.length) {
            setFilteredSummaries(mlChapters)
            return;
        }
        if (true) {
            // dispatch(summaryActions.setVideoTitleAction.action(metadata.title ? `${metadata.title}` : 'New Summary'));
            dispatch(summaryActions.useMlAsyncActions.request(videoId));
            return;
        }
    }


    return (
        <RootContainer>
            <LogoImg hideFullscreen={true} normal={true} src="https://chapterme.co/assets/img/logo-purple.svg" alt="" style={{ width: '210px' }} />
            <HeroTitle>ADD CHAPTERS AS YOUTUBE COMMENTS</HeroTitle>
            <hr />
            <SearchContainer>
                <Search ref={searchRef} />
                <Button onClick={addToQue}>Queue video</Button>
            </SearchContainer>
            <TotalProcessingText>
                Processing {comments.length - queue.length} of {comments.length} videos
            </TotalProcessingText>
            <hr />
            <InProgress
                data={inProgress}
                stop={(videoId) => changeStatus(videoId, status.inProgress, status.stopped)} />
            <InQueue
                data={queue}
                removeFromQueue={(videoId) => changeStatus(videoId, status.queue, status.stopped)} />
            <hr />
            <Completed data={completed} />
            <hr />
            <Saved data={saved} />
            <hr />
            <Error data={error} />
            <hr />
            <Removed data={stopped}
                queueVideo={(videoId) => changeStatus(videoId, status.stopped, status.queue)}
                deleteComment={deleteComment} />
        </RootContainer>
    );
}