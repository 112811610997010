import {call, put, takeLeading, takeLatest, select} from 'redux-saga/effects';
import {
	analyticalNumbersAsyncActions,
	sessionVideosAsyncActions,
	getTrailersAsyncActions,
	getTrailerSessionNumbersAsynction,
	videoAnalyticalNumbersAsyncActions,
	getSpecificTrailerAnalyticsAsyncActions,
} from './actions';
import {getAnalyticalNumbers,
	getSessionNumbers,
	trailerVideos,
	getTrailerSessionNumbers,
	getVideoAnalyticalNumbers,
	getSpecificTrailerAnalytics,
} from './api';
import {toast} from 'react-toastify';
import _ from 'lodash';

function* getAnalyticalNumbersSaga(action) {
	try {
		const {channelName} = yield select(state => state.authReducer)
		const response = yield call(getAnalyticalNumbers, 'exponent', action.payload.video, action.payload.sort, action.payload.order);
		// console.log("RESPONSE", response.data.result);
		yield put(analyticalNumbersAsyncActions.success(response.data.result))
	}
	catch(err) {
		// console.error("GET SUMMARY ERROR", err)
		yield put(analyticalNumbersAsyncActions.error())
	}
}

function* getVideoAnalyticalNumbersSaga(action) {
	try {
		const {accountId} = yield select(state => state.authReducer)
		const response = yield call(getVideoAnalyticalNumbers, accountId);
		yield put(videoAnalyticalNumbersAsyncActions.success({...response.data.result}))
	}
	catch(err) {
		// console.error("GET SUMMARY ERROR", err)
		yield put(analyticalNumbersAsyncActions.error())
	}
}

function* getSessionDataSaga(action) {
	try {
		const response = yield call(getSessionNumbers, action.payload)
		yield put(sessionVideosAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(sessionVideosAsyncActions.error())
	}
}

function* getTrailerSessionNumbersSaga(action) {
	try {
		const response = yield call(getTrailerSessionNumbers, action.payload.id, action.payload.time);
		yield put(getTrailerSessionNumbersAsynction.success(response.data.result));
	}
	catch(err)
	{
		yield put(getTrailerSessionNumbersAsynction.error());
	}
}

function* getTrailersSaga(action) {
	try {
		const {accountId, channelName} = yield select(state => state.authReducer)
		const response = yield call(trailerVideos, channelName);
		yield put(getTrailersAsyncActions.success(response.data.result));
		// yield* getTrailerSessionNumbersSaga({payload: response.data.result[0]._id})
	}
	catch(err) {
		yield put (getTrailersAsyncActions.error());
	}
}

function* getSpecificTrailerAnalyticsSaga(action) {
	try {
		const response = yield call(getSpecificTrailerAnalytics, action.payload.id);
		yield put(getSpecificTrailerAnalyticsAsyncActions.success(response.data.result));
		// yield* getTrailerSessionNumbersSaga({payload: response.data.result[0]._id})
	}
	catch(err) {
		yield put (getSpecificTrailerAnalyticsAsyncActions.error());
	}
}


function* mainSaga() {
	yield takeLeading(analyticalNumbersAsyncActions.actionNames.request, getAnalyticalNumbersSaga);
	yield takeLeading(videoAnalyticalNumbersAsyncActions.actionNames.request, getVideoAnalyticalNumbersSaga);
	yield takeLeading(sessionVideosAsyncActions.actionNames.request, getSessionDataSaga);
	yield takeLeading(getTrailersAsyncActions.actionNames.request, getTrailersSaga);
	yield takeLeading(getSpecificTrailerAnalyticsAsyncActions.actionNames.request, getSpecificTrailerAnalyticsSaga);
	yield takeLeading(getTrailerSessionNumbersAsynction.actionNames.request, getTrailerSessionNumbersSaga);
}

export default mainSaga;