import styled from 'styled-components';

export const HorizontalSpace = styled.div`
	width: ${({count}) => count*10}px;
`;

export const VerticalSpace = styled.div`
	height: ${({count}) => count*10}px;
`;

export const FlexSpace = styled.div`
	flex: 1;
`;