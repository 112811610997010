import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IoIosArrowDown } from "react-icons/io";

const AbsoluteContainer = styled.div`
  position: relative;
  z-index: ${({ seniority }) => 100+seniority};
`;

const RootContainer = styled.div`
	position: relative;
	background-color: #333;
	cursor: pointer;
	min-width: ${({theme, size}) => size !== 'small'?'170px':'100px'};
	border-radius: 25px;
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

const DropdownHeading = styled.div`
  flex: 1;
  padding: 5px 20px;
  font-weight: bolder;
  font-size: 18px;
  line-height: 27px;
  text-align: left;
  color: #ccc;
`;

const SelectedItem = styled(DropdownHeading)`
  font-weight: normal;
`;

const ListContainer = styled.div`
  position: absolute;
  z-index: -1; 
  width: 100%;
  top: calc(100% - 25px);
	max-height: 250px;
	background-color: #333;
	padding-top: ${({theme, isOpen}) => isOpen?20:0}px;
	border-bottom-left-radius: 25px;
	border-bottom-right-radius: 25px;
	overflow-y: scroll;
	&:-webkit-scrollbar {
	  display: none;
	}

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;
`;

const ListItem = styled.div`
  margin: ${({ theme, focused }) =>
    focused ? 16 : 0}px;
  padding: ${({ theme, focused }) =>
    focused
      ? `5px 0px`
      : '0px'};
  border-bottom: ${({ theme, focused }) =>
    focused ? `1px solid #555` : 'none'};
  transition: all 0.3s ease;
  overflow: hidden;
  height: ${({ focused }) => (focused ? 'auto' : '0px')};
  font-size: 15px;
  line-height: 17px;
  color: white;
  text-align: left;

  &:last-child {
    border-bottom: none;
  }
`;

export default ({icon, iconBackground, title, mandatory, items, onChange, value, size, seniority, background}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selected, setSelected] = useState({});

	const onSeleted = (item) => {
		onChange(item)
		setSelected(item);
		setIsOpen(false);
	}

	useEffect(() => {
		const currentItem = items.filter(item => item.name === value || item.value === value)
		setSelected(currentItem[0] || items[0])
	}, [value])
	
	return (
		<AbsoluteContainer seniority={seniority || 0} >	
			<RootContainer size={size} background={background} >
				<ListContainer isOpen={isOpen} >
					{
						items && items.length
						?
						items.map(item => <ListItem onClick={() => onSeleted(item)} focused={isOpen} >{item.name}</ListItem>)
						:
						null
					}
				</ListContainer>
				<HeadingContainer onClick={() => setIsOpen(!isOpen)} >
					{
						selected && (selected.name || selected.name === 0)
						?
						<SelectedItem>{selected.name}</SelectedItem>
						:
						<DropdownHeading>{title || ''} &nbsp;</DropdownHeading>
					}
					<IoIosArrowDown color='white' size='20px' style={{margin: "0px 10px", cursor: 'pointer', transition: 'all 0.3s ease', transform: `${isOpen?'rotate(180deg)':"none"}`}} />	
				</HeadingContainer>
			</RootContainer>
		</AbsoluteContainer>
	)
}
