import styled from 'styled-components';

export const Container = styled.div`
	width: max-content;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	border-radius: 5px;
	background-color: ${({theme}) => theme.colors.pureWhite};
`;

export const DurationOuterContainer = styled.div`
	display: flex;
	width: 50px;
	align-items: center;
	justify-content: flex-start;
`;

export const DurationLabel = styled.div`
	font-weight: bold;
	color: ${({theme}) => theme.colors.neutralGrey};
	padding: 5px;
`;

export const DurationItem = styled.input.attrs({type: 'text', maxLength: 2})`
	width: 100%;
	text-align: center;
	background-color: transparent;
	border: none;
	outline: none;
`;