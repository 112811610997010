import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  RootContainer,
  LoginContainer,
  InputContainer,
  Input,
  SubmitButton,
  PageHeader,
  LoaderContainer,
  UnderlinedButton,
  TransparentScreen,
} from "./Login.styles";
import { authActions } from "modules/auth";
import PropagateLoader from "react-spinners/PropagateLoader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default ({
  popLogin,
  setShowLoginDialog,
  triggerFetchSummaries,
  setIsSignUp,
  postAction,
  onRequestSummary,
  welcomeToast,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const authState = useSelector((state) => state.authReducer);
  const [queryParams, setQueryParams] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onVerifyPress = () => {
    dispatch(authActions.loginAsyncActions.request({ email: email, password }));
  };

  const verifyEmail = (e) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!re.test(e.target.value)) e.target.parentNode.style.borderColor = "red";
    else e.target.parentNode.style.borderColor = "#7289da";
  };

  useEffect(() => {
    // const goto = query.get('to')
    // if(goto) {
    // 	setQueryParams('/?to='+goto.replaceAll('|', '&'))
    // }

    if (authState.loginSuccess) {
      // checking if logining from popup
      if (setShowLoginDialog) {
        if (triggerFetchSummaries) triggerFetchSummaries();
        if (postAction) {
          welcomeToast();
          postAction();
        } else if (onRequestSummary) onRequestSummary();
        setShowLoginDialog(false);
      } else {
        // if(goto) {
        // 	history.push(`${goto.replaceAll('|', '&')}&auth=true&fetch=false`)
        // 	return;
        // }
        history.push(`/dashboard`);
      }
    }
  }, [authState]);

  return (
    <RootContainer popLogin={popLogin}>
      {popLogin ? (
        <TransparentScreen
          onClick={() => {
            if (!authState.isLoading) setShowLoginDialog(false);
          }}
        />
      ) : null}
      <LoginContainer>
        <PageHeader>Sign In</PageHeader>
        <InputContainer>
          <Input
            placeholder="Email*"
            fullWidth
            onChange={(evt) => setEmail(evt.target.value)}
            onFocus={(e) => {
              e.target.parentNode.style.borderColor = "#7289da";
            }}
            onBlur={(e) => {
              verifyEmail(e);
            }}
          />
        </InputContainer>
        <InputContainer>
          <Input
            placeholder="Password*"
            onChange={(evt) => setPassword(evt.target.value)}
            fullWidth
            type="password"
          />
        </InputContainer>
        {authState.isLoading ? (
          <PropagateLoader size={15} color="#7289da" />
        ) : (
          <LoaderContainer>
            <SubmitButton onClick={onVerifyPress}>Welcome back 🥳</SubmitButton>
            <UnderlinedButton onClick={() => history.push("/forgotPassword")}>
              Forgot Password?
            </UnderlinedButton>
          </LoaderContainer>
        )}
        <UnderlinedButton
          onClick={() => {
            popLogin
              ? setIsSignUp(true)
              : history.push(`/signup${queryParams}`);
          }}
        >
          New here? Create an account
        </UnderlinedButton>
      </LoginContainer>
    </RootContainer>
  );
};
