import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
    Garage,
    Editor,
    PodcastEditor,
    Login,
    Auth,
    Panel,
    Profile,
    Signup,
    ForgetPassword,
    ResetPassword,
    ErrorPage,
    Analytics,
    AuthSuccess,
    CreateTrailer,
    Dashboard,
    Podcast,
    SpecificAnalytics,
    PodcastEmbed,
    Comment
} from '../screens'
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Feedback from "feeder-react-feedback"; // import Feedback component
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet

const ProtectedRoute = ({children, ...rest}) => {
    const authState = useSelector(state => state.authReducer, shallowEqual)
    return (
        <Route
            {...rest}
            render={({ location }) =>
                authState.loginSuccess ? (
                    children
                ) : (
                    <Redirect
                        to="/auth"
                    />
                )
              }
        />
    )
}

export default () => {
    const authState = useSelector(state => state.authReducer, shallowEqual);
    return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Redirect to="/dashboard" />
                </Route>
                <ProtectedRoute exact path="/dashboard">
                    <Dashboard />
                </ProtectedRoute>
                <ProtectedRoute exact path="/podcast">
                    <Podcast />
                </ProtectedRoute>
                <Route exact path="/auth" >
                    <Feedback projectId="6194cfc85309550004f937aa" primaryColor="#138DCA" email={true} emailRequired emailDefaultValue={authState.email} />
                    <Auth />
                </Route>
                <Route exact path="/login" >
                    <Login />
                </Route>
                <Route exact path="/podcastEmbed" >
                    <PodcastEmbed />
                </Route>
                <Route exact path="/signup" >
                    <Signup />
                </Route>
                <Route exact path="/forgotPassword" >
                    <ForgetPassword />
                </Route>
                <Route exact path="/resetPassword/:resetId" >
                    <ResetPassword />
                </Route>               
                <Route path="/panel" >
                    <Panel />
                </Route>
                <Route path="/garage/:videoId" >
                    <Garage />
                </Route>
                <Route path="/editor/:videoId" >
                    <Editor />
                </Route>
                <Route path="/podcasteditor/:videoId" >
                    <PodcastEditor />
                </Route>
                <Route path="/comments">
                    <Comment />
                </Route>
                <ProtectedRoute exact path="/analytics" >
                    <Analytics />
                </ProtectedRoute>
                <ProtectedRoute path="/analytics/trailer/:id" >
                    <SpecificAnalytics />
                </ProtectedRoute>
                <ProtectedRoute path="/profile" >
                    <Feedback projectId="6194cfc85309550004f937aa" primaryColor="#138DCA" email={true} emailRequired emailDefaultValue={authState.email} />
                    <Profile />
                </ProtectedRoute>
                <Route path="/yt-auth-success" >
                    <Feedback projectId="6194cfc85309550004f937aa" primaryColor="#138DCA" email={true} emailRequired emailDefaultValue={authState.email} />
                    <AuthSuccess />
                </Route>
                <Route path="/create-trailer" >
                    <Feedback projectId="6194cfc85309550004f937aa" primaryColor="#138DCA" email={true} emailRequired emailDefaultValue={authState.email} />
                    <CreateTrailer />
                </Route>    
                <Route path="*">
                    <Feedback projectId="6194cfc85309550004f937aa" primaryColor="#138DCA" email={true} emailRequired emailDefaultValue={authState.email} />
                    <ErrorPage />
                </Route>
            </Switch>
        </Router>
    )
}
