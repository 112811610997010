
import styled from "styled-components";
import Theme from "Theme";


export const RootContainer = styled.div`
    padding: 1rem 4rem;
    min-height: 100vh;
    box-sizing: border-box;
    font-size: 1rem;
    

    @media only screen (max-wdith: 500px) {
        padding: 1rem 2rem;
    }
`;

export const HeroTitle = styled.h1`
    text-align: left;
`;

export const SearchContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 1rem 0;
`;

export const Search = styled.textarea.attrs({
    placeholder: 'Insert youtube video link'
})`
    resize:none;
    width: 500px;
    max-width: 90%;
    border: 1px solid #cccccc;
    border-radius: 10px;
    outline: none;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    font-size: 1rem;
`;
export const TotalProcessingText = styled.div`
    margin-left: 2rem;
    color: ${Theme.lightGrey};
    width: max-content;
`;