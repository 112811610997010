export const signupValidations = {
	name: {
		presence: {
			allowEmpty: false,
			message: "^You can't live without a name in this world!"
		},
		format: {
			pattern: "[a-zA-Z ]+",
			flags: "g",
			message: "^Your name cannot contain special charecters and numbers unless your father's name is Elon Musk!"
		}
	},
	password: {
		presence: {
			allowEmpty: false,
			message: "^You can't open a lock without it's key (Not legally atleast)!"
		}
	}
}