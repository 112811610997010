import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  RootContainer,
  PlayerTopNav,
  MenuContainer,
  MenuBtn,
  TopbarMenuHoverContainer,
  MenuItem,
  MenuEmbedIcon,
  MenuShareIcon,
  MenuLockIcon,
  MenuHomeIcon,
  TopbarSpaceFiller,
  TopbarRightContent,
  SearchIcon,
  SearchInput,
  EmbedIcon,
  ShareIcon,
  KeyboardShortcutKeyIcon,
  PlayerContainer,
  BulkUploadInput,
  SummaryContainer,
  VideoContainer,
  ChaptersTitleDiv,
  ClearAllChapterDiv,
  SummaryContainerHeader,
  SummaryContainerText,
  SummaryListContainer,
  SearchContainer,
  NoSummariesContainer,
  NoSummariesText,
  AddChapterButton,
  SummaryInput,
  VideoTitleContainer,
  VideoTitleText,
  ChannelTitleText,
  CopyContentContainer,
  CopyTextHeading,
  CopyTextContent,
  ModalContainer,
  KeyboardShortcutContainer,
  KeyboardShortcutCard,
  KeyboardShortcutName,
  KeyboardShortcutKey,
  CopyIconContainer,
  CopyTextContainer,
  MLLoadingContainer,
  Circle,
  NoDataImage,
  FeedBackIcon,
  SignupFormContainer,
  TextInputLabel,
  TextInput,
  Errors,
  CopyIcon,
  ShareDialogIconsContainer,
  ClearAllChapterContainer,
  ClearAllChapterContents,
  ClearAllChapterText,
  ButtonContainer,
  Button,
  AbsoluteContainer,
  GenerateEmailButton,
  TranscriptIcon,
  CloseButton,
} from "./Editor.styles";
import SquareLoader from "react-spinners/SquareLoader";
import ClipLoader from "react-spinners/ClipLoader";
import { useHistory } from "react-router-dom";
import moment from "moment";
import Summary from "./SummaryCard";
import { VideoPlayer } from "../../components/VideoPlayer.js";
import {
  LoadingOverlay,
  Modal,
  HorizontalSpace,
  VerticalSpace,
  DurationInput,
} from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { summaryActions } from "modules/summaries";
import { authActions } from "modules/auth";
import { useParams, useLocation } from "react-router-dom";
import {
  BiMenuAltRight,
  BiCheck,
  BiEditAlt,
  BiLink,
  BiExport,
} from "react-icons/bi";
import { HiCode } from "react-icons/hi";
import { AiFillHome } from "react-icons/ai";
import { FaKeyboard } from "react-icons/fa";
import { RiFileCopyFill } from "react-icons/ri";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
import keyboardShortcuts from "./assignedKeyboardSortcuts";
import { default as Loginpage } from "../Login/Login";
import { default as Signuppage } from "../Signup/Signup";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import validate from "validate.js";
import NoData from "assets/img/no-data.png";
import ReactTooltip from "react-tooltip";
import { useQuery } from "utils";
import { signupValidations } from "./constraints";
import { TranscriptModal, VideoHaveSlidesModal } from "./modal";
import { Tooltip } from "antd";
import { toasterOptions, transcriptToolTipText } from "./helper";

const windowLocation = window.location.href;
let showBackToHome = true,
  postAction,
  searchText = "";
let bulkUploadText = "";

const Input = ({ placeholder, onChange, label, value }) => {
  return (
    <>
      <TextInputLabel>{label}</TextInputLabel>
      <TextInput
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
      />
    </>
  );
};

const Editor = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const mode = query.get("mode");
  const location = useLocation();
  const { videoId } = useParams();
  const db = getFirestore();

  const playFunction = useRef(null);

  const [isMlLoading, setIsMlLoading] = useState(false);
  const [isMutated, setIsMutated] = useState(false);
  const [videoTitle, setVideoTitle] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  const [showSearch, setShowSearch] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [channelTitle, setChannelTitle] = useState("");
  const [videoDuration, setVideoDuration] = useState(0);
  const [previousState, setPreviousState] = useState(false);
  const [editVideoTitle, setEditVideoTitle] = useState(false);
  const [currentMlStatus, setCurrentMlStatus] = useState(null); //Values: 1 (Completed), 0 (Loading), -1 (Issue)
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [showTestPilotModel, setShowTestPilotModel] = useState(false);
  const [showKeyboardShortcuts, setShowKeyboardShortcuts] = useState(false);
  const [clearAllChaptersClicked, setClearAllChaptersClicked] = useState(false);
  const [showClearChapterConfirmation, setShowClearChapterConfirmation] =
    useState(false);
  const [chapterRequestedDialog, setChapterRequestedDialog] = useState({
    show: false,
    msg: "",
  });
  const [mlUniqueKey, setMlUniqueKey] = useState(null);
  // const [errorModal, setErrorModel] = useState({
  //   reveal: false,
  //   title: "",
  //   message: "",
  //   callbacks: [],
  // });
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ name: [], password: [] });
  const [showSlidesModal, setShowSlidesModal] = useState(false);
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [transcriptDisabled, setTranscriptDisabled] = useState(true);

  const summaryObject = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const playerState = useSelector((state) => state.playerReducer, shallowEqual);
  const authState = useSelector((state) => state.authReducer, shallowEqual);

  const onSearch = (text, summaries) => {
    searchText = text;

    const searchResults = summaries.filter((summary) => {
      let toInclude = false;

      if (summary && summary.type && summary.type === "INSERT") {
        toInclude = true;
      }

      if (summary.subpoints) {
        const subpointArray = summary.subpoints.map((subpoint) =>
          subpoint.title.toLowerCase().includes(text.toLowerCase())
        );
        toInclude = subpointArray.some((res) => res === true);
      }

      if (
        summary.title &&
        summary.title.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.summary &&
        summary.summary.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }

      if (
        summary.description &&
        summary.description.toLowerCase().includes(text.toLowerCase())
      ) {
        toInclude = true;
      }
      return toInclude;
    });
    setFilteredSummaries(searchResults || []);
  };

  const onSummaryChangesSave = (index, object) => {
    playFunction.current?.playVideo();
    setIsMutated(true);
    const currentSummaries = [...filteredSummaries];
    currentSummaries[index] = object;
    const sortedChapters = [...currentSummaries].sort(
      (a, b) => a.start - b.start
    );
    setFilteredSummaries(sortedChapters);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: sortedChapters })
    );
    document.getElementById("overlayContainer")?.focus();
  };

  const onVideoTitleChange = () => {
    setEditVideoTitle(false);
    dispatch(
      summaryActions.mutateSummariesAction.action({
        videoTitle,
        channel: channelTitle,
      })
    );
    setIsMutated(true);
    document.getElementById("overlayContainer")?.focus();
  };

  const onShareVideo = () => {
    if (isMutated) {
      toast.error(`Click on "Save all changes" button to enable sharing`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    copy(`${videoTitle}
${moment
  .duration(summaryObject?.metadata?.duration * 1000)
  .format(`${summaryObject?.metadata?.duration > 60 ? "hh:mm:ss" : "mm:ss"}`, {
    trim: summaryObject?.metadata?.duration > 60 ? true : false,
  })} | ${filteredSummaries.length} chapters 🔖
https://api.chapterme.co/share/video/${videoId}`);

    toast.success("Video link copied", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const onCopyChapters = () => {
    let chapters = "";

    summaryObject.summaries.forEach((summary) => {
      chapters =
        chapters +
        `${moment
          .duration(summary.start * 1000)
          .format(`${summary.start > 60 ? "hh:mm:ss" : "mm:ss"}`, {
            trim: summary.start > 60 ? true : false,
          })} - ${summary.title || summary.summary}
`;
    });
    toast.success("Chapters copied successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    copy("Chapters (Powered by ChapterMe) - \n" + chapters);
  };

  const onExportChapters = () => {
    let chapters = "Chapters (Powered by https://bit.ly/chapterme) - \n";

    summaryObject.summaries.forEach((summary) => {
      chapters =
        chapters +
        `${moment
          .duration(summary.start * 1000)
          .format(`${summary.start > 60 ? "hh:mm:ss" : "mm:ss"}`, {
            trim: summary.start > 60 ? true : false,
          })} - ${summary.title || summary.summary}\n`;
    });
    dispatch(
      authActions.exportToYoutubeAsyncActions.request({
        videoId,
        description: chapters,
      })
    );
  };

  const createNewChapter = () => {
    playFunction.current?.pauseVideo();
    let source = [];
    if (filteredSummaries)
      source = filteredSummaries.map((summary) => {
        if (summary.type === "INSERT" || summary.type === "EDIT")
          setIsMutated(true);
        return { ...summary, type: "default" };
      });
    setPreviousState([...source]);
    const newChapter = {
      title: "",
      description: "",
      start: playerState.currentPlayingTime,
      type: "INSERT",
    };
    source.push(newChapter);
    const sortedChapters = [...source].sort((a, b) => a.start - b.start);
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: sortedChapters })
    );
    setFilteredSummaries(sortedChapters);
    onVideoTitleChange();

    if (authState.enableRecommendations) {
      dispatch(
        summaryActions.getSummaryRecommendationAsyncAction.request({
          videoId,
          timestamp: playerState.currentPlayingTime,
        })
      );
    }
  };

  const onEditSummary = (index) => {
    playFunction.current?.pauseVideo();
    const source = filteredSummaries.map((summary) => {
      if (summary.type === "INSERT" || summary.type === "EDIT")
        setIsMutated(true);
      return { ...summary, type: "default" };
    });
    setPreviousState([...source]);
    source[index] = { ...source[index], type: "EDIT" };
    setCurrentTime(playerState.currentPlayingTime);
    setFilteredSummaries(source);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: source })
    );
  };

  const onDeleteChapter = (time) => {
    document.getElementById("overlayContainer")?.focus();
    // using filterSummary as source lends to bug while deleting chapter using search
    const source = [...summaryObject.summaries];
    const UpdatedSummaries = source.filter((chapter) => chapter.start !== time);
    // console.log("Other than Deleted One", UpdatedSummaries, summaryObject.summaries, time);
    setIsMutated(true);
    setCurrentTime(playerState.currentPlayingTime);
    // setFilteredSummaries(UpdatedSummaries);
    dispatch(
      summaryActions.mutateSummariesAction.action({
        summaries: UpdatedSummaries,
      })
    );
    onSearch(searchText, UpdatedSummaries);
    return;
  };

  const onSaveChanges = (isAutoSaving, chapters) => {
    if (authState.role === "TESTPILOT") {
      setShowTestPilotModel(true);
      return;
    }
    if (!authState.loginSuccess) {
      postAction = onSaveChanges;
      setShowLoginDialog(true);
      return;
    }
    showBackToHome = true;
    let sortedChapters = [];
    const autoSaved = isAutoSaving === true;
    if (autoSaved && chapters)
      sortedChapters = [...chapters].sort((a, b) => a.start - b.start);
    else
      sortedChapters = [...summaryObject.summaries].sort(
        (a, b) => a.start - b.start
      );
    const requestBody = {
      summary: sortedChapters,
      videoDuration: videoDuration,
      videoTitle: videoTitle,
      platform: summaryObject.platform || query.get("platform"),
      channel: channelTitle,
      autoSaved: autoSaved,
    };
    onVideoTitleChange();
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.postSummariesAsyncActions.request({ videoId, requestBody })
    );
    setShowShareDialog(true);
    setIsMutated(false);
    if (mode === "import" || mode === "ai" || mode === "diy") {
      history.replace(`${location.pathname}?platform=youtube`);
    }
    document.getElementById("overlayContainer")?.focus();
    return;
  };

  const onChangesCancel = () => {
    playFunction.current?.playVideo();
    setCurrentTime(playerState.currentPlayingTime);
    dispatch(
      summaryActions.mutateSummariesAction.action({ summaries: previousState })
    );
    setFilteredSummaries(previousState);
    document.getElementById("overlayContainer")?.focus();
  };

  const ClearAllChapters = () => {
    setIsMutated(false);
    setFilteredSummaries([]);
    setClearAllChaptersClicked(true);
    setPreviousState([...summaryObject.summaries]);
    dispatch(summaryActions.mutateSummariesAction.action({ summaries: [] }));
    setShowClearChapterConfirmation(false);
  };

  const onEmbedClick = () => {
    if (isMutated) {
      toast.error(`Click on "Save all changes" button to enable sharing`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    window.open(`https://app.chapterme.co/embedSettings/${videoId}`, "_self");
  };

  const keyPressHandling = (e) => {
    if ((e.key === "b" || e.key === "B") && !editVideoTitle) createNewChapter();
    if (e.key === "Enter") {
      onVideoTitleChange();
    }
  };

  const onSignup = () => {
    const payload = { name, password };

    const registerErrors = validate(payload, signupValidations);

    if (registerErrors) {
      setErrors(registerErrors);
      return;
    }

    setErrors({ name: [], password: [] });
    dispatch(
      authActions.upgradeAsyncActions.request({
        body: payload,
        callback: () => setShowTestPilotModel(false),
      })
    );
  };

  const onKeyboardDialogPopup = (state) => {
    if (!state)
      try {
        document.getElementById("overlayContainer")?.focus();
      } catch (e) {
        return;
      }
    setShowKeyboardShortcuts(state);
  };

  const findForwardMatch = (string) => {
    const regex = /([0-9]{1,2}?:[0-9]{1,2}:?[0-9]{1,2})(\W*)(.*)/g;
    const splittedStrings = regex.exec(string);
    if (splittedStrings && splittedStrings.length) {
      if (splittedStrings[1].split(":").length < 3) {
        splittedStrings[1] = "00:" + splittedStrings[1];
      }
      return {
        start: moment.duration(splittedStrings[1]).asSeconds(),
        title: splittedStrings[3],
      };
    }
  };

  const onBulkUploadClick = () => {
    if (bulkUpload) {
      if (bulkUploadText.length) {
        const match =
          bulkUploadText.match(
            /([0-9]{1,2}?:[0-9]{1,2}:?[0-9]{1,2})(\W*)(.*)/g
          ) || [];
        const autoChapters = match.map(findForwardMatch);
        const sortedChapters = autoChapters.sort((a, b) => a.start - b.start);

        if (!autoChapters.length)
          toast.warning("Format not supported", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        dispatch(
          summaryActions.mutateSummariesAction.action({
            summaries: sortedChapters,
          })
        );
      }
      bulkUploadText = "";
      setBulkUpload(false);
      setIsMutated(true);
    } else setBulkUpload(true);
  };

  function handleMlStatus(mlStatusCode, status) {
    if (mlStatusCode === 402) {
      toast.error(
        `You have reached your credit limit. [Click here] to add credits and continue using ChapterGPT like a pro. Check your "Video Credits" under profile settings for more info.`,
        toasterOptions({
          toastId: "ml-credit-toast",
          autoClose: false,
          onClick: () => {
            window.open(
              "https://chapterme.co/pricing.html?source=credit_limit_reached&loc=admin",
              "_blank"
            );
          },
        })
      );
      return;
    }
    if (status === -1) {
      toast.error(
        `ChapterGPT is acting up.... 😔. Please click on this message to request chapters for this video. Feel free to reach us at support@chapterme.co`,
        toasterOptions({
          toastId: "ml-acting-toast",
          autoClose: false,
          onClick: () => {
            const body = {
              accountId: authState.accountId,
              videoId,
              videoTitle,
              platform: "youtube",
              createdOn: moment(),
            };
            const callback = (status) => {
              const msg = status
                ? `Request received! We will notify you (support@chapterme.co) once chapters are available. Check Spam too 😉`
                : "Chapters have already been requested for this video. We will notify (support@chapterme.co) you once the chapters are available. Check spam too 😉";

              setChapterRequestedDialog({ show: true, msg });
            };
            dispatch(
              summaryActions.createRequestAsyncActions.request({
                body,
                callback,
              })
            );
          },
        })
      );
    }
  }

  useEffect(() => {
    window.onbeforeunload = (e) => {
      if (
        isMutated &&
        (clearAllChaptersClicked || summaryObject.summaries?.length)
      ) {
        e.preventDefault();
        e.returnValue = "";
        return;
      }
      delete e["returnValue"];
    };
  }, [isMutated, clearAllChaptersClicked, summaryObject.summaries?.length]);

  useEffect(() => {
    // console.log("Current Mode", mode, summaryObject.descriptionSummaries);
    setVideoTitle(summaryObject.videoTitle);
    switch (mode) {
      case "ai": {
        if (!summaryObject.usingML) {
          //   onMlStart();
          setShowSlidesModal(true);
        }
        break;
      }
      case "diy": {
        onKeyboardDialogPopup(true);
        return null;
      }
      case "import": {
        setFilteredSummaries(summaryObject.descriptionSummaries);
        setIsMutated(true);
        dispatch(
          summaryActions.mutateSummariesAction.action({
            summaries: summaryObject.descriptionSummaries,
          })
        );
        break;
      }
      default: {
        return null;
      }
    }
  }, [mode, JSON.stringify(summaryObject?.descriptionSummaries)]);

  useEffect(() => {
    try {
      document.getElementById("overlayContainer")?.focus();
    } catch (e) {
      return;
    }
  }, [currentTime]);

  useEffect(() => {
    setVideoTitle(summaryObject.videoTitle);
    setChannelTitle(summaryObject.channel);
    setFilteredSummaries(summaryObject.summaries);
  }, [
    summaryObject.videoTitle,
    summaryObject.summaries,
    summaryObject.channel,
  ]);

  useEffect(() => {
    if (mlUniqueKey) {
      const reatimeListener = onSnapshot(
        doc(db, "lambda-chapters", mlUniqueKey),
        (mlChapters) => {
          // console.log("INSIDE REALTIME LISTENER", mlChapters?.data())
          if (mlChapters?.data()?.isCompleted) setIsMlLoading(false);
          const { statusCode, isCompleted } = mlChapters?.data();
          setCurrentMlStatus(isCompleted);
          if (isCompleted === 1 && mode === "ai") {
            onSaveChanges(true, mlChapters?.data()?.chapters);
          }
          handleMlStatus(statusCode, isCompleted);
          if (mode === "ai") {
            const chapters = mlChapters?.data()?.chapters;
            setFilteredSummaries(chapters);
            dispatch(
              summaryActions.mutateSummariesAction.action({
                summaries: chapters,
              })
            );
          }
        }
      );
    }
    dispatch(
      summaryActions.fetchSummariesAsyncActions.request({
        videoId,
        platform: query.get("platform") || "youtube",
      })
    );

    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
    return () => {
      window.removeEventListener("pageshow", null);
    };
  }, [setIsMutated, mlUniqueKey]);

  useEffect(() => {
    if (currentMlStatus === 0) {
      toast.success(
        "Email will be sent to you when chapters are generated",
        toasterOptions({
          toastId: "ml-email-toast",
          autoClose: false,
        })
      );
    }
  }, [currentMlStatus]);

  return (
    <RootContainer id="root-container">
      <PlayerTopNav>
        <MenuContainer>
          <MenuHomeIcon
            onClick={() => {
              window.history.back();
            }}
          />
        </MenuContainer>
        {editVideoTitle ? (
          <div>
            <VideoTitleContainer>
              <SummaryInput
                onKeyDown={(e) => keyPressHandling(e)}
                placeholder="Add video title"
                value={videoTitle !== "New Summary" ? videoTitle : ""}
                onChange={(evt) => setVideoTitle(evt.target.value)}
              />
              <BiCheck
                style={{ cursor: "pointer" }}
                size={24}
                color="black"
                onClick={() => setEditVideoTitle(false)}
              />
            </VideoTitleContainer>
          </div>
        ) : (
          <div>
            <VideoTitleContainer>
              <VideoTitleText>{videoTitle || "New Summary"}</VideoTitleText>
              <BiEditAlt
                style={{ padding: "5px 15px" }}
                size={24}
                color="black"
                onClick={() => setEditVideoTitle(true)}
              />
            </VideoTitleContainer>
          </div>
        )}

        <TopbarSpaceFiller />

        <TopbarRightContent>
          {showSearch ? (
            <SearchInput
              onChange={(evt) =>
                onSearch(evt.target.value, summaryObject.summaries)
              }
            />
          ) : null}
          <Tooltip placement="bottom" title="Search Chapters">
            <SearchIcon
              className="select-nothing"
              onClick={() => setShowSearch(!showSearch)}
            />
          </Tooltip>
          {/*<FeedBackIcon  onClick={() => {window.open('https://forms.gle/uDKwdNnWip9WhmEy6', '_blank');}}  />*/}
          <Tooltip placement="bottom" title="Embed Video">
            <EmbedIcon onClick={onEmbedClick} />
          </Tooltip>
          <Tooltip placement="bottom" title="Copy Link">
            <CopyIcon onClick={onShareVideo} />
          </Tooltip>
          <Tooltip
            placement="bottom"
            title={transcriptToolTipText[String(currentMlStatus || "1")]}
          >
            <TranscriptIcon onClick={() => setShowTranscriptModal(true)} />
          </Tooltip>
          <Tooltip placement="bottom" title="Keyboard Shortcuts">
            <KeyboardShortcutKeyIcon
              onClick={() => onKeyboardDialogPopup(true)}
            />
          </Tooltip>
        </TopbarRightContent>
      </PlayerTopNav>

      <PlayerContainer>
        <VideoContainer onKeyDown={keyPressHandling}>
          {useMemo(
            () =>
              videoId ? (
                <VideoPlayer
                  ref={playFunction}
                  isLoggedIn={authState.loginSuccess}
                  selectedTimestamp={{ start: currentTime }}
                  setVideoDuration={setVideoDuration}
                  videoId={videoId}
                  platform={
                    query.get("platform")
                      ? query.get("platform")
                      : summaryObject.platform
                  }
                  onCopyVideo={onShareVideo}
                  colorElementsAry={[]}
                />
              ) : null,
            [
              summaryObject.platform,
              currentTime,
              summaryObject.summaries,
              summaryObject.isMutated,
              authState.loginSuccess,
              summaryObject.title,
              setVideoDuration,
            ]
          )}
        </VideoContainer>

        {playerState.showSummaries ? (
          <SummaryContainer>
            <ChaptersTitleDiv>Chapters</ChaptersTitleDiv>
            <ClearAllChapterDiv
              onClick={() => setShowClearChapterConfirmation(true)}
            >
              Clear All Chapters
            </ClearAllChapterDiv>
            {currentMlStatus === 0 || summaryObject.isLoading || isMlLoading ? (
              <MLLoadingContainer>
                <ClipLoader color="#7289da" loading={true} />
                <Circle>
                  {currentMlStatus === 0 ? (
                    <>
                      <div>Generating...</div>
                      <div style={{ fontSize: "12px", textAlign: "center" }}>
                        Email will be sent to you when chapters are generated
                      </div>
                    </>
                  ) : (
                    <div>Loading...</div>
                  )}
                </Circle>
              </MLLoadingContainer>
            ) : !filteredSummaries?.length && !bulkUpload ? (
              <NoDataImage src={NoData} />
            ) : null}
            <SummaryListContainer
              id="garage-summarylistcontainer"
              tabIndex={-1}
            >
              {/*bulk upload textbox and summary card*/}
              {bulkUpload ? (
                <BulkUploadInput
                  onChange={(evt) => {
                    bulkUploadText = evt.target.value;
                  }}
                />
              ) : filteredSummaries ? (
                !query.get("platform") || filteredSummaries.length ? (
                  filteredSummaries?.map((summary, index, summaryArray) => (
                    <Summary
                      key={`chapters-${index}`}
                      summary={summary}
                      videoId={videoId}
                      index={index}
                      isRecommendationLoading={
                        summaryObject.isRecommendationLoading
                      }
                      recommendations={summaryObject.currentRecommendations}
                      setCurrentTime={setCurrentTime}
                      createNewChapter={createNewChapter}
                      videoDuration={videoDuration}
                      summaryArray={summaryArray}
                      onSave={onSummaryChangesSave}
                      onCancel={onChangesCancel}
                      onDeleteSummary={onDeleteChapter}
                      edit={onEditSummary}
                      currentTime={currentTime}
                    />
                  ))
                ) : (
                  <NoSummariesContainer>
                    <NoSummariesText>
                      Chapters not available for this video. To add a new
                      chapter either press "b" (keyboard shortcut) or click on
                      "Add chapters" button 👇
                    </NoSummariesText>
                  </NoSummariesContainer>
                )
              ) : (
                <NoSummariesContainer>
                  <NoSummariesText>
                    Chapters not available for this video. To add a new chapter
                    either press "b" (keyboard shortcut) or click on "Add
                    chapters" button 👇
                  </NoSummariesText>
                </NoSummariesContainer>
              )}
            </SummaryListContainer>
            {currentMlStatus === 0 ? (
              ""
            ) : (
              <NoSummariesContainer>
                {!bulkUpload ? (
                  <AddChapterButton onClick={createNewChapter}>
                    Add <span>chapter (b)</span>
                  </AddChapterButton>
                ) : null}
                {isMutated ||
                (currentMlStatus && summaryObject.usingML) ||
                (isMutated && mode === "import") ? (
                  <AddChapterButton
                    onClick={onSaveChanges}
                    background="#daa172"
                  >
                    Save all <span>changes</span>
                  </AddChapterButton>
                ) : filteredSummaries?.length ? (
                  <AddChapterButton onClick={onCopyChapters}>
                    Copy all<span> chapters</span>
                  </AddChapterButton>
                ) : bulkUpload ? (
                  <AddChapterButton onClick={onBulkUploadClick}>
                    Add<span></span>
                  </AddChapterButton>
                ) : (
                  <AddChapterButton onClick={onBulkUploadClick}>
                    <span> </span>Upload<span> chapters</span>
                  </AddChapterButton>
                )}
              </NoSummariesContainer>
            )}
          </SummaryContainer>
        ) : null}

        {/*share dialogbox, showing after save changes*/}
        {showShareDialog ? (
          <Modal
            maxContent
            close={() => {
              setShowShareDialog(false);
            }}
          >
            <CopyContentContainer onClick={onShareVideo}>
              <CopyIconContainer>
                <BiLink color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Copy Link</CopyTextHeading>
                {/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
            <CopyContentContainer
              className="embed-btn"
              onClick={() =>
                window.open(
                  `https://app.chapterme.co/embedSettings/${videoId}`,
                  "_self"
                )
              }
            >
              <CopyIconContainer>
                <HiCode color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Customize &amp; Embed</CopyTextHeading>
                {/*<CopyTextContent>Embed the current video with our player in any site</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
            <CopyContentContainer onClick={onCopyChapters}>
              <CopyIconContainer>
                <RiFileCopyFill color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Copy All Chapters</CopyTextHeading>
                {/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
            <CopyContentContainer onClick={onExportChapters}>
              <CopyIconContainer>
                <BiExport color="#7289da" size={36} />
              </CopyIconContainer>
              <CopyTextContainer>
                <CopyTextHeading>Export Chapters to Youtube</CopyTextHeading>
                {/*<CopyTextContent>Sharable link for the video</CopyTextContent>*/}
              </CopyTextContainer>
            </CopyContentContainer>
            {showBackToHome ? (
              <CopyContentContainer
                className="embed-btn"
                onClick={() => history.replace(`/dashboard`)}
              >
                <CopyIconContainer>
                  <AiFillHome color="#7289da" size={36} />
                </CopyIconContainer>
                <CopyTextContainer>
                  <CopyTextHeading>Home</CopyTextHeading>
                </CopyTextContainer>
              </CopyContentContainer>
            ) : null}
          </Modal>
        ) : null}
        {/**/}
        {showClearChapterConfirmation ? (
          <ClearAllChapterContainer>
            <ClearAllChapterContents>
              <ClearAllChapterText>
                Do you want to delete all chapters?
              </ClearAllChapterText>
              <ButtonContainer>
                <Button onClick={() => setShowClearChapterConfirmation(false)}>
                  Cancel
                </Button>
                <Button onClick={ClearAllChapters}>OK</Button>
              </ButtonContainer>
            </ClearAllChapterContents>
          </ClearAllChapterContainer>
        ) : null}
        {chapterRequestedDialog.show ? (
          <ClearAllChapterContainer>
            <ClearAllChapterContents
              style={{ height: "150px", width: "500px" }}
            >
              <ClearAllChapterText>
                {chapterRequestedDialog.msg}
              </ClearAllChapterText>
              <ButtonContainer style={{ justifyContent: "flex-end" }}>
                {/*<Button onClick={() => setShowClearChapterConfirmation(false)} >Cancel</Button>*/}
                <Button
                  style={{ width: "max-content" }}
                  hoverbg="#25D366"
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Go Home
                </Button>
              </ButtonContainer>
            </ClearAllChapterContents>
          </ClearAllChapterContainer>
        ) : null}
        {/*keyboard shortcuts model*/}
        {showKeyboardShortcuts ? (
          <Modal large close={() => onKeyboardDialogPopup(false)}>
            <ModalContainer>
              <CopyTextHeading>
                Keyboard Shortcuts (Add chapters 10x faster!)
              </CopyTextHeading>
              {/*<CopyTextContent>Note: Except for save and cancel chapter, all other shortcuts will only work after you click on the video</CopyTextContent>*/}
              <KeyboardShortcutContainer>
                {keyboardShortcuts.map((shortcut, ind) => (
                  <KeyboardShortcutCard key={"keyboardShortcut" + ind}>
                    <KeyboardShortcutName>
                      {shortcut.title}
                    </KeyboardShortcutName>
                    <KeyboardShortcutKey
                      type={shortcut.type}
                      dangerouslySetInnerHTML={{ __html: shortcut.key }}
                    >
                      {}
                    </KeyboardShortcutKey>
                  </KeyboardShortcutCard>
                ))}
              </KeyboardShortcutContainer>
            </ModalContainer>
          </Modal>
        ) : null}
        {showTestPilotModel ? (
          <Modal maxContent close={() => setShowTestPilotModel(false)}>
            <ModalContainer>
              <CopyTextHeading>
                Test drive users can't save chapters 😥
              </CopyTextHeading>
              <CopyTextContent>
                Upgrade to an admin account for free to enjoy all features!
              </CopyTextContent>
              <VerticalSpace count={1} />
              <SignupFormContainer>
                <Input
                  onChange={setName}
                  placeholder="John Doe"
                  label="Full Name"
                />
                <Errors>{errors.name && errors.name[0]}</Errors>
                <VerticalSpace count={1} />
                <Input
                  onChange={setPassword}
                  placeholder="*********"
                  label="Password"
                />
                <Errors>{errors.password && errors.password[0]}</Errors>
                <VerticalSpace count={1} />
                {authState.isLoading ? (
                  <SquareLoader size={35} color="#138DCA" />
                ) : (
                  <AddChapterButton onClick={onSignup}>
                    Upgrade Now
                  </AddChapterButton>
                )}
              </SignupFormContainer>
            </ModalContainer>
          </Modal>
        ) : null}
      </PlayerContainer>
      <VideoHaveSlidesModal
        paid={authState.paid || false}
        visible={showSlidesModal}
        onClose={() => {
          setShowSlidesModal(false);
        }}
        email={authState.email}
        setMlUniqueKey={setMlUniqueKey}
      />
      <TranscriptModal
        visible={showTranscriptModal}
        onClose={() => {
          setShowTranscriptModal(false);
        }}
        api_key={authState.api_key}
        email={authState.email}
        paid={authState.paid || false}
      />
    </RootContainer>
  );
};

export default Editor;
