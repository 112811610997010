import {analyticalNumbersAsyncActions,
	sessionVideosAsyncActions,
	getTrailersAsyncActions,
	getTrailerSessionNumbersAsynction,
	videoAnalyticalNumbersAsyncActions,
	getSpecificTrailerAnalyticsAsyncActions
} from './actions';

const initialState = {
	isLoading: false,
	isSessionLoading: false,
	isError: false,
	analytics: {
		trailerData: []
	},
	currentTrailerAnalytics: {},
	sessionData: {},
	trailers: [],
	trailerSession: {},
	currentAnalysedTrailer: '',
}

export default (state = initialState, action) => {
	// if (analyticalNumbersAsyncActions.actionNames.request === action.type)
		// console.log(action.type, action.payload)
	switch(action.type) {
		case analyticalNumbersAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case analyticalNumbersAsyncActions.actionNames.success: {
			return {...state, analytics: {...action.payload},  isLoading: false}
		}

		case analyticalNumbersAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case getSpecificTrailerAnalyticsAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case getSpecificTrailerAnalyticsAsyncActions.actionNames.success: {
			return {...state, currentTrailerAnalytics: {...action.payload},  isLoading: false}
		}

		case getSpecificTrailerAnalyticsAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case videoAnalyticalNumbersAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case videoAnalyticalNumbersAsyncActions.actionNames.success: {
			return {...state, analytics: {...action.payload},  isLoading: false}
		}

		case videoAnalyticalNumbersAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case sessionVideosAsyncActions.actionNames.request: {
			return {...state, isSessionLoading: true}
		}

		case sessionVideosAsyncActions.actionNames.success: {
			return {...state, sessionData: action.payload,  isSessionLoading: false}
		}

		case sessionVideosAsyncActions.actionNames.error: {
			return {...state, isSessionLoading: false}
		}

		case getTrailersAsyncActions.actionNames.request: {
			return {...state, ...initialState, isSessionLoading: true}
		}

		case getTrailersAsyncActions.actionNames.success: {
			return {...state, trailers: [...action.payload], isSessionLoading: false}
		}

		case getTrailersAsyncActions.actionNames.error: {
			return {...state, isSessionLoading: false}
		}

		case getTrailerSessionNumbersAsynction.actionNames.request: {
			return {...state, isSessionLoading: true, currentAnalysedTrailer: action.payload}
		}

		case getTrailerSessionNumbersAsynction.actionNames.success: {
			return {...state, trailerSession: {...action.payload}, isSessionLoading: false}
		}

		case getTrailerSessionNumbersAsynction.actionNames.error: {
			return {...state, trailerSession: {}, isSessionLoading: false}
		}

		default:{
			return state;
		}
	}
}