import styled from 'styled-components';

export const RootWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	background-color: #F3F4F6;
`;

export const RootContainer = styled.div`
	flex: 1;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
`;

export const PageHeader = styled.div`
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 44px;
	text-align: left;
	transition: all 0.5s ease;
	margin: ${({open}) => open?"1.5rem 0":"3rem 0"};
	margin-bottom: 1.5rem;
	color: ${({theme}) => theme.blue};
`;

export const SectionContainer = styled.div`
	margin: 20px 50px;
	flex: 1;
	display: flex;
	flex-direction: column;
`;

export const Row = styled.div`
	display: flex;
	padding: 10px;
	align-items: center;
	justify-content: space-around;
	margin: 5px 0px;
	background-color: ${({theme, background}) => background?theme[background]:theme.white};
	cursor: ${({clickable}) => clickable?'pointer':'inherit'};
	transition: all 0.3s ease;
	transform-origin: top;
	transform: ${({hidden}) => hidden?'scaleY(0)':'scaleY(1)'};
`;

export const RowTitle = styled.div`
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
	text-align: left;
	transition: all 0.5s ease;
	color: ${({theme, text}) => text?theme[text]:theme.darkGrey};
`;

export const Expandable = styled.div`
	margin: 10px;
	padding-left: 25px;
	height: ${({expanded}) => expanded?"auto":"0px"};
	transition: all 0.3s ease;
	transform-origin: top;
	overflow: hidden;

`;

export const UseCaseWrapper = styled.div`
	padding: 5px;
	display: flex;
	justify-content: space-around;
`;

export const UseCaseContainer = styled.div`
	border: 1px solid ${({theme}) => theme.blue};
	background-color: ${({theme, selected}) => selected?theme.blue:theme.white};
	margin: 5px;
	padding: 5px;
	border-radius: 5px;
	padding-left: 15px;
	transform: ${({selected}) => selected?"scale(1.1)":"scale(1)"};
	min-width: 27%;
	transition: all 300ms ease;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.95)
	}
`;

export const UseCaseTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: ${({theme, selected}) => selected?theme.white:theme.blue};
`;

export const UseCaseSubtitle = styled.div`
	font-size: 14px;
	color: ${({theme, selected}) => selected?theme.white:theme.blue};
`;

export const TextInput = styled.input.attrs({type: "text"})`
	width: 50%;	
	border: none;
	outline: none;
	padding: 5px 10px;
	border-radius: 10px;
	background-color: #FFF;
	border: 1px solid #ccc;
`;

export const DownArrow = styled.img.attrs({src: "https://cdn3.iconfinder.com/data/icons/user-interface-169/32/chevron-bottom-512.png"})`
	height: 30px;
	width: auto;
`;

export const SaveButton = styled.div`
	padding: 10px 20px;
	background-color: ${({theme}) => theme.blue};
	border-radius: 10px;
	color: ${({theme}) => theme.white};
	font-weight: bold;
	cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
    	transform: scale(1.05);
    }

    &:active {
    	transform: scale(0.95);
    }
`;