import axios from "axios";
import { serverURL } from "../../constants";

export const login = async ({ email, password }) => {
  return axios
    .post(serverURL + "/admin/login/", { email, password })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return "INVALID_USER";
    });
};

export const signup = (payload) => {
  return axios.post(serverURL + "/admin/create/", payload);
};

export const upgrade = (accountId, payload) => {
  return axios.post(serverURL + "/admin/upgrade/" + accountId, payload);
};

export const getProfile = (accountId) => {
  return axios.get(serverURL + "/admin/profile/" + accountId);
};

export const createTestPilot = (payload) => {
  return axios.post(serverURL + "/admin/create-testpilot/", payload);
};

export const forgetPassword = ({ email }) => {
  return axios.post(serverURL + "/admin/forgetPassword/", { email });
};

export const resetPassword = ({ id, password }) => {
  return axios.post(serverURL + "/admin/resetPassword/", { id, password });
};

export const editProfile = ({ accountId, body }) => {
  return axios.post(serverURL + "/admin/edit/" + accountId, { ...body });
};

export const youtubeOAuth = (accountId) => {
  return axios.get(serverURL + `/admin/oauthRequest/${accountId}`);
};

export const fetchChannel = (accountId) => {
  return axios.get(serverURL + `/admin/${accountId}/yt-channels`);
};

export const fetchVideos = ({ accountId, playlistId }) => {
  return axios.get(
    serverURL + `/admin/${accountId}/yt-videos?playlistId=${playlistId}`
  );
};

export const postYoutubeComment = (payload) => {
  return axios.post(serverURL + `/admin/commentYoutubeVideo`, payload);
};
export const exportToYoutube = ({ data }) => {
  // console.log("API REQ BODY", data);
  return axios.post(serverURL + "/admin/exportChapters", data);
};
