import styled from "styled-components";

const transcriptBackgrounds = [
	"#00a8e8",
	"#ffb703",
	"rgba(0, 0, 255, 0.5)",
]

export const RootWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

export const RootContainer = styled.div`
    height: 100vh;
    width: 100%;
    max-width: 700px;
    overflow: hidden;

    audio {
        width: 100%;
    }
`;

export const HeaderContainer = styled.div`
    display: flex;
    align-items: center;

    @media only screen and (max-width: 550px) {
        flex-direction: column;
        justify-content: space-around;
        margin: 0px;
        height: auto;
    }
`;

export const HeaderImage = styled.img`
    width: 30%;
    max-width: 400px;
    align-self: center;
    height: auto;
    margin: 10px 0px 10px 10px;

    @media only screen and (max-width: 550px) {
        width: calc(100% - 20px);
        height: auto;
        margin: 10px;
    }
`;

export const PodcastDetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    position:relative;
    margin: 10px;
`;

export const PodcastHeading = styled.h2`
    margin: 5px 10px;
    color: ${({theme}) => theme.colors.darkGrey};
    text-align: left;
`;

export const PodcastChapters = styled.div`
    color: ${({theme}) => theme.colors.darkGrey};
    font-size:18px;
    font-weight: bold;
    text-align: left;
    margin: 5px 10px;

    @media only screen and (max-width: 550px) {
        text-align: center;
    }
`;

export const TabTitleBar = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
`;

export const TabTitle = styled.div`
    margin: 5px;
    padding: 5px;
    color: ${({theme, selected}) => selected?theme.colors.darkGrey:theme.colors.neutralGrey};
    border-bottom: 2px solid ${({theme, selected}) => selected?theme.colors.darkGrey:'transparent'};
    cursor: pointer;
`;

export const TabContainer = styled.div`
    width: 100%;
`;


export const TranscriptsWrapper = styled.div`
    height: 100%;
`;
export const TranscriptsOverflow = styled.div`
    width: 100%;
    height: 100%;
    overflow-y: auto;
`;
export const TranscriptSpeaker = styled.h3`
	text-align: left;
	color: ${({theme}) => theme.colors.darkGrey};
    margin-left:5px;

`;

export const TranscriptContainer = styled.div`
	// width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 10px;
	// margin-left: 10px;
`;

export const TranscriptText = styled.span`
    margin: 3px;
	padding: 2px;
	background: ${({speaker}) => transcriptBackgrounds[speaker-1]};
	color: ${({theme, speaker}) => speaker === null?theme.colors.neutralGrey:theme.colors.darkGrey};
	border-radius: 5px;
    cursor: pointer;
`;

export const ChapterContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 5px;
    padding: 5px;
    cursor: pointer;

    &:nth-child(even) {
        background-color: #eee;
    }
`;

export const ChapterTime = styled.div`
    flex: 1;
    text-align: left;
    font-weight: bold;
    color: ${({theme}) => theme.colors.darkGrey};
`;

export const ChapterText = styled.div`
    flex: 4;
    margin-left:10px;
    text-align: left;
    color: ${({theme}) => theme.colors.darkGrey};
`;