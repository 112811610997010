import {combineReducers} from 'redux';
import { put, takeEvery, all } from 'redux-saga/effects'
import summaryReducer, {summarySaga} from './summaries';
import authReducer, {authSaga} from './auth';
import analyticsReducer, {analyticsSaga} from './analytics';
import playerReducer from './player';

export function* rootSaga() {
  yield all([
  	authSaga(),
    summarySaga(),
    analyticsSaga(),
  ])
}


export default combineReducers({
	authReducer,
	summaryReducer,
	playerReducer,
	analyticsReducer,
})
