import {useState, useEffect} from 'react';
import {
	Container,
	Header,
	SearchContainer,
	HeaderTitle,
	HeaderSubtitle,
	HeaderLeftDivision,
	HeaderRightDivision,
	Logout,
	SearchContainerDivision,
	SearchContainerDivisionTitle,
	Input,
	SearchContainerActionButton,
	RecommendationContainer,
	RecommendedSection,
	RecommendedSectionTitle,
	RecommendedSectionViewMore,
	RecommendedSectionScrollable,
	RecommendedSlide,
	RecommendationLoadingContainer,
	RecommendedSlideTitle,
	RecommendedSlideSubtitle,
	PopupContainerText,
	RequestSummaryButton,
	TitleImage,
	NoSummariesContainer,
	CopyContentContainer,
	CopyTextHeading,
	CopyTextContent,
	ModalContainer,
	SearchScrollable,
	PlatformIndicatorImage,
} from './Panel.styles';
import {toast} from 'react-toastify';
import moment from 'moment';
import {Thumbnail, PreviewedVideo, Modal} from 'components';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {authActions} from 'modules/auth';
import {summaryActions} from 'modules/summaries';
import RingLoader from 'react-spinners/RingLoader';
import {default as Loginpage} from '../Login/Login';
import {default as Signuppage} from '../Signup/Signup';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const RequestSummaryToast = ({ closeToast, toastProps, onRequestSummary }) => {
	return (
		<HeaderLeftDivision>
			<RecommendedSectionTitle>Too busy to create chapters on your own?</RecommendedSectionTitle>
			<PopupContainerText>We will take care of it.Click here to request chapters. Once chapters are created you will get an email from support@chapterme.co Check your spam too!</PopupContainerText>
			{/*<SearchContainerActionButton disabled={false} onClick={onRequestSummary} >
				Request Chapters
			</SearchContainerActionButton>*/}
		</HeaderLeftDivision>
	
	)
}

let apiCallTimeout = undefined;

export default () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const authState = useSelector(state => state.authReducer);
	const summaryState = useSelector(state => state.summaryReducer);
	const [selectedPlatform, setSelectedPlatform] = useState('youtube');
	const [videoId, setVideoId] = useState('');
	const [searchLoading, setSearchLoading] = useState(false);
	const [urlInvalid, setUrlInvalid] = useState(false);
	const [searchText, setSearchText] = useState('')
	const [showLoginDialog, setShowLoginDialog] = useState(false);
	const [isSignUp, setIsSignUp] = useState(true);
	const [videoAlreadyRequested, setVideoAlreadyRequested] = useState(false);
	const query =  useQuery();
	const ytauth = query.get("ytauth");

	const onLogout = () => {
		dispatch(authActions.logoutAction.action())
		history.push('/login')
	}

	const onRequestSummary = () => {
		if(!authState.loginSuccess) {
			toast.dismiss()
			setShowLoginDialog(true);
			return;
		}

		let requestedBefore = false;
		for (let i = 0; i < summaryState.requests.length; i++)
		{
			// console.log(summaryState.requests[i]);
			if (summaryState.requests[i].videoId === videoId) {
				requestedBefore = true;
				break;
			}
		}

		if (requestedBefore)
			toast.warning('Video already requested!', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		else
		{		
			const body = {
				accountId: authState.accountId,
				videoId,
				videoTitle: summaryState.metadata.title,
				platform: selectedPlatform,
				createdOn: moment()
			}
			dispatch(summaryActions.createRequestAsyncActions.request(body))
		}

	}

	const urlParser = (text) => {
		setSearchText(text);
		clearTimeout(apiCallTimeout)
		setSelectedPlatform('')
		setUrlInvalid(false)
		const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
		var res = regex.test(text);
		if(!text) {
			setVideoId('')
			setUrlInvalid(false)
			return;
		}
		// if(!res) {
		// 	setSearchLoading(true)
		// 	apiCallTimeout = setTimeout(() => dispatch(summaryActions.searchVideosAsyncActions.request(text)), 1700)
		// 	return;
		// }
		let platform = text.match(/(https:\/\/)?([a-z]*\.)?(youtube|youtu|wistia|vimeo|wi)(\.[a-z]*)/)
		if(!platform) {
			setUrlInvalid(true)
			return;
		}
		let urlVideoId;
		switch(platform[3]) {
			case 'youtube': {
				urlVideoId = text.match(/(\/|%3D|v=|vi=)([0-9A-z-_]{11})([%#?&]|$)/)[2]
				// console.log("youtube", urlVideoId)
				break; 
			}
			// case 'wistia': {
			// 	urlVideoId = text.match(/https?:\/\/[^.]+\.(wistia\.com|wistia\.net|wi\.st)\/(embed\/iframe|medias)\/([0-9A-z-_]{10})(?:$|\/|\?.*)/)[3]
			// 	break; 
			// }
			// case 'vimeo': {
			// 	urlVideoId = text.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/)[3]
			// 	break;
			// }
			case 'youtu': {
				// urlVideoId = text.match(/(https:\/\/)?(youtu.be\/)([a-zA-Z0-9].*)/)[3]
				urlVideoId = text.match(/(\/|%3D|v=|vi=)([0-9A-z-_]{11})([%#?&]|$)/)[2]
				platform[3] = 'youtube'
				break;
			}
			default: {
				setUrlInvalid(true)
				break; 
			}
		}
		setSelectedPlatform(platform[3])
		setVideoId(urlVideoId)
		dispatch(summaryActions.fetchSummariesAsyncActions.request({videoId: urlVideoId, platform: platform[3]}))
	}

	const getYTVideos = (playlistId) => {
		dispatch(authActions.fetchVideosAction.request({accountId: authState.accountId, playlistId}));
	}

	const isRequestedBefore = () => {
		let requestedBefore = false;
		const recentVideos = summaryState.recentVideos || [];
		let checkList = recentVideos.map(vid => vid.videoId);

		if (checkList.includes(videoId))
			requestedBefore = true;
		else 
		{
			const requestedVideos = summaryState.requests || [];
			checkList = requestedVideos.map(vid => vid.videoId);
			requestedBefore = checkList.includes(videoId);
		}

		return requestedBefore;
	}

	useEffect(() => {
		if (ytauth) {
			dispatch(authActions.fetchChannelAction.request(authState.accountId));
		}
		// console.log(ytauth, authState.accountId);
	}, []);

	useEffect(() => {
		dispatch(summaryActions.mutateSummariesAction.action({videoTitle: '', summaries: []}))

		if(query.get('execRequest') && query.get('videoId')) {
			// console.log("QUERIES", query.get('videoId'), query.get('platform'));
			const body = {
				accountId: authState.accountId,
				videoId: query.get('videoId'),
				platform: query.get('platform'),
				createdOn: moment()
			}
			dispatch(summaryActions.createRequestAsyncActions.request(body))
		}


		if(authState.loginSuccess) {
			dispatch(summaryActions.recentVideosAsyncActions.request());
			dispatch(summaryActions.getRequestAsyncActions.request());
		}
	}, [dispatch, authState.loginSuccess])

	useEffect(() => {
		if(!selectedPlatform) {
			setVideoAlreadyRequested(true);
			toast.dismiss()
		}
		else if(!summaryState.videoTitle && summaryState.metadata && summaryState.metadata.title && selectedPlatform) {

			if (isRequestedBefore()) {
				setVideoAlreadyRequested(true);
			}
			else {
				setVideoAlreadyRequested(false);
				// toast.info(<RequestSummaryToast onRequestSummary={onRequestSummary} />, {
				// 	position: "top-right",
				// 	autoClose: false,
				// 	hideProgressBar: false,
				// 	closeOnClick: false,
				// 	pauseOnHover: true,
				// 	draggable: true,
				// 	progress: undefined,
				// 	toastId: 'requestSummary'
				// });
			}

		}
		else {

			if (isRequestedBefore()) {
				setVideoAlreadyRequested(true);
			}
			toast.dismiss();
		}
		
	}, [selectedPlatform, summaryState])

	useEffect(() => {
		setSearchLoading(summaryState.isSearchLoading)
	}, [summaryState.isSearchLoading])

	return (
		<Container>
			<Header>
				<HeaderLeftDivision>
					{
						authState.loginSuccess
						?
						<HeaderTitle>
							Welcome, {authState.name}
						</HeaderTitle>
						:null 
					}
					<HeaderSubtitle>{authState.email}</HeaderSubtitle>
				</HeaderLeftDivision>
				<HeaderRightDivision>
					{
						authState.loginSuccess
						?
						<Logout onClick={() => history.push('/analytics')} >Chapterlytics</Logout>
						:
						null
					}
					&nbsp;
					&nbsp;
					&nbsp;
					{
						authState.loginSuccess
						?
						<Logout onClick={() => {dispatch(authActions.youtubeOAuthAction.request(authState.accountId));}} >Link Youtube Account</Logout>
						:null
					}
					&nbsp;
					&nbsp;
					&nbsp;
					{
						authState.loginSuccess
						?
						<Logout onClick={onLogout} >Logout</Logout>
						:
						<Logout onClick={() => history.push('/login')} >Login</Logout>
					}
				</HeaderRightDivision>
			</Header>
			<SearchContainer>
				<SearchContainerDivision>
					<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
					<SearchContainerDivisionTitle>Search for a video (or) Paste a video link</SearchContainerDivisionTitle>
					<Input placeholder="We support YouTube for now" onChange={(evt) => urlParser(evt.target.value)} error={urlInvalid} />				
					{ 
						selectedPlatform === 'wistia'
						?	
						<SearchContainerActionButton disabled={urlInvalid || !selectedPlatform || !videoId || summaryState.isLoading} >
							{!summaryState.videoTitle?'Create chapters':'Edit chapters'}
						</SearchContainerActionButton>
						:
						null
					}
					{
						summaryState.metadata && summaryState.metadata.title && selectedPlatform
						?
						<PreviewedVideo video={{videoTitle: summaryState.metadata.title}} from="postSearch" thumbnail={summaryState.metadata.thumbnail} duration={summaryState.metadata.duration} buttonText={!summaryState.videoTitle?'Create chapters':'Edit chapters'} requestChapters={!videoAlreadyRequested?onRequestSummary:null} buttonClick={() => (urlInvalid || !videoId || !selectedPlatform)?null:window.open(`/editor/${videoId}?platform=${selectedPlatform}`, '_self')} />
						:
						null
					}
				</SearchContainerDivision>
				{
					searchLoading && searchText
					?
					<RecommendationLoadingContainer>
						<RingLoader size={60} color="#23272a" />
					</RecommendationLoadingContainer>
					:
					summaryState.searchedVideos.length && !selectedPlatform && searchText
					?
							<SearchScrollable>
								{
									summaryState.searchedVideos.map(video => (
										<PreviewedVideo key={video.title} video={video} from="searchVideos" urlParser={urlParser} />
									))
								}
							</SearchScrollable>
					:
					null
				}
			</SearchContainer>
			{
				authState.channelList && authState.channelList.channels.length?

					<RecommendationContainer>
						<RecommendedSection>
							<RecommendedSectionTitle>
								Channels
								<RecommendedSectionViewMore>
									{' '}
								</RecommendedSectionViewMore>
							</RecommendedSectionTitle>
							<RecommendedSectionScrollable>
								{
									summaryState.isRecentVideosLoading
									?
									<RecommendationLoadingContainer>
										<RingLoader size={60} color="#23272a" />
									</RecommendationLoadingContainer>
									:
									authState.channelList.channels.map(video => (
										<PreviewedVideo key={'recent'+video.videoId} from="channelList" video={{...video, action: getYTVideos}} />
									))
								}
							</RecommendedSectionScrollable>
						</RecommendedSection>
					</RecommendationContainer>
				:null
			}
			{
				authState.ytVideos && authState.ytVideos.length?

					<RecommendationContainer>
						<RecommendedSection>
							<RecommendedSectionTitle>
								ChapterMe Channel Videos
								<RecommendedSectionViewMore>
									{' '}
								</RecommendedSectionViewMore>
							</RecommendedSectionTitle>
							<RecommendedSectionScrollable>
								{
									authState.isRecentVideosLoading
									?
									<RecommendationLoadingContainer>
										<RingLoader size={60} color="#23272a" />
									</RecommendationLoadingContainer>
									:
									authState.ytVideos.map(video => (
										<PreviewedVideo key={'ytvideo'+video.id} from="channelList" video={video} />
									))
								}
							</RecommendedSectionScrollable>
						</RecommendedSection>
					</RecommendationContainer>
				:null
			}
			<RecommendationContainer>
				<RecommendedSection>
					<RecommendedSectionTitle>
						Recently Added
						<RecommendedSectionViewMore>
							{' '}
						</RecommendedSectionViewMore>
					</RecommendedSectionTitle>
					<RecommendedSectionScrollable>
						{
							authState.loginSuccess
							?
							summaryState.isRecentVideosLoading
							?
							<RecommendationLoadingContainer>
								<RingLoader size={60} color="#23272a" />
							</RecommendationLoadingContainer>
							:
							summaryState.recentVideos.length
							?
							summaryState.recentVideos.map(video => (
								<PreviewedVideo key={'recent'+video.videoId} video={video} />
							))
							:
							<RecommendationLoadingContainer>
								<div>No videos found</div>
							</RecommendationLoadingContainer>
							:
							<RecommendationLoadingContainer>
								<div>Login or signup to see your recent videos</div>
							</RecommendationLoadingContainer>
						}
					</RecommendedSectionScrollable>
				</RecommendedSection>
			</RecommendationContainer>
			<RecommendationContainer>
				<RecommendedSection>
					<RecommendedSectionTitle>
						Requested Videos
						<RecommendedSectionViewMore>
							{' '}
						</RecommendedSectionViewMore>
					</RecommendedSectionTitle>
					<RecommendedSectionScrollable>
						{
							authState.loginSuccess
							?
							summaryState.isRequestsLoading
							?
							<RecommendationLoadingContainer>
								<RingLoader size={60} color="#23272a" />
							</RecommendationLoadingContainer>
							:
							summaryState.requests.length
							?
							summaryState.requests.map((video, ind) => (
								<PreviewedVideo key={`requested${video.videoId}${ind}`} video={video} from="requestedVideos" />
							))
							:
							<RecommendationLoadingContainer>
								<div>No videos found</div>
							</RecommendationLoadingContainer>
							:
							<RecommendationLoadingContainer>
								<div>Login or signup to see your requested videos</div>
							</RecommendationLoadingContainer>
						}
						{
							showLoginDialog
							?
								isSignUp ?
								 	<Signuppage popLogin={true} setIsSignUp={setIsSignUp} onRequestSummary={onRequestSummary}  setShowLoginDialog={setShowLoginDialog} triggerFetchSummaries={() => {
										summaryActions.fetchSummariesAsyncActions.request(); 
										summaryActions.getRequestAsyncActions.request(); 
										summaryActions.recentVideosAsyncActions.request();
									 }}/>
								:
									<Loginpage popLogin={true} setIsSignUp={setIsSignUp} onRequestSummary={onRequestSummary}  setShowLoginDialog={setShowLoginDialog} triggerFetchSummaries={() => {
										summaryActions.fetchSummariesAsyncActions.request(); 
										summaryActions.getRequestAsyncActions.request(); 
										summaryActions.recentVideosAsyncActions.request();
									}} />
							:
							null
						}
					</RecommendedSectionScrollable>
				</RecommendedSection>
			</RecommendationContainer>
		</Container>
	)
}
							// <Modal close={() => setShowLoginDialog(false)} >
							// 	<ModalContainer>
							// 		<CopyTextHeading>Almost there!</CopyTextHeading>
							// 		<CopyTextContent>Create a new account or log in using existing account to get your video summarised</CopyTextContent>
							// 		<NoSummariesContainer>
							// 			<CopyContentContainer onClick={() => history.push(`/login?to=/panel?execRequest=true|videoId=${videoId}|platform=${selectedPlatform}`)} >
							// 					<CopyTextContent>Login</CopyTextContent>
							// 			</CopyContentContainer>
							// 			<CopyContentContainer onClick={() => history.push(`/signup?to=/panel?execRequest=true|videoId=${videoId}|platform=${selectedPlatform}`)} >
							// 					<CopyTextContent>Signup</CopyTextContent>
							// 			</CopyContentContainer>
							// 		</NoSummariesContainer>
							// 	</ModalContainer>
							// </Modal>