import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const RootContainer = styled.div` 
	height: 100%;
	width: 100%;
	background: ${props => props.theme.purple};
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const TitleImage = styled.img`
	width: 300px;
	height: auto;
	margin: 10px;
	margin-bottom: 3em;
	@media only screen and (max-width: 700px) {
		width: 60%;
		margin: 5px;
	}
`;
const MessageContainer = styled.div` 
	// height: 100px;
	display: flex;
	width: 700px;
	padding: 2em;
	margin-bottom: 5em;
	background: ${props => props.theme.white};
	border-radius: 4px;

	@media only screen and (max-width: 780px) {
		width: 100%;
	}
`;
const AuthedLogo = styled(TitleImage)` 
	// flex: 1;
	height: auto;
	width: 200px;
	margin-right: 2em;
	margin-bottom: 10px;

	@media only screen and (max-width: 500px) {
		width: 30%;
	}
`;
const Message = styled.div` 
	flex: 1;
	border-left: 2px solid ${props => props.theme.purple};
	font-weight: bold;
	display: flex;
	color: ${props => props.theme.purple};
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const HomeButton = styled.div` 
	width: max-content;
	margin: 2em 0;
	padding: 10px;
	background: ${props => props.theme.purple};
	color: white;
	border-radius: 3px;
	cursor: pointer;
`;

export default () => {

	const history = useHistory();

	return (
		<RootContainer>
			<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" alt='chapterme' onClick={() => window.open("https://chapterme.co", "_blank") }/>
			<MessageContainer>
				<AuthedLogo src="https://cdn.fastly.picmonkey.com/content4/previews/social/social_33_384.png" alt='youtube-logo' />
				<Message>
					Youtube Authentication successfull
					<HomeButton onClick={() => history.replace('/panel?ytauth=true')} >Back to Home</HomeButton>
				</Message>
			</MessageContainer>
		</RootContainer>
	);
}