import styled from 'styled-components';

export const RootWrapper = styled.div`
	display: flex;
	max-height: 100vh;
	display: flex;
	background-color: ${({theme}) => theme.colors.halfWhite};
	overflow-y: hidden
`;

export const RootContainer = styled.div`
	flex: 1;
	min-height: 100vh;
	overflow-y: scroll;
`;

export const PageTitle = styled.h1`
	margin: 10px 20px;
	text-align: left;
	color: ${({theme}) => theme.colors.darkGrey}
`;

export const TrailerTitle = styled.h3`
	margin: 10px 20px;
	text-align: left;
	color: ${({theme}) => theme.colors.darkGrey}
`;

export const CardContainer = styled.div`
	width: 100%;
`;

export const CardRowContainer = styled.div`
	width: calc(100% - 40px);
	background-color: ${({theme}) => theme.colors.pureWhite};
	margin: 10px 20px;
	padding: 20px 20px;
	border-radius: 20px;
	display: flex;
	align-items: center;
`;

export const CardCellContainer = styled.div`
	flex: 1;
	display: flex;
	align-items: center;
`;

export const NumberValue = styled.div`
	font-size: 3rem;
	font-weight: bold;
`;

export const CardTitle = styled(NumberValue)`
	font-size: 1.5rem;
	color: ${({theme}) => theme.colors.darkGrey};
	text-align: left;
`;

export const CardDetail = styled(CardTitle)`
	font-size: 1.5rem;
	font-weight: normal;
`;

export const CtaClickList = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

`;

export const CtaClickContainer = styled.div`
	width: 100%;
	margin: 5px 0px;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	color: ${({theme}) => theme.colors.darkGrey};

	strong {
		width: 20%;
	}
`;

export const ChartTabContainer = styled.div`
	width: 100%;
	height: auto;
`;

export const ChartTabSelector = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

export const ChartTabTitle = styled.h3`
	flex: 1;
	margin: 0px;
	padding: 10px 20px;
	text-align: center;
	color: ${({theme}) => theme.colors.darkGrey};
	cursor: pointer;
	border-bottom: 3px solid ${({theme, selected}) => selected?theme.colors.purple:'transparent'};
`;

export const ChartTab = styled.div`
	background-color: ${({theme}) => theme.colors.pureWhite};
`;