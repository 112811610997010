import React, { useState } from 'react';
import anime from 'animejs';
import {
	ControlIconsContainers,
	SettingsDetails
} from './overlay.style.js';


export const toggleFullscreen = () => {

	let elem = document.querySelector("#root-container");

	if (!document.fullscreenElement) {
	    elem.requestFullscreen()
	    	.catch(err => {
		      alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
		    });
  } else {
    document.exitFullscreen();
  }
}


export const playPauseAnimation = () => {
	anime({
	  targets: '#playPause-anime-btn',
	    scale: [
	    	{value: 0, duration: 0},
	    	{value: 4, duration: 200},
	    	{value: 6, duration: 300}
	    ],
	    opacity: [
	    	{value: 0, duration: 100},
	    	{value: 0.9, duration: 300},
	    	{value: 0, duration: 100},
	    ],
	    easing: 'easeInOutSine',
		loop: false,
	});
}

export const rightSeekingAnimation = () => {
	anime({
		targets: '#rightSeekingAnimeContainer',
		opacity: [
			{value: 1, duration: 300},
			{value: .0, duration: 500},
		],
		easing: 'easeInOutSine',
		loop: false
	});
	anime({
		targets: '#rightSeekingAnimeContainer .seekTriangle',
		opacity: [
			{value: 1, duration: 300},
			{value: .0, duration: 0},
		],
		delay: anime.stagger(100),
		loop: false,
	})
}

export const leftSeekingAnimation = () => {
	anime({
		targets: '#leftSeekingAnimeContainer',
		opacity: [
			{value: 1, duration: 300},
			{value: .0, duration: 500},
		],
		easing: 'easeInOutSine',
		loop: false
	});
	anime({
		targets: '#leftSeekingAnimeContainer .seekTriangle',
		opacity: [
			{value: 1, duration: 300},
			{value: .0, duration: 0},
		],
		delay: anime.stagger(100),
		loop: false,
	})
}


const openFullscreen = () => {
	let elem = document.getElementById('root-container');

	if (elem.requestFullscreen) {
		elem.requestFullscree
		.catch(err => {
		  alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
		});
	} else if (elem.mozRequestFullScreen) { /* Firefox */
		elem.mozRequestFullScreen()
			.catch(err => {
		      alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
		    });
	} else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
		elem.webkitRequestFullscreen()
			.catch(err => {
		      alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
		    });
	} else if (elem.msRequestFullscreen) { /* IE/Edge */
		elem = window.top.document.body; //To break out of frame in IE
		elem.msRequestFullscreen()
		.catch(err => {
		  alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
		});
	}


}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    window.top.document.msExitFullscreen();
  }
}

export const format = (sec) => {
	let seconds = parseInt(sec % 60);
	seconds = seconds < 10 ? `0${seconds}` : seconds;
	let minutes = parseInt(sec / 60);
	let hour = parseInt(minutes / 60);
	hour = hour < 10 ? `0${hour}` : hour;
	minutes = minutes % 60;
	return `${parseInt(hour) > 0 ? hour + ':' : ''}${minutes < 10 ? `0${minutes}` : minutes}:${seconds}`;
}

export const Settings = ({changePlaybackSpeed, playingSpeed, setShowColorPicker}) => {

	const [detail, setDetail] = useState('showSpeeds');
	const playBackRates = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
	
	return (
		<ControlIconsContainers style={{fontSize: '15px', width: '100%', flexDirection: 'column'}} className='settings-container' >
			<div style={{cursor: 'pointer', padding: '0px 10px'}} onClick={() => {setDetail(detail === 'showSpeeds' ? '' : 'showSpeeds')}} >Speed</div>
			{
				detail === 'showSpeeds'
				?
				<div className='ph10 flex1 fillWidth' style={{flex: 1, width: '100%'}} >
					<hr />	
					{
						playBackRates.map(arr => 
							<SettingsDetails key={arr} selected={arr === playingSpeed ? true : false}  onClick={() => {changePlaybackSpeed(arr);}} >
								{`${arr}x`}
							</SettingsDetails>
						)
					}
				</div>
				: null
			}
			<hr />
			{/*<div className='appearance' style={{cursor: 'pointer', padding: '0px 10px'}} onClick={() => {setDetail(detail === 'appearance' ? '' : 'appearance')}} >Appearance</div>
			{
				detail === 'appearance'
				? 
					<div className='ph10 flex1 fillWidth' style={{flex: 1, width: '100%'}} >
						<hr />
						<SettingsDetails onClick={() => {setShowColorPicker(true)}} style={{padding: '10px 0'}} >
							Player Color
						</SettingsDetails>
					</div>
				: null
			}
			<hr className='appearance' ></hr>*/}
		</ControlIconsContainers>
	);
}


export const  SettingIcons = ({onClick})  => {
	return(
		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" onClick={onClick}
				 viewBox="0 0 512 512" style={{enableBackground: 'new 0 0 512 512', height: `1.4rem`, width: `1.4rem`, cursor: 'pointer'}}>
				<g>
					<path d="M496.659,312.107l-47.061-36.8c0.597-5.675,1.109-12.309,1.109-19.328c0-7.019-0.491-13.653-1.109-19.328l47.104-36.821
						c8.747-6.912,11.136-19.179,5.568-29.397L453.331,85.76c-5.227-9.557-16.683-14.464-28.309-10.176l-55.531,22.293
						c-10.645-7.68-21.803-14.165-33.344-19.349l-8.448-58.901C326.312,8.448,316.584,0,305.086,0h-98.133
						c-11.499,0-21.205,8.448-22.571,19.456l-8.469,59.115c-11.179,5.035-22.165,11.435-33.28,19.349l-55.68-22.357
						C76.52,71.531,64.04,76.053,58.856,85.568L9.854,170.347c-5.781,9.771-3.392,22.464,5.547,29.547l47.061,36.8
						c-0.747,7.189-1.109,13.44-1.109,19.307s0.363,12.117,1.109,19.328l-47.104,36.821c-8.747,6.933-11.115,19.2-5.547,29.397
						l48.939,84.672c5.227,9.536,16.576,14.485,28.309,10.176l55.531-22.293c10.624,7.659,21.781,14.144,33.323,19.349l8.448,58.88
						C185.747,503.552,195.454,512,206.974,512h98.133c11.499,0,21.227-8.448,22.592-19.456l8.469-59.093
						c11.179-5.056,22.144-11.435,33.28-19.371l55.68,22.357c2.688,1.045,5.483,1.579,8.363,1.579c8.277,0,15.893-4.523,19.733-11.563
						l49.152-85.12C507.838,331.349,505.448,319.083,496.659,312.107z M256.019,341.333c-47.061,0-85.333-38.272-85.333-85.333
						s38.272-85.333,85.333-85.333s85.333,38.272,85.333,85.333S303.08,341.333,256.019,341.333z" fill='white' />
				</g>
			</svg>
	);
}