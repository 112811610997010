import React, { useEffect, useState } from "react";

import { IframePlayer } from "./players.styles.js";
import Overlay from "./overlay/overlay.js";

let wistiaPlayer;

const WistiaPlayer = ({
  selectedTimestamp,
  videoId,
  platform,
  addChapter,
  setCurrentDuration,
  onCopyVideo,
  colorElementsAry,
  hideChapterSeekerBtn,
  isLoggedIn,
}) => {
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0.01);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(100);
  const [playingSpeed, setPlayingSpeed] = useState(1);
  const [videoPlayed, setVideoPlayed] = useState(false);

  useEffect(() => {
    let existingScript = document.getElementById("wistia-script");

    if (!existingScript) {
      let script = document.createElement("script");
      script.id = "wistia-script";
      script.src = "https://fast.wistia.net/assets/external/E-v1.js";
      script.asycn = true;
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    window._wq = window._wq || [];
    window._wq.push({
      id: `${videoId}`,
      options: {
        controlsVisibleOnLoad: false,
        doNotTrack: true,
        playbar: false,
        playButton: false,
        playbackRateControl: false,
        settingsControl: false,
        smallPlayButton: false,
        volumeControl: false,
        fullscreenButton: false,
        isMuted: false,
        play: false,
      },
      onReady: (video) => {
        setDuration(video.duration());
        wistiaPlayer = video;
        seekTo(0.01);
        video.bind("secondchange", function (t) {
          setCurrentTime(t);
          setCurrentDuration(t);
        });
        video.bind("playbackratechange", function (playbackRate) {
          setPlayingSpeed(playbackRate);
        });
        // video.volume(100);
        video.mute();
        setIsMuted(true);
        setVolumeLevel(0);
      },
    });
  }, [videoId, setCurrentTime]);

  useEffect(() => {
    if (selectedTimestamp.start !== undefined && wistiaPlayer)
      wistiaPlayer.time(selectedTimestamp.start);
  }, [selectedTimestamp, duration]);

  const play = (state) => {
    if (!wistiaPlayer || !duration) return;
    setIsPlaying(state);
    if (state) wistiaPlayer.play();
    else wistiaPlayer.pause();

    if (!videoPlayed) {
      wistiaPlayer.unmute();
      setVolumeLevel(100);
      setIsMuted(false);
    }
  };

  const seekTo = (seconds) => {
    if (!wistiaPlayer || !duration) return;

    wistiaPlayer.time(seconds);
  };

  const setVolume = (value) => {
    if (!wistiaPlayer || !duration) return;
    setVolumeLevel(value);
    wistiaPlayer.volume(value * 100);

    if (parseFloat(value) === 0) {
      wistiaPlayer.mute();
      setIsMuted(true);
    } else {
      wistiaPlayer.unmute();
      setIsMuted(false);
    }
  };

  const changePlaybackSpeed = (rate) => {
    if (!wistiaPlayer || !duration) return;

    wistiaPlayer.playbackRate(rate);
  };

  // <div className={`wistia_embed wistia_async_${videoId} playerColor=ff0000`} style={{position: "absolute", top: 0, left: 0, width: '100%', height: "100vh"}} ></div>
  return (
    <div>
      <IframePlayer
        tabIndex={-1}
        className={`wistia_embed wistia_async_${videoId} playerColor=ff0000`}
      />
      <Overlay
        platform={"wistia"}
        isLoggedIn={isLoggedIn}
        onCopyVideo={onCopyVideo}
        addChapter={addChapter}
        currentTime={currentTime}
        play={play}
        isPlaying={isPlaying}
        duration={duration}
        isMuted={isMuted}
        volumeLevel={volumeLevel}
        seekTo={seekTo}
        setVolume={setVolume}
        changePlaybackSpeed={changePlaybackSpeed}
        volume="1"
        playingSpeed={playingSpeed}
        colorElementsAry={colorElementsAry}
        hideChapterSeekerBtn={hideChapterSeekerBtn}
      />
    </div>
  );
};

export default WistiaPlayer;
