import {setCurrentPlayingTimeAction, toggleSummariesAction} from './actions'

const initialState = {
	currentPlayingTime: 0,
	showSummaries: true,
}

export default (state = initialState, action) => {
	switch(action.type) {
		case setCurrentPlayingTimeAction.actionName: {
			return {...state, currentPlayingTime: action.payload}
		}

		case toggleSummariesAction.actionName: {
			// console.log("PLAYER ACTIONS", action, state)
			return {...state, showSummaries: !state.showSummaries}
		}

		default:
			return {...state}
	}
}