import axios from "axios";
import { serverURL } from "../../constants";

export const fetchSummaries = ({ videoId, platform }) => {
  return axios.get(
    serverURL + `/video/summary/${videoId}?platform=${platform}`
  );
};

export const upsertSummaries = (videoId, body) => {
  return axios.post(serverURL + "/video/summary/" + videoId, body);
};

export const recentVideos = (accountId) => {
  return axios.get(serverURL + "/admin/recentVideos/" + accountId);
};

export const createRequest = (body) => {
  return axios.post(serverURL + "/admin/newRequest/", body);
};

export const getRequest = (accountId) => {
  return axios.get(serverURL + "/admin/summaryRequests/" + accountId);
};

export const searchVideos = (query) => {
  return axios.get(
    serverURL + `/video/videoSearch?q=${query.replace(" ", "+")}`
  );
};

export const useMl = (video_id, isPaid) => {
  // return axios.get(`https://wa5pf2zyg6.execute-api.us-east-2.amazonaws.com/v001/chapters?service=youtube&videoId=${video_id}&paid=${isPaid?'1':'0'}`)
  return axios.post(`https://apis2.chapterme.co/chapters`, {
    url: `https://www.youtube.com/watch?v=${video_id}`,
    paid: isPaid,
  });
};

export const getRecommendations = ({ videoId, timestamp }) => {
  return axios.get(
    `https://j024vqaxf5.execute-api.us-east-2.amazonaws.com/v001/recommendation?service=youtube&videoId=${videoId}&start=${timestamp}`,
    { videoId, timestamp }
  );
};

export const requestPodcastChapters = (url) => {
  return axios.get(
    `https://wa5pf2zyg6.execute-api.us-east-2.amazonaws.com/v001/chapters?service=podcast&podcastUrl=${url}`
  );
};

export const requestPodcastTranscripts = (url) => {
  return axios.get(
    `https://35hzjv6cig.execute-api.us-east-2.amazonaws.com/v001/transcripts?service=podcast&podcastUrl=${url}`
  );
};
