import React, { forwardRef, useImperativeHandle } from "react";
import YoutubePlayer from "./YoutubePlayer.js";
import VimeoPlayer from "./VimeoPlayer.js";
import WistiaPlayer from "./WistiaPlayer.js";
import { playerActions } from "modules/player";
import { useDispatch } from "react-redux";

export const VideoPlayer = forwardRef(
  (
    {
      selectedTimestamp,
      videoId,
      platform,
      addChapter,
      onCopyVideo,
      setVideoDuration,
      colorElementsAry,
      hideChapterSeekerBtn,
      isLoggedIn,
      overlay,
    },
    ref
  ) => {
    const dispatch = useDispatch();

    const setCurrentDuration = (seconds) => {
      dispatch(
        playerActions.setCurrentPlayingTimeAction.action(Math.floor(seconds))
      );
    };

    switch (platform) {
      case "vimeo":
        return (
          <VimeoPlayer
            isLoggedIn={isLoggedIn}
            onCopyVideo={onCopyVideo}
            setCurrentDuration={setCurrentDuration}
            addChapter={addChapter}
            videoId={videoId}
            selectedTimestamp={selectedTimestamp}
            colorElementsAry={colorElementsAry}
            hideChapterSeekerBtn={hideChapterSeekerBtn}
          />
        );
      case "loom":
        return (
          <div>
            <iframe
              title="loom-player"
              src={`https://www.loom.com/embed/${videoId}?t=${selectedTimestamp.start}`}
              frameBorder="0"
              webkitallowfullscree="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100vh",
              }}
            ></iframe>
          </div>
        );
      case "wistia":
        return (
          <WistiaPlayer
            isLoggedIn={isLoggedIn}
            onCopyVideo={onCopyVideo}
            setCurrentDuration={setCurrentDuration}
            addChapter={addChapter}
            videoId={videoId}
            selectedTimestamp={selectedTimestamp}
            colorElementsAry={colorElementsAry}
            hideChapterSeekerBtn={hideChapterSeekerBtn}
          />
        );
      default:
        return (
          <YoutubePlayer
            ref={ref}
            isLoggedIn={isLoggedIn}
            setVideoDuration={setVideoDuration}
            onCopyVideo={onCopyVideo}
            setCurrentDuration={setCurrentDuration}
            addChapter={addChapter}
            videoId={videoId}
            selectedTimestamp={selectedTimestamp}
            colorElementsAry={colorElementsAry}
            hideChapterSeekerBtn={hideChapterSeekerBtn}
          />
        );
    }
  }
);
