import { Button, Modal, Skeleton, Space, Spin } from "antd";
import Title from "antd/es/typography/Title";
import Text from "antd/es/typography/Text";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getApiKey, getTranscript } from "./helper";
import { useQuery } from "utils";

export const VideoHaveSlidesModal = ({
  visible,
  paid,
  onClose,
  email,
  setMlUniqueKey,
}) => {
  const [loading, setLoading] = useState(false);
  const { videoId } = useParams();
  const query = useQuery();

  const onConfirm = async (state) => {
    setLoading(true);
    const platform = query.get("platform");
    let url = "";
    if (platform === "wistia") {
      url = `https://fast.wistia.net/embed/iframe/${videoId}`;
    } else if (platform === "vimeo") {
      url = `https://player.vimeo.com/video/${videoId}`;
    } else {
      // default to youtube
      url = `https://www.youtube.com/watch?v=${videoId}`;
    }

    try {
      const apiKey = await getApiKey(email);
      const { data } = await axios.post(
        `https://apis2.chapterme.co/chapters`,
        {
          url: url,
          paid,
          out_format: "json",
          pipeline: "CHAPTERS_AUDIO",
          is_presentation: state,
          run_in_background: true,
        },
        {
          headers: {
            "api-key": apiKey,
          },
        }
      );
      setMlUniqueKey(data.uniqueId);
    } catch (error) {
      console.log(error);
    }
    onClose();
    setLoading(false);
  };

  useEffect(() => {
    if (visible && paid) {
      onConfirm(true);
    }
  }, [visible, paid]);

  return (
    <Modal
      open={visible}
      onCancel={() => onConfirm(false)}
      onOk={() => onConfirm(true)}
      closable={false}
      keyboard={false}
      footer={
        <VideoHaveSlidesModalFooter
          onClose={() => onConfirm(false)}
          onConfirm={() => onConfirm(true)}
          disabled={loading}
        />
      }
      title={
        <Title level={3} style={{ marginTop: 0 }}>
          {!paid ? "Does 100% of your video contain slides?" : null}
        </Title>
      }
    >
      <Spin tip="ChapterGPT is warming up" spinning={loading}>
        This information helps ChapterGPT to provide better results.
        <div>
          <b>Example</b> -{" "}
          <a
            href="https://bit.ly/videos_with_100_percent_slides"
            target="_blank"
          >
            Sample videos with 100% slides
          </a>
        </div>
      </Spin>
    </Modal>
  );
};

const VideoHaveSlidesModalFooter = ({ onClose, onConfirm, disabled }) => {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    marginTop: "2rem",
  };
  return (
    <Space align="center" style={containerStyle}>
      {!disabled ? (
        <>
          <Button onClick={onClose} disabled={disabled}>
            No
          </Button>
          <Button
            type="primary"
            style={{ backgroundColor: "#35599c" }}
            onClick={onConfirm}
            disabled={disabled}
          >
            Yes
          </Button>
        </>
      ) : (
        <div style={{ height: "32px" }}></div>
      )}
    </Space>
  );
};

export const TranscriptModal = ({ visible, onClose, api_key, email, paid }) => {
  const [loading, setLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { videoId } = useParams();
  const query = useQuery();

  const urlRef = useRef("");

  const [transcriptText, setTranscriptText] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);

  const platform = query.get("platform");

  if (platform === "wistia") {
    urlRef.current = `https://fast.wistia.net/embed/iframe/${videoId}`;
  } else {
    // default to youtube
    urlRef.current = `https://www.youtube.com/watch?v=${videoId}`;
  }

  const getTranscriptJson = async () => {
    setLoading(true);
    setLoadingError(false);
    try {
      const apiKey = await getApiKey(email);
      const data = await getTranscript("json", urlRef.current, apiKey, paid);
      const sentenceArray = data.transcript.utterances.map((sentenceObj) => {
        return sentenceObj.words.map((wordObj) => wordObj.text).join(" ");
      });
      setTranscriptText(sentenceArray.join("\n"));
    } catch (error) {
      setLoadingError(true);
      console.log(error);
    }
    setLoading(false);
  };

  async function downloadTranscript() {
    setDownloadLoading(true);
    const data = await getTranscript("srt", urlRef.current, api_key);
    const blob = new Blob([data], { type: "text/srt" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "transcript.srt";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setDownloadLoading(false);
  }

  useEffect(() => {
    if (visible) getTranscriptJson();
  }, [visible]);

  const Header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
      }}
    >
      {loadingError ? (
        <Text type="danger">
          Error when generating transcript, Please try again
        </Text>
      ) : (
        <Title level={4}>
          {loading ? "Generating Transcript" : "Transcript"}
        </Title>
      )}
      {!loading && !loadingError && (
        <Button
          size="small"
          loading={downloadLoading}
          onClick={downloadTranscript}
        >
          Download .srt
        </Button>
      )}
    </div>
  );

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={Header}
      closeIcon={false}
      footer={<Button onClick={onClose}>Close</Button>}
    >
      <Skeleton loading={loading}>{transcriptText}</Skeleton>
    </Modal>
  );
};
