import './App.css';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import {ThemeProvider} from 'styled-components'
import theme from './Theme';
import RootRouter from './navigation';
import createStore from './store';
import {ToastContainer} from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

var moment = require("moment-timezone");
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);


const firebaseConfig = {
  apiKey: "AIzaSyA4OIQS0Z76PAlii30TIDjaYDxldnViIPQ",
  authDomain: "ml-chapterme.firebaseapp.com",
  projectId: "ml-chapterme",
  storageBucket: "ml-chapterme.appspot.com",
  messagingSenderId: "380580997019",
  appId: "1:380580997019:web:4c59c049e99192e1d8a33e",
  measurementId: "G-SE3LHRKCCT"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const {store, persistor} = createStore();

function App() {
  return (
	<Provider store={store} >
		<PersistGate loading={null} persistor={persistor}>	
		  	<ThemeProvider theme={theme} >
			    <div className="App">
			    	<RootRouter />
			    </div>
			</ThemeProvider>
			<ToastContainer
				position="top-left"
				autoClose={2500}
				hideProgressBar
				newestOnTop
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
		</PersistGate>
	</Provider>
  );
}

export default App;
