import {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import {useSelector, shallowEqual} from 'react-redux';
import {
	SummaryCard,
	SummaryTitle,
	SummaryDescription,
	SummaryCardTop,
	SummaryCardBottom,
	SummaryCardLeft,
	SummaryCardRight,
	SummaryTime,
	EditChapterBtn,
	DeleteChapterBtn,
	BtnContainerOnEdit,
	SaveBtn,
	CancelBtn,
	SummaryCardTopBar,
	SummaryCardTopBarLeftPiece,
	SummaryCardTopBarRightPiece,
	SummaryCardButtonContainer,
	SummaryInput,
	SummaryDescriptionInput,
	InputContainer,
	InputLabel,
	AddChapterButton,
	CopyContentContainer,
	CopyIconContainer,
	CopyTextContainer,
	CopyTextHeading,
	CopyTextContent,
	RecommendationsLoadingText,
	RecommendationsContainer,
	RecommendationItem,
	RecommendationItemSpan,
} from './PodcastEditor.styles';
import { Modal} from 'components';
import {DurationInput} from 'components'
import {toast} from 'react-toastify';
import { MdEdit, MdDelete, MdShare } from "react-icons/md";
import { BiLink, BiPaste } from "react-icons/bi";
import copy from 'clipboard-copy';
import stringMatchFinder from 'utils/stringSimilarityChecker';

let shiftPressed = false;

const Summary = ({summary, isRecommendationLoading, recommendations, videoId, setCurrentTime, currentTime, summaryArray, index, onSave, onDeleteSummary, currentChapterCard, onCancel, isEditing, edit, onClick}) => {
	const [selected, setSelected] = useState(false)
	const titleInputRef = useRef(null);
	const summaryCardRef = useRef(null);
	const descriptionInputRef = useRef(null)
	const durationInputRef = useRef(null)
	const [sortedRecommendations, setSortedRecommendations] = useState([{}])
	const [isMutated, setIsMutated] = useState(false)
	const [title, setTitle] = useState('');
	const [titleFocused, setTitleFocused] = useState(false);
	const [description, setDescription] = useState('');
	const [descriptionFocused, setDescriptionFocused] = useState(false);
	const [duration, setDuration] = useState(0)
	const [durationFocused, setDurationFocused] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const [showAllRecommendations, setShowAllRecommendations] = useState(false);
	const playerState = useSelector(state => state.playerReducer, shallowEqual)

	useEffect(() => {
		if(summaryArray.length === 1) {
			setSelected(true);
		}
		else if(index === summaryArray.length-1 && summaryArray[index].start <= playerState.currentPlayingTime) {
			setSelected(true);
		}
		else if(summaryArray[index].start <= playerState.currentPlayingTime && summaryArray[index+1].start > playerState.currentPlayingTime) {
			setSelected(true);
		}
		else {
			setSelected(false)
		}
	}, [summary.start, playerState]);



	const onSaveChanges = () => {
		const {type, ...restOfSummary} = summary;
		const editedSummary = {...restOfSummary, title, description}
		onSave(index, {title, description, start: duration});
	}

	const onChangesCancel = () => {
		setIsMutated(false);
		setTitle(summary.title)
		setDescription(summary.description)
		setDuration(summary.start)
		onCancel();
		scrollChapter(document.getElementById('current-chapter'));
	}

	// const onEnterPress = () => {
	// 	if(titleFocused && descriptionInputRef && descriptionInputRef.current)
	// 		descriptionInputRef.current.focus()
	// 	if(descriptionFocused && durationInputRef && durationInputRef.current)
	// 		durationInputRef.current.focus()
	// 	if(durationFocused && descriptionInputRef && descriptionInputRef.current)
	// 		onSave()
	// }

	const onNonEditKeyPress = e => {
		// console.log("KEYPRESS", index, selected, e.key)
		if(e.key === 'e') {
			edit(index)
		}
	}

	const onKeyPress = e => {
		if (e.key === 'Shift')
			shiftPressed = true;
		else if(e.key === "Escape")
			onChangesCancel()
		else if(e.key === "Enter" && !shiftPressed)
			onSaveChanges()
		else if(e.key === "ArrowRight" && titleFocused) {
			const caretPosition = titleInputRef.current.selectionStart;
			if(recommendations?.length && caretPosition === title.length ) {
				setTitle(sortedRecommendations[0].text)
			}

		}
		else if (e.key === "b" && summary.type == 'EDIT')
			return
	}

	const onCopyLink = () => {
		if(isMutated) {
			toast.warning('Save changes to enable sharing', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return;
		}
		const link = `https://api.chapterme.co/share/video/${videoId}/chapter/${summary.start}`;
		copy(link);
		toast.success('Chapter link copied', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}


	const scrollChapter = (elem) => {
		let y = elem?.offsetTop || 0;
		document.getElementById('garage-summarylistcontainer')?.scrollTo({
			top: y,
			behavior: 'smooth'
		});
	}

	useEffect(() => {
		if (selected)
			scrollChapter(document.getElementById('current-chapter'));
	}, [selected]);

	useEffect(() => {
		if (summary.type === 'EDIT' || summary.type === 'INSERT')
		{
			setTitle(summary.title);
			setDescription(summary.description);
			setDuration(summary.start);
			setTimeout(() => {
				titleInputRef.current.focus();
			}, 1000);
			if (summaryCardRef.current)
			{
				scrollChapter(summaryCardRef.current);
			} else if (selected) {
				scrollChapter(document.getElementById('current-chapter'));
			}
		}
	}, [summary.type, titleInputRef]);

	useEffect(() => {
		if(recommendations?.length) {
			const unsortedRecommendations = recommendations.map(rec => ({text: rec, match: stringMatchFinder(title, rec)}))
			setSortedRecommendations(unsortedRecommendations.sort(({match: a}, {match: b}) => b-a))

		}
	}, [recommendations, title])


	if(isEditing || summary.type === 'INSERT' || summary.type === 'EDIT') {
		return (
			<SummaryCard id={selected?'current-chapter':null} focused={selected} className="summary-card" ref={selected?currentChapterCard:null} onKeyDown={onKeyPress} onKeyUp={(e) => {if (e.key === 'Shift') shiftPressed = false;}} >
				<div style={{flex: 1}} onClick={e => e.stopPropagation()} >
					<SummaryTitle onMouseEnter={() => setShowAllRecommendations(true)} onMouseLeave={() => setShowAllRecommendations(false)} >
						<SummaryInput
							ref={titleInputRef}
							onFocus={() => setTitleFocused(true)}
							onBlur={() => setTitleFocused(false)} 
							placeholder={recommendations?.length?"Press → to auto populate chapters":"Chapter title"}
							value={title || ''}
							onChange={evt => setTitle(evt.target.value)}
						/>
						{
							isRecommendationLoading
							?
							<RecommendationsLoadingText>Loading Recommendations for you</RecommendationsLoadingText>
							:
							null
						}
						{
							recommendations?.length && summary.type === 'INSERT'
							?
							<RecommendationsContainer>
								<RecommendationsLoadingText>AI recommended Chapters</RecommendationsLoadingText>
								{
									showAllRecommendations
									?
									sortedRecommendations.map(item => <RecommendationItem onClick={() => setTitle(item.text)} >{item.text}</RecommendationItem>)
									:
									<RecommendationItem onClick={() => setTitle(sortedRecommendations[0].text)} >{sortedRecommendations[0].text}<RecommendationItemSpan>+{sortedRecommendations.length-1} More</RecommendationItemSpan></RecommendationItem>
								}
							</RecommendationsContainer>
							:
							null
						}
					</SummaryTitle>
					<SummaryDescription >
						<SummaryDescriptionInput rows="3" onFocus={() => setDescriptionFocused(true)} onBlur={() => setDescriptionFocused(false)}  value={description} placeholder="Chapter description" onChange={evt => setDescription(evt.target.value)} />
						<SummaryCardTopBarLeftPiece> &nbsp; &nbsp;<b>Shift+Enter</b> for a new line</SummaryCardTopBarLeftPiece>
					</SummaryDescription>
					<DurationInput value={summary.start} onChange={(value) => setDuration(value)} />
					<BtnContainerOnEdit>
						<SaveBtn onClick={onSaveChanges}>Enter</SaveBtn>
						<CancelBtn onClick={() => onChangesCancel()}>Cancel (Esc)</CancelBtn>
					</BtnContainerOnEdit>
				</div>
			</SummaryCard>
		)
	}

	return (
		<SummaryCard onKeyDown={onNonEditKeyPress} id={selected?'current-chapter':null} tabIndex={-1} focused={selected} className="summary-card" ref={selected?currentChapterCard:summaryCardRef} onClick={() => onClick(summary.start)} >
			<SummaryCardTop>
				<SummaryCardLeft>
					<SummaryTime onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >
						{
							moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })
						} 	{summary.subpoints && summary.subpoints.length ? summary.subpoints.length + ' Subpoints' : null} 
					</SummaryTime>
				</SummaryCardLeft>
				<SummaryCardRight>
					{/*<EditChapterBtn onClick={() => onEditSummary(index)}>Edit</EditChapterBtn>
					<DeleteChapterBtn onClick={() => onDeleteSummary(index)}>Delete</DeleteChapterBtn>*/}
					<EditChapterBtn  onClick={e => {edit(index); e.stopPropagation()}} />
					<DeleteChapterBtn onClick={e => {onDeleteSummary(summary.start); e.stopPropagation()}} />
				</SummaryCardRight>
			</SummaryCardTop>
			<SummaryCardBottom>
				<SummaryTitle ref={titleInputRef}  onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}}>
					{
						summary.title
						?
						summary.title
						:
						summary.summary
					}
				</SummaryTitle>
				<SummaryDescription onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}}>
					{
						summary.description
						?
						summary.description.substring(0, 100)+'...'
						:
						null
					}
				</SummaryDescription>
			</SummaryCardBottom>
		</SummaryCard>
	)
}

export default Summary;
