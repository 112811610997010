
import styled from "styled-components";
import Theme from "Theme";



export const Btn = styled.button`
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    border: 0px;
    outline: none;
    cursor: pointer;
    background-color: ${Theme.blue};
    color: white;
    cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
	opacity: ${({ disabled }) => disabled ? .3 : 1};
`;

export const RootContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;
export const TextContainer = styled.div`
    text-align: left;
    // flex: 1
`;
export const TextContent = styled.div`
    margin: 10px 0;
`;
export const ButtonWrap = styled.div`
    cursor: pointer;
    margin: 0 10px
`;
export const Title = styled.h4`
    margin: 0;
`;
export const ButtonContainer = styled.div`
    display: flex;
`;

export const SubHeading = styled.h3`
    text-align: left;
    margin-top: 40px;
    color: ${({ color }) => color || 'black'};
`;
export const Section = styled.div`
    padding: 50px 0;
`;
export const EmptyContent = styled.h2`
    margin: 0;
    text-align: center;
    color: lightGrey;
`;
// export const PopupRootContainer = styled.div`
//     position: absolute;
//     top: 0;
//     left: 0;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 100%;
//     width: 100%;
//     background-color: rgba(0, 0, 0, .4);
// `;
// export const PopupContainer = styled.div`
//     height: 90%;
//     max-height: 1500px;
//     width: 90%;
//     max-width: 600px;
//     padding: 1rem;
// `;
// export const PopupTitle = styled.div`
//     // font-size: 1rem;
// `;
// export const PopupChapters = styled.div`

//     overflow-y: auto;

//     &::-webkit-scrollbar {
//         width: 8px;
//         height: 8px;
//     }

//     &::-webkit-scrollbar-thumb {
//         background-color: #f5f5f5;
//         border-radius: 4px;
//     }

//     &::-webkit-scrollbar-track {
//         background-color: black;
//     }
  
// `;
// export const PopupChapter = styled.div`
//     padding: 10px;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
// `;