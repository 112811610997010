import {useEffect, useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Thumbnail} from 'components';

const RootContainer = styled.div`
	display: flex;
	padding: 10px;
	margin: 10px;
	height: 180px;
	width: 600px;
	border-radius: 5px;
	background: ${({theme}) => theme.halfWhite};

	@media only screen and (max-width: 820px) {
		flex-direction: column;
		padding: 5px;
	}
`;

const VideoThumbContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	align-self: center;
	box-sizing: border-box;
`;

const ThumbImage = styled.img`
	width: 220px;
	height: auto;
`;

const VideoTitle = styled.div`
	padding: 5px;
	font-weight: bold;
	font-size: 16px;
	color: ${({theme}) => theme.purple};
	text-align: left;
`;

const VideoSubtitle = styled.div`
	padding: 5px;
	font-weight: 500;
	font-size: 12px;
	color: ${({theme}) => theme.darkGrey}
`;

const VideoSummaryContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	flex: 2;
	margin: 0px 20px;

	@media only screen and (max-width: 820px) {
		margin: 0px 5px;
	}
`;
export const RecommendedSlide = styled.div`
	position: relative;
	border-radius: 5px;
	margin: 10px;
	background: #CCC;
	cursor: pointer;
	width: 240px;
	// display: flex;
	// flex-direction: column;
`;
export const RecommendedSlideTitle = styled.div`
	margin: 10px;
	color: ${({theme}) => theme.purple};
	font-size: 14px;
	font-weight: bold;
	text-align: left;
`;

export const RecommendedSlideSubtitle = styled.div`
	margin: 5px 10px;
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
	font-size: 12px;
`;

// const VideoSummaryTableContainer = styled.div`
// 	overflow-y: scroll;
// 	height: 100px;
// 	margin: 10px 0px;
// `;

// const SummaryRow = styled.div`
// 	background: ${({theme}) => theme.white};
// 	padding: 5px;
// 	display: flex;
// 	color: ${({theme}) => theme.darkGrey};
// 	font-weight: 500; 

// 	&:nth-child(even) {
// 		background: ${({theme}) => theme.halfWhite};
// 	}

// 	@media only screen and (max-width: 820px) {
// 		font-size: 12px;
// 	}
// `;

// const SummarySerial = styled.div`
// 	padding: 0px 5px;
// `;

// const SummaryTitle = styled.div`
// 	padding: 0px 5px;
// 	flex: 1;
// `;
const PlatformIndicatorImage = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 40px;
	position: absolute;
	top: 10px;
	right: 10px;
`;

const ActionButtonContainer = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: flex-start;
	width: 100%;
`;
export const ActionButton = styled.div`
	background: ${(props) => props.theme.purple};
	font-weight: bold;
	padding: 10px;
	margin: 10px 0;
	border-radius: 5px;
	color: ${(props) => props.theme.white};
	transition: all 0.5s ease;
	cursor: ${props => !props.disabled?'pointer':'not-allowed'};

	&:hover {
		color: ${({theme}) => theme.purple};
		background: ${({theme}) => theme.white};
	}
`;
const UnderlinedButton = styled.div`
	text-align: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 0.8em;
	text-decoration: underline;
	margin: 10px 0;
	cursor: pointer;
`;


const PreviewedVideo = ({video, thumbnail, duration, buttonText, requestChapters, buttonClick, from, urlParser}) => {

	const [summaryTitleTextLength, setSummaryTitleTextLength] = useState(27);

	const handleSummayTitleText = () => {
		setSummaryTitleTextLength(23);
		try {
			let chapterTitle = document.getElementsByClassName('preview-video-title');
			// console.log(chapterTitle);
			let parentElem = chapterTitle[0].parentElement;
			// console.log(chapterTitle[0])

			let totalTextInChapterTitle = chapterTitle[0].innerText.length;
			let totalTextCanBeDisplayed  = Math.floor((parentElem.clientWidth*totalTextInChapterTitle)/(chapterTitle[0].offsetWidth/2) - 10);
			// console.log(totalTextInChapterTitle, totalTextCanBeDisplayed);
			if (from === 'postSearch')
				totalTextCanBeDisplayed *= totalTextCanBeDisplayed;

			setSummaryTitleTextLength(totalTextCanBeDisplayed);
			
		} catch(e) {
			// statements
			// console.log(e);
		}
	}

	useEffect(() => {
		handleSummayTitleText()
		window.addEventListener('resize', () => {
			handleSummayTitleText()
		});

		return () => {window.removeEventListener('resize', () => {handleSummayTitleText()})}
	}, [handleSummayTitleText]);

	if (from === 'postSearch')
		return (
			<RootContainer >
				<VideoThumbContainer>
					<ThumbImage src={thumbnail} />
				</VideoThumbContainer>
				<VideoSummaryContainer forChannel={false} >
					<VideoThumbContainer>
						<VideoTitle><span className='preview-video-title' >
							{video.videoTitle.length < summaryTitleTextLength ? video.videoTitle : video.videoTitle.substr(0, video.videoTitle.lastIndexOf(' ', summaryTitleTextLength))+'...'}
						</span></VideoTitle>
						<VideoSubtitle>{
							duration.toString()[0] === 'P'?
								moment.duration(duration).format('hh:mm:ss', {trim: false}):moment.duration(duration*1000).format('hh:mm:ss')
							}</VideoSubtitle>
					</VideoThumbContainer>
					<ActionButtonContainer>
						<ActionButton onClick={buttonClick} >{buttonText}</ActionButton>
						{/*{
							//buttonText !== 'Edit Chapter' this is tmp way to hide "Request chapters"
							requestChapters !== null && buttonText !== 'Edit chapters'?
							<ActionButton onClick={requestChapters} >Request Chapters</ActionButton>
							:null
						}*/}
					</ActionButtonContainer>
					{
						//buttonText !== 'Edit Chapter' this is tmp way to hide "Request chapters"
						requestChapters !== null && buttonText !== 'Edit chapters'?
							<UnderlinedButton onClick={requestChapters}>Too busy to create chapters? Request chapters here</UnderlinedButton>
						:null
					}
				</VideoSummaryContainer>
			</RootContainer>		
		);
	else if (from === 'searchVideos')
		return (
			<RecommendedSlide key={video.title} onClick={() => urlParser(video.url)} >
				<PlatformIndicatorImage src={video.platform === 'youtube'?'https://cdn.fastly.picmonkey.com/content4/previews/social/social_33_384.png':'https://cdn2.iconfinder.com/data/icons/social-icon-3/512/social_style_3_vimeo-512.png'} />
				<Thumbnail src={video.thumbnail} />
				<RecommendedSlideTitle>
					<span className='preview-video-title'>
						{video.title.length < summaryTitleTextLength ? video.title : video.title.substring(0, summaryTitleTextLength)+'...'}
					</span>
					</RecommendedSlideTitle>
			</RecommendedSlide>
		)
	else if (from === 'requestedVideos')
		return (
		<RecommendedSlide key={video.videoTitle} onClick={() => window.open(`${window.location.origin}/editor/${video.videoId}`, '_self')} >
			<Thumbnail video={video} />
				<RecommendedSlideSubtitle>
					<RecommendedSlideTitle>{video.videoTitle || 'Untitled Video'}</RecommendedSlideTitle>
					<RecommendedSlideSubtitle>Platform: {video.platform}</RecommendedSlideSubtitle>
					<RecommendedSlideSubtitle>Created: {moment(video.createdOn).format('DD MMM YYYY')}</RecommendedSlideSubtitle>
				</RecommendedSlideSubtitle>
		</RecommendedSlide>
		);
	else if (from === 'channelList')
		return (
			<RecommendedSlide key={video.title} onClick={() => {video.action?video.action(video.uploadPlaylistId):window.open(`${window.location.origin}/editor/${video.id}`,'_self')}} >
				<Thumbnail src={video.thumbnail} />
					<RecommendedSlideSubtitle>
						<RecommendedSlideTitle>{video.title || 'Untitled'}</RecommendedSlideTitle>
					</RecommendedSlideSubtitle>
			</RecommendedSlide>
		);
	else
		return (
			<RecommendedSlide key={video.videoTitle} onClick={() => window.open(`${window.location.origin}/editor/${video.videoId}`, '_self')} >
				<Thumbnail video={video} />
				<RecommendedSlideTitle><span className='preview-video-title' >{video.videoTitle.length < summaryTitleTextLength ? video.videoTitle : video.videoTitle.substr(0, video.videoTitle.lastIndexOf(' ', summaryTitleTextLength))+'...'}</span></RecommendedSlideTitle>
				{ video.summary.length?
					<RecommendedSlideSubtitle>{moment.duration(video.summary[video.summary.length-1].start*1000).format(`${video.summary[video.summary.length-1].start > 60?'hh:mm:ss':'mm:ss'}`, {trim: video.summary[video.summary.length-1].start > 60?true:false})} | {video.summary.length} Chapters</RecommendedSlideSubtitle>
					:
					<RecommendedSlideSubtitle>No Chapter found</RecommendedSlideSubtitle>
				}
				<RecommendedSlideSubtitle>{video.viewCount} Views</RecommendedSlideSubtitle>
			</RecommendedSlide>
		);
}

export default PreviewedVideo;