import {useState, useEffect, useRef, Fragment} from 'react';
import {
	RootContainer,
	TranscriptContainer,
	TranscriptsWrapper,
	TranscriptSpeaker,
	TranscriptText,
	SwitchContainer
} from './AudioPlayer.styles';
import Switch from 'react-switch';
import {VerticalSpace} from 'components';
import {useDispatch} from 'react-redux';
import {playerActions} from 'modules/player';

const AudioPlayer = ({audio, transcripts, setRef}) => {
	const audioPlayerRef = useRef(null);
	const currentWordRef = useRef(null);
	const transcriptScroller = useRef(null);

	const dispatch = useDispatch();

	const [currentTime, setCurrentTime] = useState(0)
	const [autoScroll, setAutoScroll] = useState(true);
	const [segmentedTranscript, setSegmentedTranscript] = useState([])

	const segregateChapters = () => {
		let currentSpeaker;
		let allTranscripts = [];
		let currentSpeakerTranscripts = []

		transcripts.forEach(transcript => {
			if(!currentSpeaker) {
				// console.log("ENTERED FIRST CONDITION")
				currentSpeaker = transcript.speakerTag
				currentSpeakerTranscripts.push(transcript)
			}
			else if(transcript.speakerTag === currentSpeaker) {
				// console.log("ENTERED SECOND CONDITION")
				currentSpeakerTranscripts.push(transcript)
			}
			else {
				// console.log("SPEAKER TAG CHANGE", currentSpeakerTranscripts, currentSpeaker)
				allTranscripts.push({speaker: currentSpeaker, transcript: currentSpeakerTranscripts})
				currentSpeaker = transcript.speakerTag
				currentSpeakerTranscripts = [transcript]
			}
		})
		allTranscripts.push({speaker: currentSpeaker, transcript: currentSpeakerTranscripts});
		// console.log("All Transcripts", currentSpeaker, allTranscripts, currentSpeakerTranscripts);
		setSegmentedTranscript(allTranscripts);
	}

	useEffect(() => {
		if(transcripts?.length)
			segregateChapters()
	}, [transcripts])

	useEffect(() => {
		if(audioPlayerRef?.current) {
			setRef(audioPlayerRef);
			audioPlayerRef.current.addEventListener("timeupdate", () => {
				setCurrentTime(audioPlayerRef.current.currentTime);
				dispatch(playerActions.setCurrentPlayingTimeAction.action(Math.floor(audioPlayerRef.current.currentTime)))

			})
		}
	}, [audioPlayerRef]);

	useEffect(() => {
		// console.log("autoscroll", autoScroll, currentWordRef);
		if (autoScroll && currentWordRef.current) {
			const y = (currentWordRef.current?.offsetTop || 0) - 260;
			// console.log("autoscroll", y);
			transcriptScroller.current?.scrollTo({
				top: y,
				behavior: 'smooth'
			})
		}
	}, [autoScroll, currentWordRef.current, transcriptScroller])


	return (
		<RootContainer>
			<VerticalSpace count={2} />
			<audio src={audio} ref={audioPlayerRef} controls={1} controlsList="nodownload" />
			<VerticalSpace count={3} />
			<TranscriptsWrapper ref={transcriptScroller} >
				{
				segmentedTranscript.map((transcriptGroup, ind) => {
					return (
						<Fragment key={'group-'+ind}>
							<TranscriptSpeaker>Speaker {transcriptGroup.speaker}:</TranscriptSpeaker>
							<TranscriptContainer>
							{
								transcriptGroup.transcript?.map((transcript, ind) => {
									const speaker = transcript.start <= currentTime && transcript.end >= currentTime;
									return <TranscriptText key={'word-'+ind} ref={speaker?currentWordRef:null} speaker={speaker?transcript.speakerTag:null} onClick={() => audioPlayerRef.current. currentTime = transcript.start} >{transcript.word}</TranscriptText>
								})
							}
							</TranscriptContainer>
						</Fragment>
					)
				})
				}
			</TranscriptsWrapper>
			<VerticalSpace count={3} />
			{
			segmentedTranscript.length
			?
			<SwitchContainer>
				<Switch onChange={setAutoScroll} checked={autoScroll} uncheckedIcon={false} checkedIcon={false} height={20} width={40}/>
				<span>Auto Scroll</span>
			</SwitchContainer>
			:
			null
			}
		</RootContainer>
	)
}

export default AudioPlayer;
