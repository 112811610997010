import React, { useEffect, useState } from "react";

import { VimeoIframe } from "./players.styles.js";
import Overlay from "./overlay/overlay.js";

let vimeoPlayer, timeUpdater;

const VimeoPlayer = ({
  selectedTimestamp,
  videoId,
  addChapter,
  setCurrentDuration,
  onCopyVideo,
  colorElementsAry,
  hideChapterSeekerBtn,
  isLoggedIn,
}) => {
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0.01);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [volumeLevel, setVolumeLevel] = useState(1);
  const [playingSpeed, setPlayingSpeed] = useState(1);

  const setTimeUpdater = () => {
    clearInterval(timeUpdater);

    timeUpdater = setInterval(() => {
      vimeoPlayer.getCurrentTime().then(function (seconds) {
        setCurrentTime(seconds);
        setCurrentDuration(seconds);
      });
    }, 1000);
  };

  useEffect(() => {
    let existingScript = document.getElementById("vimeo-script");

    if (!existingScript) {
      let script = document.createElement("script");
      script.id = "vimeo-script";
      script.src = "https://player.vimeo.com/api/player.js";
      script.onload = () => {
        window.onVimeoAPIReady();
      };
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    window["onVimeoAPIReady"] = () => {
      vimeoPlayer = new window.Vimeo.Player(
        document.getElementById("vimeo-player"),
        {
          autoplay: 0,
          title: 0,
          sidedock: 0,
          controls: 0,
        }
      );

      vimeoPlayer.on("bufferstart", () => {
        clearInterval(timeUpdater);
      });

      vimeoPlayer.on("bufferend", () => {
        setTimeUpdater();
      });

      vimeoPlayer
        .getDuration()
        .then(function (duration) {
          setDuration(duration);
          // duration = the duration of the video in seconds
        })
        .catch(function (error) {
          // an error occurred
        });

      // setPlayingSpeed(1);
      // changePlaybackSpeed(1);
    };

    if (existingScript) {
      window.onVimeoAPIReady();
    }
  }, []);

  useEffect(() => {
    if (vimeoPlayer && selectedTimestamp.start !== undefined)
      vimeoPlayer
        .setCurrentTime(selectedTimestamp.start)
        .then(function (seconds) {
          // `seconds` indicates the actual time that the player seeks to
        })
        .catch(function (error) {
          switch (error.name) {
            case "RangeError":
              // The time is less than 0 or greater than the video's duration
              break;

            default:
              // Some other error occurred
              break;
          }
        });
  }, [selectedTimestamp]);

  const play = (state) => {
    if (!vimeoPlayer || !duration) return;

    setIsPlaying(state);
    if (state || !isPlaying) {
      vimeoPlayer
        .play()
        .then(function () {
          setIsPlaying(true);
          setTimeUpdater();
          // The video is playing
        })
        .catch(function (error) {
          switch (error.name) {
            case "PasswordError": {
              alert("This video is password protected!!");
              break;
            }

            case "PrivacyError": {
              alert("This is privated");
              break;
            }
            default:
              return;
          }
        });
    } else if (!state || isPlaying) {
      vimeoPlayer
        .pause()
        .then(function () {
          setIsPlaying(false);
          clearInterval(timeUpdater);
          // The video is playing
        })
        .catch(function (error) {
          switch (error.name) {
            case "PasswordError": {
              alert("This video is password protected!!");
              break;
            }

            case "PrivacyError": {
              alert("This is privated");
              break;
            }
            default:
              // alert ('Oops something went wrong!');
              return;
          }
        });
    }
  };

  const seekTo = (seconds) => {
    if (!vimeoPlayer || !duration) return;

    // play(true);
    vimeoPlayer.setCurrentTime(seconds);
  };

  const setVolume = (value) => {
    if (!vimeoPlayer || !duration) return;
    setVolumeLevel(value);
    vimeoPlayer.setVolume(value);

    if (parseFloat(value) === 0) setIsMuted(true);
    else setIsMuted(false);
  };

  const changePlaybackSpeed = (rate) => {
    if (!vimeoPlayer || !duration) return;
    vimeoPlayer.setPlaybackRate(rate);
    setPlayingSpeed(rate);
  };

  const url = `https://player.vimeo.com/video/${videoId}?app_id=f0cfd439316409280df41a1003525c81fdff2837&byline=0&portrait=0&transparent=0&controls=0&sidedock=0&title=0/`;

  return (
    <div>
      <VimeoIframe
        tabIndex={-1}
        url={url}
        allow="autoplay"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
      <Overlay
        isLoggedIn={isLoggedIn}
        onCopyVideo={onCopyVideo}
        addChapter={addChapter}
        currentTime={currentTime}
        play={play}
        isPlaying={isPlaying}
        duration={duration}
        isMuted={isMuted}
        volumeLevel={volumeLevel}
        seekTo={seekTo}
        setVolume={setVolume}
        changePlaybackSpeed={changePlaybackSpeed}
        volume="1"
        playingSpeed={playingSpeed}
        colorElementsAry={colorElementsAry}
        hideChapterSeekerBtn={hideChapterSeekerBtn}
      />
    </div>
  );
};

export default VimeoPlayer;
