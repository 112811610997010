import {toast} from 'react-toastify';
import {useState, useEffect} from 'react';
import {
	RootContainer,
	RightScreen,
	AnalyticsHeaderContainer,
	SectionSelector,
	SectionTitle,
	SectionContainer,
} from './Analytics.styles';
import Chapterme_short_logo from '../../chapterme_short_icon.png';
import moment from 'moment';
import Tooltip from 'react-tooltip';
import {Thumbnail, Navbar, Dropdown, Modal, VerticalSpace, HorizontalSpace} from 'components';
import TrailerAnalytics from './TrailerAnalytics';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {analyticsActions} from 'modules/analytics';
import {summaryActions} from 'modules/summaries';
import RingLoader from 'react-spinners/RingLoader';
import Humanize from 'humanize-plus';
import NoData from 'assets/img/no-data.png';
import {useTransition, animated} from 'react-spring';

const Analytics = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {analytics, isLoading, isSessionLoading, sessionData, trailers, trailerSession, currentAnalysedTrailer} = useSelector(state => state.analyticsReducer, shallowEqual)
	const summaryState = useSelector(state => state.summaryReducer, shallowEqual);
	const [selectedVideo, setSelectedVideo] = useState({});
	const [selectedSummary, setSelectedSummary] = useState({});
	const [currentScreen, setCurrentScreen] = useState('videos');

	const screenTransitions = useTransition(currentScreen, {
	    from: { opacity: 0, position: 'absolute', width: '100%', x: -250, y:0 },
	    enter: { opacity: 1, position: 'absolute', width: '100%', x: 0, y:0 },
	    leave: { opacity: 0, position: 'absolute', width: '100%', x: 250, y:0 },
	})

	useEffect(() => {
		dispatch(summaryActions.recentVideosAsyncActions.request());
		dispatch(analyticsActions.getTrailersAsyncActions.request());
	}, [])

	useEffect(() => {
		if(selectedSummary?.videoId)
			dispatch(analyticsActions.sessionVideosAsyncActions.request(selectedSummary.videoId))
	}, [selectedSummary])

	return (
		<RootContainer>
			<Navbar history={history} />
			<RightScreen>
				<VerticalSpace count={1} />
				<AnalyticsHeaderContainer>Analytics</AnalyticsHeaderContainer>
				<VerticalSpace count={1} />
				<SectionSelector>
					<SectionTitle id="videos" onClick={(evt) => setCurrentScreen(evt.target.id)} selected={currentScreen === 'videos'} >Videos</SectionTitle>
					<HorizontalSpace count={2} />
					<SectionTitle id="trailers" onClick={(evt) => setCurrentScreen(evt.target.id)} selected={currentScreen === 'trailers'} >Trailers</SectionTitle>
				</SectionSelector>
				<VerticalSpace count={3} />
				<SectionContainer>
					{
						screenTransitions((style, item) => 
							item === 'videos'
							?
							<animated.div style={style} >
								<b>Only available for closed beta testers.</b> If you are interested in participating send us an email to <a href="mailto:support@chapterme.co">support@chapterme.co</a> with the following subject - "Video Analytics Beta Access".
							</animated.div>
							:
							<animated.div style={style} >
								<TrailerAnalytics />
							</animated.div>
						)
					}
				</SectionContainer>
			</RightScreen>
			{/*
				!showTrailerVideos && false
				?
				<Modal close={() => setshowTrailerVideos(true)} >
					<ModalContainer>
						<ModalHeading>
							Get beta access!
						</ModalHeading>
						<VerticalSpace count={2} />
						<ModalContent>
							We are currently testing this feature in private beta. If you are interested in joining, click below:
						</ModalContent>
						<VerticalSpace count={2} />
						<Button onClick={() => {window.open('https://bit.ly/chapterme_analytics_beta_gforms', '_blank'); setshowTrailerVideos(true)}} >Interested →</Button>
					</ModalContainer>
				</Modal>
				:
				null
			*/}
		</RootContainer>
	)
}

export default Analytics;