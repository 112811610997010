import styled from 'styled-components';

export const Container = styled.div`
	height: 100vh;
	width: 100%;
	background: ${({theme}) => theme.darkGrey};
`;

export const Header = styled.div`
	display: flex;
	align-items: center;
	padding: 20px;
`;

export const HeaderLeftDivision = styled.div`
	flex: 1;
	text-align: left;
`;

export const HeaderRightDivision = styled.div`
	flex: 1;
	display: flex;
	justify-content: flex-end;
`;

export const HeaderTitle = styled.div`
	font-size: 1.3em;
	color: ${({theme}) => theme.white};
`;

export const HeaderSubtitle = styled.div`
	font-size: 1.3em;
	color: ${({theme}) => theme.purple};
`;

export const Logout = styled.div`
	background: ${({theme}) => theme.purple};
	padding: 10px;
	border-radius: 5px;
	color: ${({theme}) => theme.white};
	transition: all 0.5s ease;
	cursor: pointer;

	&:hover {
		color: ${({theme}) => theme.purple};
		background: ${({theme}) => theme.white};
	}

	&:active {
		transform: scale(0.8);
	}
`;

export const SearchContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: ${({theme}) => theme.purple};
	padding: 20px 0px;
`;

export const SearchContainerDivision = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
`;

export const TitleImage = styled.img`
	width: 300px;
	height: auto;
	margin: 10px;

	@media only screen and (max-width: 700px) {
		width: 60%;
		margin: 5px;
	}
`;

export const SearchContainerDivisionTitle = styled.div`
	color: ${({theme}) => theme.white};
	font-size: 1.5em;
	font-weight: bold;
	margin-bottom: 20px;
`;

export const Input = styled.input.attrs({type: 'text'})`
	border: none;
	outline: none;
	padding: 10px;
	border-radius: 5px;
	width: 50vw;
	border: 2px solid transparent;
	transition: all 0.4s ease;

	${props => props.error?'border: 2px solid #D00000; background: #FF8585;':null}
`;

export const ErrorText = styled.div`
	color: #99aab5;
`;

export const PlatformIndicatorContainer = styled.div`
	display: flex;
	align-items: center;
	margin: 20px;
`;

export const PlatformIndicator = styled.div`
	flex: 1;
	margin: 10px;
	border-radius: 5px;
	font-size: ${(props) => props.selected ? '1.2em' : '1em'};
	font-weight: ${(props) => props.selected ? 'bold' : 'normal'};
	border: 2px solid ${({theme}) => theme.white};
	background: ${(props) => props.selected ? props.theme.white : 'transparent'};
	color: ${(props) => props.selected ? props.theme.purple : props.theme.white};
	cursor: pointer;
	transition: all 0.3s ease;

	&:hover {
		transform: translateY(-5px);
	}

	&:active {
		transform: scale(0.85);
	}
`;

export const SearchContainerActionButton = styled.div`
	background: ${(props) => !props.disabled?props.theme.white:props.theme.halfWhite};
	display: inline-block;
	font-weight: bold;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
	color: ${(props) => !props.disabled?props.theme.purple:props.theme.lightGrey};
	transition: all 0.5s ease;
	cursor: ${props => !props.disabled?'pointer':'not-allowed'};
	opacity: ${props => !props.disabled?1:.8};

	${(props) =>
		!props.disabled
		?
		`&:hover {
			color: ${({theme}) => theme.white};
			background: ${({theme}) => theme.darkGrey};
		}

		&:active {
			transform: scale(0.8);
		}`
		:
		null
	}
`;

export const RecommendationContainer = styled.div`
	background: ${props => props.theme.white};
	padding: 20px 10px;
`;

export const RecommendedSection = styled.div`
	display: flex;
	flex-direction: column;
	background: ${({theme}) => theme.halfWhite};
	padding: 10px;
	border-radius: 20px;
	box-shadow: 0px 10px 20px 0px rgba(0,0,0,0.25);
`;

export const RecommendedSectionTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 18px;
	margin: 10px;
	text-align: left;
`;

export const PopupContainerText = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${({theme}) => theme.purple};
	font-size: 14px;
	margin: 10px;
	text-align: left;
`;

export const RecommendedSectionViewMore = styled.div`
	text-align: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 12px;
	text-decoration: underline;
	margin: 10px;
	padding: 10px;
	cursor: pointer;
`;

export const RecommendedSectionScrollable = styled.div`
	display: flex;
	overflow-x: scroll;
	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;  /* Firefox */

	&:-webkit-scrollbar {
	  display: none;
	}
`;

export const SearchScrollable = styled(RecommendedSectionScrollable)`
	width: 90vw;
	padding: 10px;
`;

export const RecommendationLoadingContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	padding: 30px;
`;

export const RecommendedSlide = styled.div`
	position: relative;
	border-radius: 10px;
	margin: 10px;
	background: #CCC;
	cursor: pointer;
`;

// export const RecommendedSlideImage = styled.img`
// 	width: 240px;
// 	height: 180px;
// `;

export const RecommendedSlideTitle = styled.div`
	margin: 10px;
	color: ${({theme}) => theme.purple};
	font-size: 14px;
	font-weight: bold;
	text-align: left;
`;

export const RecommendedSlideSubtitle = styled.div`
	margin: 5px 10px;
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
	font-size: 12px;
`;

export const RequestSummaryContainer = styled.div`
	width: auto;
	margin: 20px;
	padding: 20px;
	color: ${({theme}) => theme.white};
	font-weight: bold;
	font-size: 18px;
	border: 2px solid ${({theme}) => theme.white};
	border-radius: 10px;
	transition: all 0.4s ease;
	${props => props.show?'opacity: 1':'opacity: 0'};
`;

export const RequestSummaryButton = styled.div`
	text-align: center;
	margin: 10px 0px;
	color: ${({theme}) => theme.white};
	font-size: 18px;
	text-decoration: underline;
	cursor: pointer;
`;

export const NoSummariesContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-around;
	padding: 10px;
`;

export const CopyContentContainer = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	margin: 10px;
	border-radius: 5px;
	border: 1px solid ${({theme}) => theme.metallic};
	cursor: pointer;
`;

export const CopyIconContainer = styled.div`
	border-radius: 50%;
	padding: 10px;
`;

export const CopyTextContainer = styled.div`
	flex: 1;
	padding: 10px;
`;

export const CopyTextHeading = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${({theme}) => theme.white};
`;

export const CopyTextContent = styled.div`
	font-size: 18px;
	color: ${({theme}) => theme.metallic};
`;

export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
`;
