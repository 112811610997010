import {useState, useEffect, useRef} from 'react';
import {
	Container,
	DurationOuterContainer,
	DurationLabel,
	DurationItem,
} from './DurationInput.styles';

import moment from 'moment';

const DurationInput = ({onChange, value}) => {
	const [hours, setHours] = useState(moment.duration(value*1000).hours())
	const [minutes, setMinutes] = useState(moment.duration(value*1000).minutes())
	const [seconds, setSeconds] = useState(moment.duration(value*1000).seconds())

	const onDurationChange = () => {
		const newValue = moment.duration({hours, minutes, seconds}).asSeconds()
		onChange(newValue)
	}

	const scrollToTop = (child, parent) => {
		const y = child.offsetTop - parent.offsetTop - 50;
		parent.scrollTo({
			top: y,
			behavior: 'smooth'
		})
		return;
	}

	useEffect(() => {
		onDurationChange()
	}, [hours, minutes, seconds])

	return (
		<Container>
			<DurationOuterContainer>
				<DurationItem value={hours} onChange={evt => setHours(evt.target.value)} />
				<DurationLabel>:</DurationLabel>
			</DurationOuterContainer>
			<DurationOuterContainer>
				<DurationItem value={minutes} onChange={evt => setMinutes(evt.target.value)} />
				<DurationLabel>:</DurationLabel>
			</DurationOuterContainer>
			<DurationOuterContainer>
				<DurationItem value={seconds} onChange={evt => setSeconds(evt.target.value)} />
			</DurationOuterContainer>
		</Container>
	)
}

export default DurationInput;
