const Theme = {
	purple: '#7289da',
	blue: '#138DCA',
	transparent: 'transparent',
	pureWhite: '#fff',
	white: '#e5e7eb',
	metallic: '#99aab5',
	lightGrey: '#2c3f33',
	darkGrey: '#23272a',
	halfWhite: '#c8c9cb',
	red: '#d50000',
	green: '#00600f',
	colors: {
		darkGrey: '#424247',
		halfWhite: '#F7F7FF',
		neutralGrey: '#777777',
		pureWhite: '#FFFFFF',
		purple: '#7289da',
		red: '#d50000',
		green: '#00600f',
	}
}

export default Theme;