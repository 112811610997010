import styled, {keyframes} from 'styled-components';
import {RiPlayListAddLine} from 'react-icons/ri';

const PulseAnimations = keyframes`
	from {
		transform: scale(1.0);
		opacity: 1;
	}
	to {
		transform: scale(5);
		opacity: 0;
	}
`;

const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RootWrapper = styled.div`
	display: flex;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	background-color: #F3F4F6;
`;

export const RootContainer = styled.div`
	flex: 1;
	height: 100vh;
	padding: 2rem;
	overflow-y: auto;
	box-sizing: border-box;
`;
export const PageHeader = styled.div`
	font-size: 2.5rem;
	font-weight: bold;
	line-height: 44px;
	text-align: left;
	transition: all 0.5s ease;
	margin: ${({open}) => open?"1.5rem 0":"3rem 0"};
	color: ${({theme}) => theme.blue};
`;
export const SearchContainer = styled(Flex)`
	position: relative;
	flex-direction: column;
	font-size: 1.5rem;
	margin-top: 1rem;
	margin-bottom: 10px;
	color: ${({theme}) => theme.lightGrey};
	padding:  10px;
`;

export const SearchWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	transition: all 0.5s ease;
	height: ${({open}) => open?"70vh":"auto"};
`;

export const SearchResultWrapper = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SearchResultContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
`;

export const SearchResultThumb = styled.img`
	width: 300px;
	height: auto;
	margin: 15px;
`;

export const SearchResultContent = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

export const VideoTitle = styled.div`
	font-size: 24px;
	font-weight: bolder;
	text-align: left;
	color: ${({theme}) => theme.darkGrey};
`;

export const VideoSubtitle = styled(VideoTitle)`
	font-size: 20px;
	font-weight: normal;
`;

export const ActionButtonWrapper = styled.div`
	margin: 20px 0px 20px 10px;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
`;

export const ActionButtonContainer = styled.div`
	width: 50px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${({focused}) => focused?"#272932":"#fff"};
	border: 1px solid ${({theme}) => theme.blue};
	border-radius: 100px;
	transition: all 0.5s ease;
	cursor: ${({disabled}) => disabled?"not-allowed":"pointer"};

	& img {
		height: 30px;
		width: 30px;
	}
`;

export const ActionButtonDescription = styled.div`
	width: 100%;
	padding: 30px;
	background-color: ${({theme}) => theme.halfWhite};
	font-size: 16px;
	text-align: left;
	opacity: 0.7;
`;

export const EditButton = styled.div`
	padding: 10px 20px;
	background-color: ${({theme}) => theme.blue};
	border-radius: 10px;
	color: ${({theme}) => theme.white};
	font-weight: bold;
	cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
    	transform: scale(1.05);
    }

    &:active {
    	transform: scale(0.95);
    }
`;

export const SearchHighlighter = styled.div`
	position: absolute;
	bottom: 20px;
	right: 10%;
	width: 20px;
	height: 20px;
	border-radius: 20px;
	background-color: ${({theme}) => theme.blue};
	animation: ${PulseAnimations} 1s ease-out infinite;
`;

export const SearchInput = styled.input.attrs({
	type: 'text', 
	placeholder: 'Paste your YouTube video link here.'
})`
	width: 500px;
	font-size: 1rem;
	padding: 10px;
	border-radius: 10px;
    outline: none;
    margin-top: 1rem;
    transition: all 0.5s ease;
    background-color: ${({theme, error}) => error?"rgba(213, 0, 0, 0.5)":theme.white};
    border: 2px solid ${({theme, error}) => error?"rgba(213,0,0,1)":theme.purple};
`;
export const VideoListContainerWrapper = styled.div`
	position: relative;
	width: 100%;
`;
export const VideoListContainerHeader = styled.div`
	text-align: left;
	font-weight: bold;
	font-size: 1.3rem;
	margin: 2rem 0;
	color: ${({theme}) => theme.lightGrey};
	display: flex;
	justify-content: space-between;
	align-items: center;	
	box-sizing: border-box;
`;
export const VideoListContainer = styled.div`
	max-width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	grid-column-gap: 30px;
	grid-row-gap: 70px;
    flex-wrap: wrap;
    padding: 1rem 0;
	overflow: hidden;
	& .videoTitle {
		margin: 0;
	}
`;

export const VideoFullListContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
	grid-column-gap: 30px;
	grid-row-gap: 70px;
    flex-wrap: wrap;
    padding: 1rem 0;
	overflow: auto;

	& .videoTitle {
		margin: 0;
	}
`;

export const ShowAllButton = styled.div`
	padding: 5px 10px;
	background-color: ${({theme}) => theme.blue};
	border-radius: 10px;
	color: ${({theme}) => theme.white};
	font-size:0.8rem;
	font-weight: bold;
	cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
    	transform: scale(1.05);
    }

    &:active {
    	transform: scale(0.95);
    }
`;

export const YTLoginContainer = styled.div`
	text-align: center;
	height: 100px;
`;
export const YTLoginBtn = styled.div`
	position: relative;
	height: 1.3rem;
	width: max-content;
	margin: 10px auto;
	padding: 8px;
	// font-size: 1rem;
	border: none;
	border-radius: 7px;
	color: white;
	cursor: pointer;
	background: ${({theme}) => theme.blue};
	font-weight: normal;
`;
export const VideoTile = styled.div.attrs({
	className: 'videoTile'
})`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 200px;
	min-width: 300px;
	// margin: 1.5rem;
	border-radius: 5px;
	overflow: hidden;
	background: ${({src, thumbnail}) => thumbnail?`url(${thumbnail})`:`url("https://img.youtube.com/vi/${src}/0.jpg")`};
	background-repeat: no-repeat;
    background-size: cover;
    cursor: ${({clickable}) => clickable?'pointer':'inherit'};
    transition: all 0.5s ease;

    &:hover {
    	transform: scale(1.05);
    }
`;

export const VideoTileTitle = styled.div`
	font-size: 1rem;
	width: 100%;
	padding: 10px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	color: white;
	background: linear-gradient(to bottom, rgba(0, 0, 0, .7) 0%, rgba(0, 0, 0, 0) 100%);
`;
export const TileSpaceFiller = styled.div`
	flex: 1;
`;
export const VideoTileBottomDiv = styled.div`
	padding: 10px;
	background: rgba(255, 255, 255, 0.7);
	background-size: cover;
`;
export const VideoTileInfo = styled(Flex)`
	font-size: 0.9rem;
	font-weight: 300;
	justify-content: space-between;
`;
export const TileActionContainer = styled(Flex)`
	justify-content: space-around;
	padding-top: 10px;

	& img {
		height: 1.6rem;
		width: 1.6rem;
		cursor: pointer;
	}
`;

export const DescriptionChaptersSpan = styled.span`
	color: ${({theme, length}) => length?theme.green:theme.red};
	font-weight: bold;
`;
