import { useState, useEffect } from "react";
import {
  RootWrapper,
  RootContainer,
  PageHeader,
  SectionContainer,
  Row,
  RowTitle,
  UseCaseWrapper,
  SaveButton,
} from "./Profile.styles";
import { Navbar, FlexSpace } from "components";
import { useHistory } from "react-router-dom";
import SquareLoader from "react-spinners/SquareLoader";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Switch from "react-switch";
import { authActions } from "modules/auth";
import "feeder-react-feedback/dist/feeder-react-feedback.css"; // import stylesheet
import { Alert, Button, Card, Space } from "antd";
import { summaryActions } from "modules/summaries";
import { serverURL } from "../../constants";
import axios from "axios";
import moment from "moment";

function shallowEqualObjectComparison(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

const Profile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.authReducer, shallowEqual);
  const summaryState = useSelector(
    (state) => state.summaryReducer,
    shallowEqual
  );
  const [isMutated, setIsMutated] = useState(false);
  const [channelName, setChannelName] = useState(authState.displayName);
  const [useCase, setUseCase] = useState(authState.useCase);
  const [remainingCredits, setRemainingCredits] = useState(null);

  const remainingTime = moment.duration(
    remainingCredits?.credits_seconds_available || 0,
    "seconds"
  );

  const remainingTimeString = moment
    .utc(remainingTime.asMilliseconds())
    .format("H[h] m[m] s[s]");

  const onLogout = () => {
    dispatch(authActions.logoutAction.action());
    history.push("/auth");
  };

  const onSaveProfile = () => {
    const body = {
      displayName: channelName,
      channelName: channelName.replace(" ", "_").toLowerCase(),
      useCase,
    };

    dispatch(
      authActions.editProfileAsyncActions.request({
        accountId: authState.accountId,
        body,
        callback: () => setIsMutated(false),
      })
    );
  };
  const getRemainingCredits = async () => {
    try {
      const { data } = await axios.get(
        serverURL + "/admin/getRemainingCredits/" + authState.email
      );
      setRemainingCredits(data.result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    dispatch(summaryActions.recentVideosAsyncActions.request());
    getRemainingCredits();
  }, []);

  useEffect(() => {
    if (
      channelName !== authState.displayName ||
      !shallowEqualObjectComparison(useCase, authState.useCase)
    ) {
      setIsMutated(true);
    } else {
      setIsMutated(false);
    }
  }, [channelName, useCase]);

  // "name": "Rajkumar",
  // "email": "raj@videosummary.co",
  // "accountId": "60c022eb78044084ff8f811e",
  // "role": "ADMIN",
  // "api_key": "e5d53e8f-25e4-4686-a4f8-725d375671cb"

  return (
    <RootWrapper>
      <Navbar history={history} />
      <RootContainer>
        <PageHeader>Profile</PageHeader>
        <UseCaseWrapper>
          <Card style={{ width: 1000 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 10,
                }}
              >
                <div style={{ fontSize: 24, fontWeight: "bold" }}>
                  {authState.name}
                </div>
                <div style={{ fontSize: 16 }}>{authState.email}</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    fontSize: 16,
                  }}
                >
                  Role:{" "}
                  <Alert
                    message={authState.role}
                    style={{ padding: 5, fontSize: 12 }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: 10,
                    fontSize: 16,
                  }}
                >
                  API Key:
                  <Alert
                    message={authState.api_key}
                    style={{ padding: 5, fontSize: 12 }}
                  />
                </div>
              </div>
              <div>
                <Button onClick={onLogout}>Logout</Button>
              </div>
            </div>
          </Card>
        </UseCaseWrapper>
        <UseCaseWrapper>
          <Card>
            <Space align="center" size={"large"}>
              <Card
                title="Video Chaptered"
                style={{ width: 300 }}
                bordered={false}
                size="small"
              >
                {summaryState.recentVideos?.length || 0}
              </Card>
              <Card
                title="Video Credit as Duration"
                style={{ width: 300 }}
                bordered={false}
                size="small"
              >
                {remainingTimeString}
              </Card>
              <Card
                title="Plan"
                style={{ width: 300 }}
                bordered={false}
                size="small"
              >
                {authState.paid ? "Paid" : "Free"}
              </Card>
            </Space>
          </Card>
        </UseCaseWrapper>
        <SectionContainer>
          {/* <Row hidden={!isMutated} background="translate">
            <FlexSpace />
            {authState.isLoading ? (
              <SquareLoader size={35} color="#138DCA" />
            ) : (
              <SaveButton onClick={onSaveProfile}>Save</SaveButton>
            )}
          </Row> */}
          {/* <Row clickable onClick={() => setUseCaseExpanded((p) => !p)}>
            <RowTitle>Use Cases</RowTitle>
            <FlexSpace />
            <DownArrow />
          </Row> */}
          <Row clickable={false}>
            <RowTitle>
              Show recommendations while adding individual chapters
            </RowTitle>
            <FlexSpace />
            <Switch
              onChange={(state) =>
                dispatch(authActions.updateRecommendationSettingAction.action())
              }
              checked={authState.enableRecommendations}
            />
          </Row>
          <FlexSpace />
          {/* <Row clickable onClick={onLogout}>
            <RowTitle text="red">Logout</RowTitle>
            <FlexSpace />
          </Row> */}
        </SectionContainer>
      </RootContainer>
    </RootWrapper>
  );
};

export default Profile;
