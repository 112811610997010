import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {RootContainer, LoginContainer, InputContainer, Input, SubmitButton, PageHeader, LoaderContainer, UnderlinedButton} from './ForgetPassword.styles'
import {authActions} from 'modules/auth';
import PropagateLoader from 'react-spinners/PropagateLoader'

export default () => {
	const dispatch = useDispatch()
	const history = useHistory();
	const authState = useSelector(state => state.authReducer);
	const [email, setEmail] = useState('');

	const onVerifyPress = () => {
		dispatch(authActions.forgetPasswordAsyncActions.request({email}))
	}

	return (
		<RootContainer>
			<LoginContainer>
				<PageHeader>Reset Password</PageHeader>
				<InputContainer>
					<Input placeholder="Email*" fullWidth onChange={evt => setEmail(evt.target.value)} />
				</InputContainer>
				{
					authState.isLoading
					?
					<PropagateLoader size={15} color="#7289da" />
					:
					<LoaderContainer>
						<SubmitButton onClick={onVerifyPress} >Reset Password</SubmitButton>
					</LoaderContainer>
				}
				<UnderlinedButton onClick={() => history.push('/')} >Back to login</UnderlinedButton>
			</LoginContainer>
		</RootContainer>
	)
}