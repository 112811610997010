import styled from 'styled-components';

export const RootContainer = styled.div`
	position: relative;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	background: #373B44;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #4286f4, #373B44);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #4286f4, #373B44); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const BannerContainer = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 40%;
	padding: 15px;
`;

export const BannerTitle = styled.div`
	font-size: 72px;
	color: ${({theme}) => theme.white};
	font-weight: bolder;
	text-align: left;
`;

export const BannerSubtitle = styled.div`
	font-size: 28px;
	color: ${({theme}) => theme.white};
	font-weight: bolder;
	text-align: left;
`;

export const FormContainer = styled.div`
	position: absolute;
	overflow-x: hidden;
	overflow-y: scroll;
	top: 25px;
	right: 25px;
	width: 30%;
	height: calc(100vh - 50px);
	background-color: ${({theme}) => theme.white};
	border-radius: 25px;

	-ms-overflow-style: none;  /* IE and Edge */
	scrollbar-width: none;

	&:-webkit-scrollbar {
	  display: none;
	}
`;

export const SectionSelectorContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const SectionSelector = styled.div`
	flex: 1;
	cursor: pointer;
	text-align: center;
	color: ${({theme, selected}) => selected?theme.blue:"#777"};
	border-bottom: ${({theme, selected}) => selected?`2px solid ${theme.blue}`:"2px solid transparent"};
	font-weight: ${({selected}) => selected?"bolder":"regular"};
	font-size: ${({selected}) => selected?"20px":"16px"};
	padding: ${({selected}) => selected?"15px 0px":"17px 0px"};
	transition: all 0.3s ease;
`;

export const SectionContainer = styled.div`
	padding: 10px;
	margin: 10px;
	text-align: left;
`;

export const SectionTitle = styled.h1`
	color: ${({theme}) => theme.darkGrey};
	font-weight: bolder;
	margin: 5px 0px;	
	line-height: 32px;
`;

export const SectionTitle1 = styled.h1`
	color: ${({theme}) => theme.darkGrey};
	font-weight: bolder;
	margin: 5px 0px 30px;	
	line-height: 32px;
`;

export const TextInputLabel = styled.div`
	font-size: 12px;
	margin: 5px;
	font-weight: bold;
	color: ${({theme}) => theme.darkGrey};
`;

export const TextInput = styled.input.attrs(({type}) => ({type: type || "text"}))`
	width: 80%;	
	border: none;
	outline: none;
	padding: 10px;
	border-radius: 10px;
	background-color: ${({theme}) => theme.white};
	border: 1px solid #ccc;
`;

export const Errors = styled.div`
	width: 80%;
	font-size: 14px;
	color: ${({theme}) => theme.red};
	margin: 5px 10px;
`;

export const Button = styled.div`
	align-self: center;
	display: inline-block;
	background: ${({theme}) => theme.blue};
	padding: 10px;
	margin: 0px 10px 0px 0px;
	border-radius: 5px;
	color: ${({theme}) => theme.white};
	transition: all 0.5s ease;
	cursor: pointer;
	border: 2px solid transparent;

	&:hover {
		color: ${({theme}) => theme.blue};
		background: ${({theme}) => theme.white};
		border: 2px solid ${({theme}) => theme.blue};
	}

	&:active {
		transform: scale(0.8);
	}
`;

export const CircularStepsContainer = styled.div`
	width: 100%;
	position:relative;
	display: flex;
	align-items: center;
	min-height: 30px;
`;

export const CircularStepsContainerPassLine = styled.div`
	position: absolute;
	top: 50%;
	width: 100%;
	height: 1px;
	background-color: ${({theme}) => theme.halfWhite};
`;

export const RoundedStepNumberContainer = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${({theme, highlight}) => highlight?theme.white:theme.lightGrey};
	background-color: ${({theme, highlight}) => highlight?theme.blue:theme.white};
	border: 1px solid ${({theme}) => theme.blue};
	margin-right: 5px;
	z-index: 100;
	cursor: ${({clickable}) => clickable?'pointer':'inherit'};

	&:last-child {
		margin-right: 0;
	}
`;

export const RoundedStepNameContainer = styled.div`
	flex: ${({highlight}) => highlight?1:0};
	overflow: hidden;
	width: ${({highlight}) => highlight?'auto':'0px'};
	transition: all 0.3s ease;
	z-index: 100;
	cursor: pointer;
`;

export const RoundedStepName = styled.span`
	font-size: 16px;
	font-weight: bolder;
	padding-right: 15px;
	padding-left: 5px;
	background-color: ${({theme}) => theme.white};
	color: ${({theme}) => theme.darkGrey};
	line-height: 30px;
	z-index: 100;
`;

export const UseCaseWrapper = styled.div`
	padding: 5px;
`;

export const UseCaseContainer = styled.div`
	border: 1px solid ${({theme}) => theme.blue};
	background-color: ${({theme, selected}) => selected?theme.blue:theme.white};
	margin: 5px;
	padding: 5px;
	border-radius: 5px;
	padding-left: 15px;
	transform: ${({selected}) => selected?"scale(1.05)":"scale(1)"};
	max-width: 80%;
	transition: all 300ms ease;
	cursor: pointer;

	&:hover {
		transform: scale(1.1);
	}

	&:active {
		transform: scale(0.95)
	}
`;

export const UseCaseTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
	color: ${({theme, selected}) => selected?theme.white:theme.blue};
`;

export const UseCaseSubtitle = styled.div`
	font-size: 14px;
	color: ${({theme, selected}) => selected?theme.white:theme.blue};
`;

export const TestPilotContainer = styled.div`
	font-size: 18px;
	color: ${({theme}) => theme.darkGrey};
`;