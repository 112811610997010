import {
	fetchSummariesAsyncActions,
	mutateSummariesAction,
	postSummariesAsyncActions,
	recentVideosAsyncActions,
	createRequestAsyncActions,
	getRequestAsyncActions,
	searchVideosAsyncActions,
	useMlAsyncActions,
	setVideoTitleAction,
	getSummaryRecommendationAsyncAction,
	requestPodcastChaptersAsyncActions,
} from './actions';

const initialState = {
	isLoading: false,
	isRecentVideosLoading: false,
	isRequestsLoading: false,
	isSearchLoading: false,
	isError: false,
	videoTitle: '',
	videoId: '',
	channel: '',
	summaries: [],
	descriptionSummaries: [],
	recentVideos: [],
	searchedVideos: [],
	requests: [],
	isMutated: false,
	usingML: false,
	metadata: {},	
	isRecommendationLoading: false,
	currentRecommendations: [],
}

export default (state = initialState, action) => {
	switch(action.type) {
		case fetchSummariesAsyncActions.actionNames.request: 
			return {...state, summaries: [], videoTitle: '', platform: '', isLoading: true}

		case fetchSummariesAsyncActions.actionNames.success: {
			return {...state, metadata: {...action.payload.metadata}, summaries: action.payload.summaries.summary || [], videoTitle: action.payload.metadata.title, channel: action.payload.summaries.channel, platform: action.payload.summaries.platform, isLoading: false, descriptionSummaries: action.payload.autoChapters}
		}

		case fetchSummariesAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case postSummariesAsyncActions.actionNames.request: 
			return {...state, isLoading: true}

		case postSummariesAsyncActions.actionNames.success: 
			return {...state, isLoading: false, isMutated: false, usingML: false, summaries: action.payload.summary}

		case postSummariesAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case recentVideosAsyncActions.actionNames.request: {
			// console.log("IS RECENT VIDEOS LOADING", state.isRecentVideosLoading)
			return {...state, isRecentVideosLoading: true, recentVideos: action.payload}
		}

		case recentVideosAsyncActions.actionNames.success: 
			return {...state, isRecentVideosLoading: false, recentVideos: action.payload}

		case recentVideosAsyncActions.actionNames.error: 
			return {...state, isRecentVideosLoading: false}

		case createRequestAsyncActions.actionNames.request: 
			return {...state, isLoading: true}

		case createRequestAsyncActions.actionNames.success: 
			return {...state, isLoading: false}

		case createRequestAsyncActions.actionNames.error: 
			return {...state, isLoading: false}

		case getRequestAsyncActions.actionNames.request: 
			return {...state, isRequestsLoading: true}

		case getRequestAsyncActions.actionNames.success: 
			return {...state, isRequestsLoading: false, requests: action.payload}

		case getRequestAsyncActions.actionNames.error: 
			return {...state, isRequestsLoading: false}

		case searchVideosAsyncActions.actionNames.request: 
			return {...state, isSearchLoading: true}

		case searchVideosAsyncActions.actionNames.success: 
			return {...state, isSearchLoading: false, searchedVideos: action.payload}

		case searchVideosAsyncActions.actionNames.error: 
			return {...state, isSearchLoading: false}

		case getSummaryRecommendationAsyncAction.actionNames.request: 
			return {...state, isRecommendationLoading: true, currentRecommendations: []}

		case getSummaryRecommendationAsyncAction.actionNames.success: 
			return {...state, isRecommendationLoading: false, currentRecommendations: action.payload}

		case getSummaryRecommendationAsyncAction.actionNames.error: 
			return {...state, isRecommendationLoading: false}

		case useMlAsyncActions.actionNames.request: 
			return {...state, usingML: true}

		case mutateSummariesAction.actionName: {
			return {...state, isMutated: true, ...action.payload}
		}

		case setVideoTitleAction.actionNames: {
			return {...state, videoTitle: action.payload}
		}

		default:
			return state;
	}
}