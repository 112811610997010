import {asyncActionGenerator, plainActionGenerator} from 'utils';

export const loginAsyncActions = asyncActionGenerator('LOGIN');

export const signupAsyncActions = asyncActionGenerator('SIGNUP');

export const upgradeAsyncActions = asyncActionGenerator('UPGRADE');

export const getProfileAsyncActions = asyncActionGenerator('GET_PROFILE');

export const editProfileAsyncActions = asyncActionGenerator('EDIT_PROFILE');

export const createTestPilotAsyncActions = asyncActionGenerator('CREATE_TESTPILOT');

export const forgetPasswordAsyncActions = asyncActionGenerator('FORGET_PASSWORD');

export const resetPasswordAsyncActions = asyncActionGenerator('RESET_PASSWORD');

export const exportToYoutubeAsyncActions = asyncActionGenerator('EXPORT_TO_YOUTUBE');

export const updateRecommendationSettingAction = plainActionGenerator('UPDATE_RECOMMENDATION_SETTING')

export const logoutAction = plainActionGenerator('LOGOUT');

export const youtubeOAuthAction = asyncActionGenerator('YOUTUBE_AUTH');

export const fetchChannelAction = asyncActionGenerator('FETCH_CHANNEL');

export const fetchVideosAction = asyncActionGenerator('FETCH_VIDEOS');