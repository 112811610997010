import {useState, useEffect} from 'react';
import {
	CardContainer,
	Card,
	CardTitle,
	CardContent,
	CardSubtitle,
	TrailersListWrapper,
	TrailersRow,
	TrailerColumnHeading,
	TrailerCell,
	DataCell,
} from './Analytics.styles';
import {
	VerticalSpace,
	HorizontalSpace,
	Thumbnail
} from 'components';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import Tooltip from 'react-tooltip';
import {analyticsActions} from 'modules/analytics';
import BarLoader from 'react-spinners/BarLoader';

const TrailerAnalytics = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const {analytics, isLoading} = useSelector(state => state.analyticsReducer, shallowEqual)
	const [sortBy, setSortBy] = useState();
	const [sortOrder, setSortOrder] = useState();
	const {totalPlayedSessions, totalViews, totalPlaySeconds, totalConversions} = analytics?.trailerData[0] || [{}]
	
	const getPercents = (nom, denom) => {
		return Math.round(((nom/denom)*100))
	}

	const onSortClick = (field) => {
		if(sortBy === field) {
			setSortOrder(p => p*-1)
		}
		else {
			setSortBy(field)
			setSortOrder(-1)
		}
	}

	useEffect(() => {
		dispatch(analyticsActions.analyticalNumbersAsyncActions.request({video: false, sort: sortBy, order: sortOrder}))
	}, [sortBy, sortOrder])

	if(isLoading) {
		return (
			<>
				<CardTitle>Please wait while we are loading your data</CardTitle>
				<BarLoader width={200} height={4} />
			</>
		)
	}

	return (
		<>
			<CardContainer>
				<Card>
					<CardTitle>Trailers</CardTitle>
					<CardContent>{analytics.trailersCount}</CardContent>
					<CardSubtitle>Lifetime Data</CardSubtitle>
				</Card>
				<Card>
					<CardTitle>Play Rate</CardTitle>
					<CardContent>{getPercents(totalPlayedSessions,totalViews)}%</CardContent>
					<CardSubtitle>Lifetime Data</CardSubtitle>
				</Card>
				<Card>
					<CardTitle>Engagement</CardTitle>
					<CardContent>{getPercents((totalPlaySeconds/totalPlayedSessions),63)}%</CardContent>
					<CardSubtitle>Lifetime Data</CardSubtitle>
				</Card>
				<Card>
					<CardTitle>Conversion Rate</CardTitle>
					<CardContent>{getPercents(totalConversions, totalPlayedSessions)}%</CardContent>
					<CardSubtitle>Lifetime Data</CardSubtitle>
				</Card>
			</CardContainer>

			<VerticalSpace count={3} />

			<TrailersListWrapper>
				<VerticalSpace count={3} />
				<TrailersRow>
					<TrailerCell>
						<TrailerColumnHeading>Trailers</TrailerColumnHeading>
					</TrailerCell>
					<DataCell onClick={() => onSortClick('views')} >
						<TrailerColumnHeading>Views</TrailerColumnHeading>
					</DataCell>
					<DataCell onClick={() => onSortClick('playedSessions')} >
						<TrailerColumnHeading>Play Rate</TrailerColumnHeading>
					</DataCell>
					<DataCell onClick={() => onSortClick('watchSeconds')} >
						<TrailerColumnHeading>Engagement</TrailerColumnHeading>
					</DataCell>
					<DataCell onClick={() => onSortClick('conversions')} >
						<TrailerColumnHeading>Conversion Rate</TrailerColumnHeading>
					</DataCell>
				</TrailersRow>
				{
					analytics.trailers?.map(trailer => {
						return (
							<TrailersRow onClick={() => history.push(`/analytics/trailer/${trailer._id}`)} >
								<TrailerCell>
									<Thumbnail video={{videoId: trailer._id}} fromAnalytics />
									<HorizontalSpace count={2} />
									{trailer.videoName.replace('Trailer ', '').replace('|', '')}
								</TrailerCell>
								<DataCell>
									{trailer.views}
								</DataCell>
								<DataCell>
									{getPercents(trailer.playedSessions, trailer.views)}%
								</DataCell>
								<DataCell>
									{getPercents(trailer.watchSeconds/trailer.views, 63)}%
								</DataCell>
								<DataCell>
									{getPercents(trailer.conversions, trailer.views)}%
								</DataCell>
							</TrailersRow>
						)
					})
				}
				<VerticalSpace count={2} />
			</TrailersListWrapper>
		</>
	)
}

export default TrailerAnalytics;