import {asyncActionGenerator, plainActionGenerator} from 'utils';

export const fetchSummariesAsyncActions = asyncActionGenerator('FETCH_SUMMARIES');

export const postSummariesAsyncActions = asyncActionGenerator('POST_SUMMARIES');

export const recentVideosAsyncActions = asyncActionGenerator('RECENT_VIDEOS');

export const createRequestAsyncActions = asyncActionGenerator('NEW_REQUEST');

export const getRequestAsyncActions = asyncActionGenerator('GET_REQUEST');

export const searchVideosAsyncActions = asyncActionGenerator('SEARCH_VIEOS');

export const useMlAsyncActions = asyncActionGenerator('USE_ML');

export const mutateSummariesAction = plainActionGenerator('MUTATE_SUMMARIES');

export const setVideoTitleAction = plainActionGenerator('SET_VIDEOTITLE');

export const getSummaryRecommendationAsyncAction = asyncActionGenerator('GET_SUMMARY_RECOMMENDATIONS');

export const requestPodcastChaptersAsyncActions = asyncActionGenerator('REQUEST_PODCAST_CHAPTER');

export const requestPodcastTranscriptsAsyncActions = asyncActionGenerator("REQUEST_PODCAST_TRANSCRIPT");