import {useState, useEffect} from 'react';
import {
	RootWrapper,
	RootContainer,
	PageTitle,
	TrailerTitle,
	CardContainer,
	CardRowContainer,
	CardCellContainer,
	NumberValue,
	CardTitle,
	CardDetail,
	CtaClickList,
	CtaClickContainer,
	ChartTabContainer,
	ChartTabSelector,
	ChartTabTitle,
	ChartTab,
} from './SpecificAnalytics.styles';
import {VerticalSpace, HorizontalSpace, Navbar} from 'components';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import moment from 'moment';
import {useHistory, useLocation, useNavigate, useParams} from 'react-router-dom';
import {analyticsActions} from 'modules/analytics';
import {
	ResponsiveContainer,
	AreaChart,
	CartesianGrid,
	XAxis,
	YAxis,
	Tooltip,
	Legend,
	Area,
	linearGradient,
	defs,
} from 'recharts';

// /analytics/specific/trailer/:id

const SpecificAnalytics = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const analytics = useSelector(state => state.analyticsReducer.currentTrailerAnalytics, shallowEqual)
	const {id} = useParams();
	const [currentChartTab, setCurrentChartTab] = useState(1);
	useEffect(() => {
		dispatch(analyticsActions.getSpecificTrailerAnalyticsAsyncActions.request({id}))
	}, [])

	// console.log("CURRENT ANALYTICS", analytics)

	return (
		<RootWrapper>
			<Navbar history={history} />
			<RootContainer>
				<PageTitle>Trailer Analytics</PageTitle>
				<TrailerTitle>
					{analytics?.title || ''}
				</TrailerTitle>
				<VerticalSpace count={3} />
				<CardContainer>
					<CardRowContainer>
						<CardCellContainer>
							<NumberValue>{Math.round(analytics.avgEngagement)}%</NumberValue>
							<HorizontalSpace count={2} />
							<CardTitle>Average Engagement</CardTitle>
						</CardCellContainer>
						<CardCellContainer>
							<CardDetail>
								On average, people play <strong>{Math.round(analytics.watchSeconds/analytics.views)} seconds</strong> out of <strong>{Math.round(analytics.length)} seconds</strong> in your trailer
							</CardDetail>
						</CardCellContainer>
					</CardRowContainer>
					<CardRowContainer>
						<CardCellContainer>
							<NumberValue>{analytics.fullyWatched}</NumberValue>
							<HorizontalSpace count={2} />
							<CardTitle>Fully Watched</CardTitle>
						</CardCellContainer>
						<CardCellContainer>
							<CardDetail>
								This video was created <strong>{moment.duration(moment().diff(moment(analytics.createdOn))*-1).humanize(true)}</strong>.
							</CardDetail>
						</CardCellContainer>
					</CardRowContainer>
					<CardRowContainer>
						<CardCellContainer>
							<NumberValue>{Math.round(analytics.playRate)}%</NumberValue>
							<HorizontalSpace count={2} />
							<CardTitle>Play Rate</CardTitle>
						</CardCellContainer>
						<CardCellContainer>
							<CardDetail>
								Out of <strong>{analytics.views}</strong> people who load this video, <strong>{analytics.playedSessions}</strong> people played it
							</CardDetail>
						</CardCellContainer>
					</CardRowContainer>
					<CardRowContainer>
						<CardCellContainer>
							<NumberValue>{analytics.conversions}</NumberValue>
							<HorizontalSpace count={2} />
							<CardTitle>CTA Clicks</CardTitle>
						</CardCellContainer>
						<CardCellContainer>
							<CtaClickList>
								<CtaClickContainer><strong>{analytics.conversions - analytics.endScreenCtaClicks}</strong> Play Screen CTA Clicks</CtaClickContainer>
								<CtaClickContainer><strong>{analytics.endScreenCtaClicks}</strong> End Screen CTA Clicks</CtaClickContainer>
							</CtaClickList>
						</CardCellContainer>
					</CardRowContainer>
				</CardContainer>
				<VerticalSpace count={3} />
				<ChartTabContainer>
					<ChartTabSelector>
						<ChartTabTitle selected={ currentChartTab === 1 } onClick={() => setCurrentChartTab(1)} >Action Time</ChartTabTitle>
						<ChartTabTitle selected={ currentChartTab === 2 } onClick={() => setCurrentChartTab(2)} >Dropoff Time</ChartTabTitle>
					</ChartTabSelector>
					{
						currentChartTab === 1
						?
						<ChartTab>
							<VerticalSpace count={2} />
							<ResponsiveContainer width="80%" height={350}>
								<AreaChart data={analytics.actionButtonTimeframeData}
								  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
								  	<defs>
						  			  	<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
											<stop offset="5%" stopColor="#8884d8" stopOpacity={1}/>
											<stop offset="95%" stopColor="#8884d8" stopOpacity={0.5}/>
						  		  	    </linearGradient>
								  	</defs>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="min" />
									<YAxis />
									<Tooltip />
									<Legend />
									<Area type="monotone" dataKey="count" fillOpacity={1} fill="url(#colorUv)" />
								</AreaChart>
							</ResponsiveContainer>
						</ChartTab>
						:
						<ChartTab>
							<VerticalSpace count={2} />
							<ResponsiveContainer width="80%" height={350}>
								<AreaChart data={analytics.dropOffTimeframeData}
								  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
					  			  	<defs>
					  	  			  	<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
					  						<stop offset="5%" stopColor="#8884d8" stopOpacity={1}/>
					  						<stop offset="95%" stopColor="#8884d8" stopOpacity={0.5}/>
					  	  		  	    </linearGradient>
					  			  	</defs>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis dataKey="min" />
									<YAxis />
									<Tooltip />
									<Legend />
									<Area type="monotone" dataKey="count" fillOpacity={1} fill="url(#colorUv)" />
								</AreaChart>
							</ResponsiveContainer>
						</ChartTab>
					}
				</ChartTabContainer>
				<VerticalSpace count={3} />
			</RootContainer>
		</RootWrapper>
		)
}

export default SpecificAnalytics;