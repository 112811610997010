import { useState, useEffect } from "react";
import {
  RootWrapper,
  RootContainer,
  PageHeader,
  SearchContainer,
  SearchInput,
  VideoListContainerWrapper,
  VideoListContainerHeader,
  VideoListOverflow,
  VideoListContainer,
  VideoFullListContainer,
  YTLoginContainer,
  YTLoginBtn,
  VideoTile,
  VideoTileTitle,
  TileSpaceFiller,
  VideoTileBottomDiv,
  VideoTileInfo,
  TileActionContainer,
  SearchHighlighter,
  SearchWrapper,
  SearchResultWrapper,
  SearchResultContainer,
  SearchResultThumb,
  SearchResultContent,
  VideoTitle,
  VideoSubtitle,
  ActionButtonWrapper,
  ActionButtonContainer,
  ActionButtonDescription,
  DescriptionChaptersSpan,
  EditButton,
  ShowAllButton,
} from "./Dashboard.styles";
import { Navbar, VerticalSpace } from "components";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import RingLoader from "react-spinners/RingLoader";
import { authActions } from "modules/auth";
import { summaryActions } from "modules/summaries";
import UsersSvg from "./Users.png";
import AI from "./Artificial_Intelligence.png";
import DatabaseSvg from "./Database_Import.png";
import addChaptersIcon from "./addChapter.png";
import moment from "moment";
import GoogleSigninButton from "assets/img/google_auth_button.png";
import { Input } from "antd";
import Title from "antd/es/typography/Title";
import { getThumbnailUrl } from "./helper";

const Dashboard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const summaryState = useSelector((state) => state.summaryReducer);
  const authState = useSelector((state) => state.authReducer);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [currentHoveredActionButton, setCurrentHoveredActionButton] =
    useState("");
  const [urlInvalid, setUrlInvalid] = useState(false);
  const [showAllPreviousVideos, setShowAllPreviousVideos] = useState(false);
  const [showAllYoutubeVideos, setShowAllYoutubeVideos] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoId, setVideoId] = useState("");
  const [searchedPlatform, setSearchedPlatform] = useState("youtube");

  // console.log("SUMMARY STATE", summaryState)

  const actionButtonDescriptionProvider = () => {
    if (summaryState.summaries && summaryState.summaries.length)
      return (
        <ActionButtonDescription>
          This video has{" "}
          <DescriptionChaptersSpan length={1}>
            {summaryState.summaries.length} chapters
          </DescriptionChaptersSpan>
        </ActionButtonDescription>
      );

    switch (currentHoveredActionButton) {
      case "ai":
        return (
          <ActionButtonDescription>
            Generate chapters with{" "}
            <span style={{ fontWeight: 700 }}>ChapterGPT</span> in under 30% of
            your video's duration. Download{" "}
            <span style={{ fontWeight: 700 }}>transcripts (SRT)</span> via the
            "Transcript" button (second from last, top right).
          </ActionButtonDescription>
        );

      case "import":
        // return `Import and embed the video with chapters in your page or blog in our highly customizable player. ${summaryState.descriptionSummaries.length?`${summaryState.descriptionSummaries.length} Chapters are found.`:"**We can't find chapters from this video description. You can use our AI Tool to generate chapters quickly and efficiently.**"}`
        return (
          <ActionButtonDescription>
            Import chapters from video description, if available.
            <br />
            <br />
            <DescriptionChaptersSpan
              length={summaryState.descriptionSummaries.length}
            >
              {summaryState.descriptionSummaries.length
                ? `${summaryState.descriptionSummaries.length} chapters found.`
                : "Chapters are not available in this video's description. You can try our AI tool to create chapters automatically"}
            </DescriptionChaptersSpan>
          </ActionButtonDescription>
        );

      case "request":
        return (
          <ActionButtonDescription>
            Need accurate chapters done by human professionals? Request here.
          </ActionButtonDescription>
        );

      case "diy":
        return (
          <ActionButtonDescription>
            Add chapters using our simple and intuitive tool. Use{" "}
            <span style={{ fontWeight: 700 }}>keyboard shortcuts</span> to add
            and edit chapters 10x faster.
          </ActionButtonDescription>
        );

      default:
        return (
          <ActionButtonDescription>
            Please choose one of the options above.
          </ActionButtonDescription>
        );
    }
  };

  const urlParser = (text) => {
    if (text.length) setIsSearchFocused(true);
    else setIsSearchFocused(false);
    setVideoUrl(text);
    setUrlInvalid(false);

    const regex =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    var res = regex.test(text);
    if (!text) {
      setVideoId("");
      setUrlInvalid(false);
      return;
    }

    let platformRegex = text.match(
      /(https:\/\/)?([a-z]*\.)?(youtube|youtu|wistia|vimeo|wi)(\.[a-z]*)/
    );
    const allowedPlatforms = ["youtube", "youtu", "vimeo"];

    if (
      platformRegex === null ||
      !platformRegex.length ||
      !allowedPlatforms.includes(platformRegex[3])
    ) {
      setUrlInvalid(true);
      return;
    }

    const youtubeRegex = text.match(
      /(\/|%3D|v=|vi=)([0-9A-z-_]{11})([%#?&]|$)/
    );
    const vimeoRegex = text.match(/https:\/\/player\.vimeo\.com\/video\/(\d+)/);

    let platform = "";
    let videoId = "";

    if (youtubeRegex && youtubeRegex.length) {
      platform = "youtube";
      videoId = youtubeRegex[2];
    } else if (vimeoRegex && vimeoRegex.length) {
      platform = "vimeo";
      videoId = vimeoRegex[1];
    } else {
      setUrlInvalid(true);
      return;
    }

    setVideoId(videoId);
    setSearchedPlatform(platform);
    dispatch(
      summaryActions.fetchSummariesAsyncActions.request({
        videoId: videoId,
        platform: platform,
      })
    );
  };

  const onRequestSummary = () => {
    const body = {
      accountId: authState.accountId,
      videoId,
      videoTitle: summaryState.metadata.title,
      platform: "youtube",
      createdOn: moment(),
    };
    const callback = (status) => {
      const msg = status
        ? `Request received! We will notify you (support@chapterme.co) once chapters are available. Check Spam too 😉`
        : "Chapters have already been requested for this video. We will notify (support@chapterme.co) you once the chapters are available. Check spam too 😉";

      toast.success(msg, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    };
    dispatch(
      summaryActions.createRequestAsyncActions.request({ body, callback })
    );
    setVideoUrl("");
  };

  useEffect(() => {
    dispatch(summaryActions.recentVideosAsyncActions.request());
    dispatch(authActions.getProfileAsyncActions.request(authState.accountId));
    setVideoId("");
    setVideoUrl("");

    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
    return () => {
      window.removeEventListener("pageshow", null);
    };
  }, []);

  useEffect(() => {
    // console.log("YT AUTH STATE", authState.ytAuth)
    if (authState.ytAuth || true) {
      dispatch(authActions.fetchChannelAction.request(authState.accountId));
    }
  }, [authState.ytAuth]);

  return (
    <RootWrapper>
      <Navbar history={history} />
      <RootContainer>
        <PageHeader open={isSearchFocused || videoId}>Dashboard</PageHeader>

        <SearchWrapper open={isSearchFocused || videoId}>
          <SearchContainer>
            {!isSearchFocused && !videoId ? <SearchHighlighter /> : null}
            <div>Start Chaptering 👇</div>
            <SearchInput
              onChange={(evt) => urlParser(evt.target.value)}
              value={videoUrl}
              error={urlInvalid}
            />
          </SearchContainer>
          <SearchResultWrapper>
            {summaryState.isLoading ? (
              <ActionButtonDescription>Fetching Video</ActionButtonDescription>
            ) : summaryState.videoTitle && videoId && !urlInvalid ? (
              <SearchResultContainer>
                <SearchResultThumb src={summaryState.metadata.thumbnail} />
                <SearchResultContent>
                  <VideoTitle>{summaryState.videoTitle}</VideoTitle>
                  <br />
                  <VideoSubtitle>
                    {moment
                      .duration(summaryState.metadata.duration * 1000)
                      .format("hh:mm:ss", { trim: false })}{" "}
                    |{" "}
                    {summaryState.summaries.length
                      ? `${summaryState.summaries.length} Chapters`
                      : "Chapters not added yet"}
                  </VideoSubtitle>
                  {!(
                    summaryState.summaries && summaryState.summaries.length
                  ) ? (
                    <ActionButtonWrapper>
                      <ActionButtonContainer
                        onClick={() =>
                          window.open(
                            `/editor/${videoId}?platform=${searchedPlatform}&mode=ai`,
                            "_self"
                          )
                        }
                        onMouseEnter={() => setCurrentHoveredActionButton("ai")}
                        onMouseLeave={() => setCurrentHoveredActionButton("")}
                        focused={currentHoveredActionButton === "ai"}
                      >
                        <img src={AI} alt="use AI" />
                      </ActionButtonContainer>
                      <ActionButtonContainer
                        onClick={() =>
                          window.open(
                            `/editor/${videoId}?platform=${searchedPlatform}&mode=diy`,
                            "_self"
                          )
                        }
                        onMouseEnter={() =>
                          setCurrentHoveredActionButton("diy")
                        }
                        onMouseLeave={() => setCurrentHoveredActionButton("")}
                        focused={currentHoveredActionButton === "diy"}
                      >
                        <img src={addChaptersIcon} alt="" />
                      </ActionButtonContainer>
                      <ActionButtonContainer
                        disabled={!summaryState.descriptionSummaries?.length}
                        onClick={() =>
                          summaryState.descriptionSummaries.length
                            ? window.open(
                                `/editor/${videoId}?platform=${searchedPlatform}&mode=import`,
                                "_self"
                              )
                            : null
                        }
                        onMouseEnter={() =>
                          setCurrentHoveredActionButton("import")
                        }
                        onMouseLeave={() => setCurrentHoveredActionButton("")}
                        focused={currentHoveredActionButton === "import"}
                      >
                        <img src={DatabaseSvg} alt="" />
                      </ActionButtonContainer>
                      <ActionButtonContainer
                        onMouseEnter={() =>
                          setCurrentHoveredActionButton("request")
                        }
                        onClick={onRequestSummary}
                        onMouseLeave={() => setCurrentHoveredActionButton("")}
                        focused={currentHoveredActionButton === "request"}
                      >
                        <img src={UsersSvg} alt="Add Chapters" />
                      </ActionButtonContainer>
                    </ActionButtonWrapper>
                  ) : (
                    <ActionButtonWrapper>
                      <EditButton
                        onClick={() =>
                          window.open(
                            `/editor/${videoId}?platform=${searchedPlatform}`,
                            "_self"
                          )
                        }
                      >
                        Edit Chapters
                      </EditButton>
                    </ActionButtonWrapper>
                  )}
                  {actionButtonDescriptionProvider()}
                </SearchResultContent>
              </SearchResultContainer>
            ) : urlInvalid ? (
              <ActionButtonDescription>
                My responses are limited. Please give use the right video!
              </ActionButtonDescription>
            ) : null}
          </SearchResultWrapper>
        </SearchWrapper>

        {authState.ytVideos?.length ? (
          <VideoListContainerWrapper>
            <VideoListContainerHeader>
              Your Youtube Videos
              <ShowAllButton onClick={() => setShowAllYoutubeVideos((p) => !p)}>
                {showAllYoutubeVideos ? "Hide" : "Show All"}
              </ShowAllButton>
            </VideoListContainerHeader>
            {showAllYoutubeVideos ? (
              <VideoFullListContainer>
                {authState.ytVideos?.map((video, i) => (
                  <VideoTile
                    key={i}
                    clickable
                    onClick={() =>
                      urlParser(`https://www.youtube.com/watch?v=${video.id}`)
                    }
                    thumbnail={video.thumbnail}
                  >
                    <VideoTileTitle>{video.title}</VideoTileTitle>
                    <TileSpaceFiller />
                  </VideoTile>
                ))}
              </VideoFullListContainer>
            ) : (
              <VideoListContainer>
                {authState.ytVideos?.slice(0, 5).map((video, i) => (
                  <VideoTile
                    key={i}
                    clickable
                    onClick={() =>
                      urlParser(`https://www.youtube.com/watch?v=${video.id}`)
                    }
                    thumbnail={video.thumbnail}
                  >
                    <VideoTileTitle>{video.title}</VideoTileTitle>
                    <TileSpaceFiller />
                  </VideoTile>
                ))}
              </VideoListContainer>
            )}
          </VideoListContainerWrapper>
        ) : (
          <VideoListContainerWrapper>
            <VideoListContainerHeader>
              Your Youtube Videos
            </VideoListContainerHeader>
            <YTLoginContainer>
              Add your google account to view your recent youtube Videos
              <YTLoginBtn
                onClick={() => {
                  dispatch(
                    authActions.youtubeOAuthAction.request(authState.accountId)
                  );
                }}
              >
                <img src={GoogleSigninButton} />
              </YTLoginBtn>
            </YTLoginContainer>

            {/*<VideoListContainer>
								<YTLoginBtn>Sign in </YTLoginBtn>with your google account to view your recent youtube accounts
							</VideoListContainer>*/}
          </VideoListContainerWrapper>
        )}

        <VideoListContainerWrapper>
          <VideoListContainerHeader>
            Previously Chaptered Videos
            <ShowAllButton onClick={() => setShowAllPreviousVideos((p) => !p)}>
              {showAllPreviousVideos ? "Hide" : "Show All"}
            </ShowAllButton>
          </VideoListContainerHeader>
          {showAllPreviousVideos ? (
            <VideoFullListContainer>
              {authState.loginSuccess ? (
                summaryState.isRecentVideosLoading ? (
                  <RingLoader size={60} color="#23272a" />
                ) : summaryState.recentVideos.length ? (
                  summaryState.recentVideos.map((video, ind) => (
                    <VideoTile
                      clickable
                      onClick={() =>
                        window.open(
                          `/editor/${video.videoId}?platform=${video.platform}`,
                          "_self"
                        )
                      }
                      url={getThumbnailUrl(video)}
                      key={"previousAdded" + ind}
                    >
                      <VideoTileTitle>{video.videoTitle}</VideoTileTitle>
                      <TileSpaceFiller />
                      <VideoTileBottomDiv>
                        <VideoTileInfo>
                          <span>
                            {moment
                              .duration(
                                video.summary[video.summary.length - 1]?.start *
                                  1000
                              )
                              .format(
                                `${
                                  video.summary[video.summary.length - 1]
                                    ?.start > 60
                                    ? "hh:mm:ss"
                                    : "mm:ss"
                                }`,
                                {
                                  trim:
                                    video.summary[video.summary.length - 1]
                                      ?.start > 60
                                      ? true
                                      : false,
                                }
                              )}
                          </span>
                          <span>{video.summary.length} Chapters</span>
                        </VideoTileInfo>
                        <TileActionContainer style={{ display: "none" }}>
                          <img src={AI} alt="use AI" onClick={() => null} />
                          <img src={DatabaseSvg} alt="" onClick={() => null} />
                          <img src={UsersSvg} alt="" onClick={() => null} />
                          <img
                            src={addChaptersIcon}
                            alt="Add Chapters"
                            onClick={() => null}
                          />
                        </TileActionContainer>
                      </VideoTileBottomDiv>
                    </VideoTile>
                  ))
                ) : (
                  <div>No videos found</div>
                )
              ) : (
                <div>Login or signup to see your recent videos</div>
              )}
            </VideoFullListContainer>
          ) : (
            <VideoListContainer>
              {authState.loginSuccess ? (
                summaryState.isRecentVideosLoading ? (
                  <RingLoader size={60} color="#23272a" />
                ) : summaryState.recentVideos?.length ? (
                  summaryState.recentVideos.slice(0, 5).map((video, ind) => (
                    <VideoTile
                      clickable
                      onClick={() =>
                        window.open(
                          `/editor/${video.videoId}?platform=${video.platform}`,
                          "_self"
                        )
                      }
                      // src={video.videoId}
                      thumbnail={getThumbnailUrl(video)}
                      key={"previousAdded" + ind}
                    >
                      <VideoTileTitle>{video.videoTitle}</VideoTileTitle>
                      <TileSpaceFiller />
                      <VideoTileBottomDiv>
                        <VideoTileInfo>
                          <span>
                            {moment
                              .duration(
                                (video.videoDuration
                                  ? video.videoDuration
                                  : video.summary[video.summary.length - 1]
                                      ?.start) * 1000
                              )
                              .format(
                                `${
                                  (video.videoDuration
                                    ? video.videoDuration
                                    : video.summary[video.summary.length - 1]
                                        ?.start) > 60
                                    ? "hh:mm:ss"
                                    : "mm:ss"
                                }`,
                                {
                                  trim:
                                    (video.videoDuration
                                      ? video.videoDuration
                                      : video.summary[video.summary.length - 1]
                                          ?.start) > 60
                                      ? true
                                      : false,
                                }
                              )}
                          </span>
                          <span>{video.summary.length} Chapters</span>
                        </VideoTileInfo>
                        <TileActionContainer style={{ display: "none" }}>
                          <img src={AI} alt="use AI" onClick={() => null} />
                          <img src={DatabaseSvg} alt="" onClick={() => null} />
                          <img src={UsersSvg} alt="" onClick={() => null} />
                          <img
                            src={addChaptersIcon}
                            alt="Add Chapters"
                            onClick={() => null}
                          />
                        </TileActionContainer>
                      </VideoTileBottomDiv>
                    </VideoTile>
                  ))
                ) : (
                  <div>No videos found</div>
                )
              ) : (
                <div>Login or signup to see your recent videos</div>
              )}
            </VideoListContainer>
          )}
        </VideoListContainerWrapper>
        <VerticalSpace count={5} />
        <div
          style={{
            position: "fixed",
            bottom: "0",
            backgroundColor: "white",
            width: "100%",
            left: 0,
          }}
        >
          <Title level={5} style={{ marginTop: "10px" }}>
            Made with{" "}
            <a href="https://chapterme.co/?ref=admin_footer" target="_blank">
              ChapterMe.co
            </a>{" "}
            ❤️ | Join our{" "}
            <a href="https://bit.ly/chapterme-community" target="_blank">
              community 🎉
            </a>
          </Title>
        </div>
      </RootContainer>
    </RootWrapper>
  );
};

export default Dashboard;
