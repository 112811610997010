import styled from 'styled-components';


export const IframePlayer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	transition: width 300ms;
	height: 100%;
	// height: calc(100vh - 56px);

	@media only screen and (orientation:portrait) {
		position: relative;
		height: ${window.innerHeight <= (window.innerWidth * 9) / 16 ? window.innerHeight : (window.innerWidth * 9) / 16}px;
	}
`;

	// position: absolute;
export const VimeoIframe = styled.iframe.attrs(props => ({
	title: 'vimeo-player',
	id: 'vimeo-player',
	src: `${props.url}`,
}))`
	top: 0;
	left: 0;
	width: 100%;

	@media only screen and (orientation:portrait) {
		position: relative;
		height: ${window.innerHeight <= (window.innerWidth * 9) / 16 ? window.innerHeight : (window.innerWidth * 9) / 16}px;
	}


	@media only screen and (orientation:landscape) {
		height: 100vh;
	}
`;