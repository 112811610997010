import {
	call, 
	put, 
	select, 
	takeLeading
} from 'redux-saga/effects';
import {
	loginAsyncActions,
	signupAsyncActions, 
	createTestPilotAsyncActions, 
	upgradeAsyncActions, 
	getProfileAsyncActions, 
	forgetPasswordAsyncActions, 
	resetPasswordAsyncActions, 
	youtubeOAuthAction, 
	fetchChannelAction, 
	fetchVideosAction, 
	editProfileAsyncActions,
	exportToYoutubeAsyncActions,
} from './actions';
import {
	login,
	signup,
	createTestPilot, 
	forgetPassword, 
	resetPassword, 
	youtubeOAuth, 
	fetchChannel, 
	fetchVideos, 
	editProfile, 
	upgrade, 
	getProfile, 
	exportToYoutube
} from './api';
import {toast} from 'react-toastify';

function* loginSaga(action) {
	try {
		const response = yield call(login, action.payload);
		// console.log(response);
		if (response === 'INVALID_USER')
		{
			yield put(loginAsyncActions.error())
			toast.error('Invalid user name or password', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		else
			yield put(loginAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(loginAsyncActions.error())
		toast.error(err.response.data.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* forgetPasswordSaga(action) {
	try {
		const response = yield call(forgetPassword, action.payload);
		yield put(forgetPasswordAsyncActions.success(response.data.result))
		toast.success('Password reset request sent from support@chapterme.co', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	catch(err) {
		yield put(loginAsyncActions.error())
		toast.error('Something went wrong', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* resetPasswordSaga(action) {
	try {
		// console.log("RESET CALL", action)
		const response = yield call(resetPassword, action.payload);
		yield put(forgetPasswordAsyncActions.success(response.data.result))
		toast.success('Password reset successful', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	catch(err) {
		yield put(loginAsyncActions.error())
		toast.error('Something went wrong during password reset', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* signupSaga(action) {
	try {
		const response = yield call(signup, action.payload);
		yield put(signupAsyncActions.success(response.data.result))
	}
	catch(err) {
		yield put(signupAsyncActions.error())
		toast.error(err.response.data.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* getProfileSaga(action) {
	try {
		const response = yield call(getProfile, action.payload);
		yield put(getProfileAsyncActions.success({...response.data.result.admin, channelName: response.data.result.channel?.channelName, displayName: response.data.result.channel?.displayName}))
	}
	catch(err) {
		console.log("ERROR", err)
		yield put(getProfileAsyncActions.error())
		toast.error(err.response.data.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* upgradeSaga(action) {
	try {
		const accountId = yield select(state => state.authReducer.accountId)
		const response = yield call(upgrade, accountId, action.payload.body);
		yield put(upgradeAsyncActions.success(response.data.result))
		action.payload.callback()
		toast.success("You have upgraded to an admin account successfully. Try saving the chapters now!", {
			position: "top-right",
			autoClose: 8000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	catch(err) {
		yield put(signupAsyncActions.error())
		toast.error(err.response.data.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* createTestpilotSaga(action) {
	try {
		const response = yield call(createTestPilot, action.payload.data);
		yield put(createTestPilotAsyncActions.success(response.data.result))
		toast.success('Test drive account created successfully! You will get an email from support@chapterme.co', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		action.payload.callback()
	}
	catch(err) {
		console.log("ERROR", err)
		yield put(createTestPilotAsyncActions.error())
		toast.error(err.response.data.message, {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
}

function* youtubeOAuthSaga(action) {
	try {
		const response = yield call(youtubeOAuth, action.payload);
		// console.log(response.data.result);
		window.location = response.data.result;
		yield put(youtubeOAuthAction.success())
	}
	catch(err) {
		console.log(err)
		yield put(youtubeOAuthAction.error());
	}
}

function* fetchChannelSaga(action) {
	try {
		const response = yield call(fetchChannel, action.payload);
		// console.log("Channel Result", response.data.result)
		yield put(fetchVideosAction.success(response.data.result))
	}
	catch(err) {
		// console.log(err)
		yield put(fetchChannelAction.error())
	}
}

function* fetchVideosSaga(action) {
	try {
		const response = yield call(fetchVideos, action.payload);
		// console.log(response.data.result)
		yield put(fetchVideosAction.success(response.data.result))
	}
	catch(err) {
		console.log(err)
		yield put(fetchVideosAction.error())
	}
}

function* editProfileSaga(action) {
	try{
		const response = yield call(editProfile, {accountId: action.payload.accountId, body: action.payload.body})
		yield put(editProfileAsyncActions.success(response.data.result))
		action.payload.callback()
	}
	catch(err) {
		yield put(editProfileAsyncActions.error())
	}
}

function* exportToYoutubeSaga(action) {
	try{
		const accountId = yield select(state => state.authReducer.accountId)
		const reqBody = {...action.payload, accountId}
		const response = yield call(exportToYoutube, {data: reqBody})
		yield put(exportToYoutubeAsyncActions.success(response.data.result))
		toast.success('Chapters exported successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}
	catch(err) {
		toast.error('Something went wrong', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		console.log("ERROR", err)
		yield put(exportToYoutubeAsyncActions.error())
	}
}

function* mainSaga() {
	yield takeLeading(loginAsyncActions.actionNames.request, loginSaga)
	yield takeLeading(signupAsyncActions.actionNames.request, signupSaga)
	yield takeLeading(upgradeAsyncActions.actionNames.request, upgradeSaga)
	yield takeLeading(createTestPilotAsyncActions.actionNames.request, createTestpilotSaga)
	yield takeLeading(getProfileAsyncActions.actionNames.request, getProfileSaga)
	yield takeLeading(forgetPasswordAsyncActions.actionNames.request, forgetPasswordSaga)
	yield takeLeading(resetPasswordAsyncActions.actionNames.request, resetPasswordSaga)
	yield takeLeading(editProfileAsyncActions.actionNames.request, editProfileSaga)
	yield takeLeading(youtubeOAuthAction.actionNames.request, youtubeOAuthSaga)
	yield takeLeading(fetchChannelAction.actionNames.request, fetchChannelSaga)
	yield takeLeading(fetchVideosAction.actionNames.request, fetchVideosSaga)
	yield takeLeading(exportToYoutubeAsyncActions.actionNames.request, exportToYoutubeSaga)
}

export default mainSaga;
