import {
	loginAsyncActions,
	signupAsyncActions,
	createTestPilotAsyncActions,
	getProfileAsyncActions,
	forgetPasswordAsyncActions,
	resetPasswordAsyncActions,
	logoutAction,
	fetchChannelAction,
	fetchVideosAction,
	editProfileAsyncActions,
	upgradeAsyncActions,
	updateRecommendationSettingAction,
} from './actions';

const initialState = {
	isLoading: false,
	isError: false,
	loginSuccess: false,
	name: '',
	email: '',
	accountId: '',
	paid: false,
	resetSuccess: false,
	channelName: "",
	displayName: "",
	role: "",
	useCase: {video: false, trailer: false},
	enableRecommendations: true,
	ytAuth: true,
}

export default (state = initialState, action) => {
	switch(action.type) {
		case loginAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case loginAsyncActions.actionNames.success: {
			return {...state, loginSuccess: true, ...action.payload,  isLoading: false, resetSuccess: false}
		}

		case loginAsyncActions.actionNames.error: {
			return {...state, loginSuccess: false, isLoading: false}
		}

		case createTestPilotAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case createTestPilotAsyncActions.actionNames.success: {
			return {...state, isLoading: false, resetSuccess: false}
		}

		case createTestPilotAsyncActions.actionNames.error: {
			return {...state, loginSuccess: false, isLoading: false}
		}

		case getProfileAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case getProfileAsyncActions.actionNames.success: {
			return {...state, isLoading: false, resetSuccess: false, ...action.payload}
		}

		case getProfileAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case signupAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case signupAsyncActions.actionNames.success: {
			return {...state, loginSuccess: true, ...action.payload, isLoading: false, resetSuccess: false}
		}

		case signupAsyncActions.actionNames.error: {
			return {...state, loginSuccess: false, isLoading: false}
		}

		case upgradeAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case upgradeAsyncActions.actionNames.success: {
			return {...state, loginSuccess: true, ...action.payload, isLoading: false, resetSuccess: false}
		}

		case upgradeAsyncActions.actionNames.error: {
			return {...state, loginSuccess: false, isLoading: false}
		}

		case forgetPasswordAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}
		
		case forgetPasswordAsyncActions.actionNames.success: {
			return {...state, isLoading: false}
		}
		
		case forgetPasswordAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case resetPasswordAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}
		
		case resetPasswordAsyncActions.actionNames.success: {
			return {...state, isLoading: false, resetSuccess: true}
		}
		
		case resetPasswordAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		case logoutAction.actionName: {
			return {...initialState}
		}

		case updateRecommendationSettingAction.actionName: {
			return {...state, enableRecommendations: !state.enableRecommendations}
		}

		case fetchChannelAction.actionNames.request: {
			return {...state}
		}

		case fetchChannelAction.actionNames.success: {
			// console.log(action.payload);
			return {...state, channelList: action.payload}
		}

		case fetchChannelAction.actionNames.error: {
			return {...state}
		}

		case fetchVideosAction.actionNames.request: {
			return {...state}
		}

		case fetchVideosAction.actionNames.success: {
			// console.log("YT Video Response", action.payload);
			return {...state, ytVideos: action.payload}
		}

		case fetchVideosAction.actionNames.error: {
			return {...state, ytVideos: []}
		}

		case editProfileAsyncActions.actionNames.request: {
			return {...state, isLoading: true}
		}

		case editProfileAsyncActions.actionNames.success: {
			// console.log("ACTION>PAYLOAD", action.payload)
			return {...state, isLoading: false, ...action.payload}
		}

		case editProfileAsyncActions.actionNames.error: {
			return {...state, isLoading: false}
		}

		default: {
			return state;
		}
	}
}