export default [
	{
		title: 'Toggle play/pause',
		key: 'Space',type: 'large',
	},
	{
		title: 'Add chapter at current time',
		key: 'b',type: 'square',
	},
	{
		title: 'Save chapter',
		key: 'Enter',type: 'medium',
	},
	{
		title: 'Cancel chapter',
		key: 'Esc',type: 'medium',
	},
	{
		title: 'Rewind 5 secs',
		key: '&#8592;',type: 'square',
	},
	{
		title: 'Fast forward 5 secs',
		key: '&#8594;',type: 'square',
	},
	{
		title: 'Increase volume',
		key: '&#8593;',type: 'square',
	},
	{
		title: 'Decrease volume',
		key: '&#8595;',type: 'square',
	},
]