import { postYoutubeComment } from "modules/auth/api";
import { fetchSummaries } from "modules/summaries/api";
import moment from "moment";
import { AiFillDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Btn, ButtonContainer, ButtonWrap, EmptyContent, RootContainer, Section, SubHeading, TextContainer, TextContent, Title } from "./styles";


export function Button({ children, onClick, disabled }) {
    return (
        <Btn disabled={disabled} onClick={onClick}>{children}</Btn>
    );
}


export function Card({ title, middleText, url, btn1Text, btn1Action, deleteButton, btn2Text, btn2Action, btn2Disabled, buttonTitle }) {

    function emptyAction() {}
    
    return (
        <RootContainer>
            <TextContainer>
                <TextContent><Title>{title}</Title></TextContent>
                <TextContent>{middleText}</TextContent>
                <TextContent>{url}</TextContent>
            </TextContainer>
            <ButtonContainer>
                {
                    btn1Text &&
                    <ButtonWrap><Button onClick={btn1Action || emptyAction} >{btn1Text}</Button></ButtonWrap>
                }
                {deleteButton && <ButtonWrap onClick={deleteButton} ><AiFillDelete size={32} color={'red'} title={buttonTitle || "Remove from Que"} /></ButtonWrap>}
                {
                    btn2Text &&
                        <ButtonWrap><Button disabled={btn2Disabled} onClick={btn2Action || emptyAction}>{btn2Text}</Button></ButtonWrap>
                }
            </ButtonContainer>
        </RootContainer>
    );
}


export function InQueue({ data, removeFromQueue }) {
    return (
        <Section>
            <SubHeading color="blue" >In Queue</SubHeading>
            {
                data.length
                    ?
                    data.map((video, ind) =>
                        <Card key={ind} title={video.title} url={video.url} deleteButton={() => removeFromQueue(video.videoId)} />
                    )
                    :
                    <EmptyContent>Queue is Empty</EmptyContent>
            }
        </Section>
    );
}

export function Completed({ data }) {

	const authState = useSelector(state => state.authReducer);
    const showPostCommentButton = authState.ytVideos ? true : false ;

    async function postComments(videoId) {
        const res = await fetchSummaries({
            videoId, platform: 'youtube'
        })
        if (!res.data.isSuccess || !res.data.result.summary?.summary?.length) 
            return;
        const summaries = res.data.result.summary.summary;
        let chapters = '"Chapters (Powered by ChapterMe) - \n"';
        summaries.forEach(summary => {
			chapters = chapters + `${moment.duration(summary.start*1000).format(`${summary.start > 60?'hh:mm:ss':'mm:ss'}`, {trim: summary.start > 60?true:false})} - ${summary.title || summary.summary}
`; 
		});
        // await postYoutubeComment({user: authState.accountId, videoId, comment: chapters})
    }

    return (
        <Section>
            <SubHeading color="green" >Completed</SubHeading>
            {
                data.length
                    ?
                    data.map((video, ind) =>
                        <Card
                            key={ind}
                            title={video.title}
                            url={video.url}
                            btn1Text="Review"
                            btn1Action={() => window.open(`https://admin.chapterme.co/editor/${video.videoId}?platform=youtube&mode=ai`)} 
                            btn2Text="Add to Comment"
                            btn2Action={() => postComments(video.videoId)}
                            btn2Disabled={!showPostCommentButton}
                             />
                    )
                    :
                    <EmptyContent>Nothing has completed</EmptyContent>
            }
        </Section>
    );
}

export function Saved({ data }) {
    return (
        <Section>
            <SubHeading color="darkgreen" >Saved to youtube</SubHeading>
            {
                data.length
                    ?
                    data.map((video, ind) =>
                        <Card key={ind} title={video.title} url={video.url} />
                    )
                    :
                    <EmptyContent>No comment added yet</EmptyContent>
            }
        </Section>
    );
}

export function Error({ data, deleteComment }) {
    return (
        <Section>
            <SubHeading color="red" >Error</SubHeading>
            {
                data.length
                    ?
                    data.map((video, ind) =>
                        <Card key={ind} title={video.title} url={video.url} middleText={video.message} deleteButton={() => deleteComment(video.videoId)} buttonTitle="Delete" />
                    )
                    :
                    <EmptyContent>No Error occured</EmptyContent>
            }
        </Section>
    );
}

export function Removed({ data, queueVideo, deleteComment }) {
    return (
        <Section>
            <SubHeading color="grey" >Removed | stopped</SubHeading>
            {
                data.length
                    ?
                    data.map((video, ind) =>
                        <Card
                            key={ind}
                            title={video.title}
                            url={video.url}
                            deleteButton={() => deleteComment(video.videoId)}
                            buttonTitle="Delete"
                            btn1Text="Queue"
                            btn1Action={() => queueVideo(video.videoId)} />
                    )
                    :
                    <EmptyContent>Things are clean here</EmptyContent>
            }
        </Section>
    );
}

// export const Popup({ chapters, videoId }) {
//     return (
//         <PopupRootContainer>
//         </PopupRootContainer>
//     );
// }