import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";

import { IframePlayer } from "./players.styles.js";
import Overlay from "./overlay/overlay.js";

let player,
  pausedVideo = true,
  timeUpdatingInterval;
var existingScript;

const YoutubePlayer = forwardRef(
  (
    {
      selectedTimestamp,
      videoId,
      addChapter,
      setCurrentDuration,
      setVideoDuration,
      onCopyVideo,
      colorElementsAry,
      hideChapterSeekerBtn,
      isLoggedIn,
    },
    ref
  ) => {
    const [isPlayerReady, setIsPlayerReady] = useState(false);
    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const [volumeLevel, setVolumeLevel] = useState(100);
    const [playingSpeed, setPlayingSpeed] = useState(1);
    const [videoState, setVideoState] = useState(-1);

    const onsetDuration = (dur) => {
      setDuration(dur);
      if (setVideoDuration) setVideoDuration(dur);
    };

    useImperativeHandle(ref, () => ({
      pauseVideo() {
        play(false);
      },
      playVideo() {
        play(true);
      },
    }));

    useEffect(() => {
      setIsPlayerReady(false);
      existingScript = document.getElementById("youtube-script");
      // console.log('useEffect triggered, existingScript:', existingScript);
      if (!existingScript && !window.YT) {
        var script = document.createElement("script");
        script.id = "youtube-script";
        script.src = "https://www.youtube.com/iframe_api";

        var firstScriptTag = document.getElementsByTagName("head")[0];
        firstScriptTag.appendChild(script);
      } else window.onYouTubeIframeAPIReady();

      window.onYouTubeIframeAPIReady = () => {
        // the Player object is created uniquely based on the id in props

        player = new window.YT.Player(`youtube-player-${videoId}`, {
          videoId: videoId,
          playerVars: {
            origin: window.location.origin,
            autoplay: 0,
            controls: 0,
            modestbranding: 1,
          },
          events: {
            onReady: () => {
              // console.log('onReady', isPlayerReady);
              setIsPlayerReady(true);
              let dur = player.getDuration();
              onsetDuration(dur);
              // console.log('duration', dur, duration, player.getDuration());
              let interval = setInterval(() => {
                let currentTime = player.getCurrentTime
                  ? player.getCurrentTime()
                  : 0;
                setCurrentTime(currentTime);
                setCurrentDuration(currentTime);

                // if (currentTime === dur)
                // 	clearInterval(interval);
              }, 1000);
            },
            onStateChange: (state) => {
              // console.log('video state', state.data);
              setVideoState(state.data);
              if (state.data === 0) {
                //video ended
                pausedVideo = true;
                clearInterval(timeUpdatingInterval);
                setCurrentTime(0);
                setIsPlaying(false);
                player.stopVideo();
              }
              if (state.data === 1) {
                //video playing
                timeUpdatingInterval = setInterval(() => {
                  if (pausedVideo) return;
                  let currentTime = player.getCurrentTime();
                  setCurrentTime(currentTime);
                  setCurrentDuration(currentTime);
                }, 1000);
                setIsPlaying(true);
              } else if (state.data === 2) {
                //video paused
                clearInterval(timeUpdatingInterval);
                setIsPlaying(false);
                pausedVideo = true;
              }
            },
            onPlaybackRateChange: (rate) => {
              setPlayingSpeed(rate.data);
            },
            onError: (event) => {
              console.log("ERROR on youtube player", event);
            },
            onApiChange: () => {
              // console.log("ON API CHANGE")
            },
          },
        });
      };
    }, [videoId, setDuration, setCurrentTime]);

    const play = (state) => {
      if (!player || !duration) return;
      setIsPlaying(state);
      if (state) {
        pausedVideo = false;
        player.playVideo();
      } else {
        pausedVideo = true;
        player.pauseVideo();
      }
    };

    const seekTo = (seconds) => {
      if (!player || !duration) return;
      player.seekTo(seconds, true);
      if (!currentTime) play(false);
    };

    const setVolume = (value) => {
      if (!player || !duration) return;
      setVolumeLevel(value);
      player.setVolume(value);

      if (parseInt(value) === 0) setIsMuted(true);
      else setIsMuted(false);
    };

    const changePlaybackSpeed = (rate) => {
      if (!player || !duration) return;
      player.setPlaybackRate(rate);
    };

    useEffect(() => {
      if (selectedTimestamp.start !== undefined) {
        seekTo(selectedTimestamp.start);
      }
    }, [selectedTimestamp, duration]);

    return (
      <div>
        <IframePlayer tabIndex={-1} id={`youtube-player-${videoId}`} />
        {isPlayerReady ? (
          <Overlay
            videoState={videoState}
            isLoggedIn={isLoggedIn}
            onCopyVideo={onCopyVideo}
            addChapter={addChapter}
            currentTime={currentTime}
            play={play}
            isPlaying={isPlaying}
            duration={duration}
            isMuted={isMuted}
            volumeLevel={volumeLevel}
            seekTo={seekTo}
            setVolume={setVolume}
            changePlaybackSpeed={changePlaybackSpeed}
            volume="100"
            playingSpeed={playingSpeed}
            colorElementsAry={colorElementsAry}
            hideChapterSeekerBtn={hideChapterSeekerBtn}
          />
        ) : null}
      </div>
    );
  }
);

export default YoutubePlayer;
