import axios from "axios";
import { serverURL } from "../../constants";

/**
 * Retrieves the transcript of a YouTube video in the specified format.
 * @param {("srt" | "json")} format - The format of the transcript to retrieve. Can be either "srt" or "json".
 * @param {string} url - The URL of the YouTube, wistia video to retrieve the transcript from.
 * @param {string} api_key - The API key to use for the request.
 * @returns {Promise<any>} - A Promise that resolves with the transcript data.
 */
export async function getTranscript(format, url, api_key, paid) {
  const { data } = await axios.post(
    `https://apis2.chapterme.co/transcript`,
    {
      url: url,
      paid: paid,
      out_format: format,
      pipeline: "TRANSCRIPT_AUDIO",
    },
    {
      headers: {
        "api-key": api_key,
      },
    }
  );
  return data;
}

export async function getApiKey(email) {
  const { data } = await axios.get(
    serverURL + "/admin/mlapi/getAPIKey/" + email
  );
  return data.result;
}

/**
 * Returns options for a toaster notification.
 * @function
 * @param {Object} options - The options for the toaster notification.
 * @param {string} options.toastId - The ID of the toaster notification.
 * @param {function} options.onClick - The function to be called when the notification is clicked.
 * @param {boolean} options.autoClose - Whether or not the notification should automatically close.
 * @returns {Object} The options for the toaster notification.
 */
export function toasterOptions({ toastId, autoClose = true, onClick }) {
  return {
    position: "top-right",
    autoClose,
    hideProgressBar: false,
    toastId,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    onClick: onClick,
  };
}

export const transcriptToolTipText = {
  1: "Transcript",
  0: "Generating Transcript",
  "-1": "Transcript Unavailable",
};
