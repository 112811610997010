import styled from "styled-components";

import { GrView } from "react-icons/gr";
import { AiFillHome } from "react-icons/ai";
import { BiLink } from "react-icons/bi";
import { MdEdit, MdDelete, MdMenu, MdShare, MdFeedback } from "react-icons/md";
import { ImEmbed2 } from "react-icons/im";
import { BsFillLockFill, BsSearch } from "react-icons/bs";
import { RiFeedbackLine } from "react-icons/ri";
import { VscFeedback } from "react-icons/vsc";
import { FaKeyboard } from "react-icons/fa";
import { BiCaptions } from "react-icons/bi";
// import { BiMenuAltRight } from "react-icons/bi";

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SelectNothing = styled.div`
  -webkit-touch-callout: none;
  -webkit-user-select: none;
`;
const bulkUploadPlaceHolder = `Please use the following format

hh:mm:ss Chapter name
hh:mm:ss Chapter name

	   (or)
	   
hh:mm:ss - Chapter name
hh:mm:ss - Chapter name
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 25%;
  left: 200px;
`;

export const GenerateEmailButton = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.purple};
`;

export const RootContainer = styled.div`
  // display: flex;
  flex: 1;
  background: #000;
  height: 100vh;
  background: ${({ theme }) => theme.darkGrey};

  @media only screen and (orientation: portrait) {
    flex-direction: column;
  }
`;
export const PlayerTopNav = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 3.5rem;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
  z-index: 3;
`;
export const MenuBtn = styled(MdMenu)`
  height: 2.5rem;
  width: 2.5rem;
  color: ${({ theme }) => theme.purple};
  margin: 0 1rem;
  padding: 5px;
  cursor: pointer;
  border-radius: 50%;
  transition: background 300ms;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const TopbarSpaceFiller = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;
export const TopbarRightContent = styled(Flex)`
  // width: 10rem;
  height: 100%;
  justify-content: space-evenly;
`;
export const FeedBackIcon = styled(RiFeedbackLine).attrs({ title: "Feedback" })`
  height: 1.7rem;
  width: 1.7rem;
  color: ${({ theme }) => theme.purple};
  padding: 8px;
  cursor: pointer;
  transition: background 300ms;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const EmbedIcon = styled(ImEmbed2).attrs({
  title: "Customize and Embed",
})`
  height: 2rem;
  width: 2rem;
  color: ${({ theme }) => theme.purple};
  padding: 8px;
  cursor: pointer;
  transition: background 300ms;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const ShareIcon = styled(MdShare).attrs({ title: "Share" })`
  height: 1.7rem;
  width: 1.7rem;
  color: ${({ theme }) => theme.purple};
  padding: 8px;
  cursor: pointer;
  transition: background 300ms;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const KeyboardShortcutKeyIcon = styled(FaKeyboard).attrs({
  title: "Keyboard Shortcuts",
})`
  height: 2rem;
  width: 2rem;
  padding: 10px;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;
  transition: background 300ms;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const SearchIcon = styled(BsSearch).attrs({ title: "search" })`
  height: 1.5rem;
  width: 1.5rem;
  padding: 10px;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;
  transition: background 300ms;
  border-radius: 50%;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const SearchInput = styled.input.attrs({
  size: 20,
  autoFocus: true,
})`
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.halfWhite};
  outline: none;
`;
export const MenuContainer = styled.div`
  position: relative;

  &:hover > div {
    display: flex;
  }
`;
export const TopbarMenuHoverContainer = styled(Flex)`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  position: absolute;
  top: 90%;
  left: 10%;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.3rem;
  font-weight: 300;
  height: 180px;
  width: 250px;
  max-width: 250px;
  color: black;
  background: ${({ theme }) => theme.white};
`;
export const MenuItem = styled(Flex)`
  width: 100%;
  justify-content: flex-start;
  cursor: pointer;
  text-align: start;
`;
export const MenuEmbedIcon = styled(ImEmbed2)`
  height: 1.3rem;
  width: 1.3rem;
  padding: 10px;
  margin-right: 15px;
  border-radius: 10px;
  background: ${({ theme }) => theme.purple};
`;
export const MenuShareIcon = styled(MdShare)`
  height: 1.3rem;
  width: 1.3rem;
  padding: 10px;
  border-radius: 10px;
  margin-right: 15px;
  background: ${({ theme }) => theme.purple};
`;
export const MenuLockIcon = styled(BsFillLockFill)`
  height: 1.3rem;
  width: 1.3rem;
  padding: 10px;
  border-radius: 10px;
  margin-right: 15px;
  background: ${({ theme }) => theme.purple};
`;
export const MenuHomeIcon = styled(AiFillHome)`
  height: 1.5rem;
  width: 1.5rem;
  padding: 10px;
  border-radius: 50%;
  margin: 0 15px;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const CopyIcon = styled(BiLink).attrs({ title: "Copy link" })`
  height: 2rem;
  width: 2rem;
  padding: 10px;
  border-radius: 50%;
  transition: background 300ms;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const TranscriptIcon = styled(BiCaptions).attrs({
  title: "Transcript",
})`
  height: 2rem;
  width: 2rem;
  padding: 10px;
  border-radius: 50%;
  transition: background 300ms;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.halfWhite};
  }
`;
export const PlayerContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  align-items: flex-end;
  position: relative;
`;
export const VideoContainer = styled.div`
  flex: 3;
  background: #000;
  position: relative;
  height: 100%;

  @media only screen and (orientation: portrait) {
    flex: 1;
    margin-bottom: 5px;
  }
`;
export const BulkUploadInput = styled.textarea.attrs({
  cols: 27,
  rows: 3,
  placeholder: bulkUploadPlaceHolder,
})`
  width: 100%;
  height: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  outline: none;
`;
export const SummaryContainer = styled.div`
  position: relative;
  flex: 1;
  height: calc(100% - 56px);
  width: 480px;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.white};
  text-align: left;
  min-height: 0;
  min-width: 0;

  @media only screen and (orientation: portrait) {
    flex: 3;
  }

  @media only screen and (max-width: 850px) {
    flex: 1.5;
  }
`;
export const ClearAllChapterDiv = styled.div`
  position: absolute;
  right: 10px;
  top: 1.1rem;
  font-size: 0.8rem;
  text-decoration: underline;
  color: white;
  cursor: pointer;
  z-index: 10;
`;
export const SearchContainer = styled.div`
  background: ${({ theme }) => theme.lightGrey};
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// export const SearchInput = styled.input.attrs({placeholder: "Search chapters title and description"})`
// 	width: 100%;
// 	padding: 5px 10px;
// 	color: ${({theme}) => theme.darkGrey};
// 	font-weight: bold;
// 	border: none;
// 	outline: none;
// 	border-radius: 5px;
// `;
export const SummaryListContainer = styled.div`
  flex: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  &:-webkit-scrollbar {
    display: none;
  }
`;
export const SummaryContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.purple};
  padding: 10px;
`;

export const SummaryContainerText = styled.div`
  color: ${({ theme }) => theme.white};
  font-weight: bolder;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1120px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;
export const SummaryCard = styled.div`
  position: relative;
  // display: flex;
  align-items: flex-start;
  min-height: 60px;
  padding: 15px 25px;
  color: ${({ theme }) => theme.white};
  background: ${(props) =>
    props.focused ? props.theme.white : props.theme.halfWhite};
  color: ${({ theme }) => theme.darkGrey};
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  outline: none;
  transition: background 300ms;

  ${(props) =>
    props.focused
      ? `
			background: ${props.theme.halfWhite};
			box-shadow: inset 1px 0 0 #dadce0, 
					inset -1px 0 0 #dadce0, 
					0 1px 2px 0 rgb(60 64 67 / 30%), 
					0 1px 3px 1px rgb(60 64 67 / 15%);
			z-index: 1;
		 `
      : `
			background: ${props.theme.white};
			box-shadow: inset 0 -1px 0 0 rgb(100 121 143 / 12%)
			z-index: 0;

		 `}

  @media(hover:hover) {
    &:hover {
      box-shadow: inset 1px 0 0 #dadce0, inset 0px 0 0 #dadce0,
        0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
      z-index: 1;
    }
  }
`;
export const SummaryCardTop = styled.div`
  display: flex;
`;
export const SummaryCardBottom = styled.div`
  flex: 1;
`;
export const SummaryCardLeft = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;
export const SummaryCardRight = styled.div`
  width: 80px;
  height: 100%;
  display: flex;
  justify-content: flex-end;

  & svg {
    margin: 0 10px;
  }
`;
export const SummaryTime = styled.div`
  font-size: 0.9rem;
  width: 100%;
  margin: 5px 0;
`;
export const SummaryTitle = styled.div`
  position: relative;
  font-size: 1.2rem;
  margin: 5px 0;

  // @media only screen and (max-width: 1120px) {
  // 	font-size: 18px;
  // }

  // @media only screen and (max-width: 1000px) {
  // 	font-size: 16px;
  // }
`;
export const SummaryDescription = styled.div`
  font-size: 0.9rem;
  max-height: 100px;
`;
const SummaryCardBtn = styled.div`
  padding: 5px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.halfWhite};
  transition: all 300ms;

  @media (hover: hover) {
    &:hover {
      transform: scale(1.05);
    }
  }
`;
export const EditChapterBtn = styled(MdEdit).attrs({ title: "Edit chapter" })`
  height: 1.2rem;
  width: 1.2rem;
  color: black;

  @media only screen and (max-width: 1200px) {
    height: 1.1rem;
    width: 1.2rem;
  }
  @media only screen and (max-width: 1000px) {
    height: 1rem;
    width: 1rem;
  }
`;
export const DeleteChapterBtn = styled(MdDelete).attrs({
  title: "Delete Chapter",
})`
  height: 1.2rem;
  width: 1.2rem;
  color: black;

  @media only screen and (max-width: 1200px) {
    height: 1.1rem;
    width: 1.1rem;
  }
  @media only screen and (max-width: 1000px) {
    height: 1rem;
    width: 1rem;
  }
`;
export const BtnContainerOnEdit = styled(Flex)`
  justify-content: flex-start;
  margin-top: 1.5rem;
`;
export const SaveBtn = styled(SummaryCardBtn)`
  width: 7rem;
  background: ${({ theme }) => theme.purple};
  margin: 0 10px;
`;
export const CancelBtn = styled(SummaryCardBtn)`
  width: 7rem;
  background: ${({ theme }) => theme.darkGrey};
  margin-left: 10px;
`;
export const ChaptersTitleDiv = styled.div`
  position: sticky;
  padding: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  border: 1px solid rgba(0, 0, 0, 0.4);
  background: ${({ theme }) => theme.purple};
`;
export const SummaryCardTopBar = styled.div`
  display: flex;
  color: ${({ theme }) => theme.metallic};
  text-align: left;
  align-items: center;
`;
export const SummaryCardTopBarLeftPiece = styled.div`
  flex: 1;
  font-size: 14px;
  color: ${({ theme }) => theme.metallic};
`;
export const SummaryCardTopBarRightPiece = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  // padding-right: 15px;
  font-size: 12px;
  font-weight: bold;
  color: ${({ theme }) => theme.metallic};
`;
export const ViewIcon = styled(GrView)`
  color: ${({ theme }) => theme.metallic};
`;
export const SummaryCardButtonBar = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;
export const SummaryCardButtonContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  padding: 5px;
`;
export const NoSummariesContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
`;
export const NoSummariesText = styled.div`
  text-align: center;
  font-size: 1.1em;
  // font-weight: bold;
  color: #23272a;
`;

export const NoDataImage = styled.img`
  width: 80%;
  height: auto;
  align-self: center;
`;

export const AddChapterButton = styled.div`
  display: inline-block;
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 5px;
  font-size: ${({ small }) => (small ? "0.8em" : "1em")};
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
  text-align: center;
  background: ${({ theme, background }) =>
    background ? background : theme.purple};
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme, background }) =>
      background ? background : theme.purple};
    background: ${({ theme }) => theme.white};
  }

  &:active {
    transform: scale(0.8);
  }

  @media only screen and (max-width: 1120px) {
    font-size: 14px;
  }

  @media only screen and (max-width: 1000px) {
    font-size: 12px;
    padding: 5px;
  }

  @media only screen and (max-width: 850px) {
    padding: 5px 10px;
    & > span {
      display: none;
    }
  }
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 10px;
  color: ${({ theme }) => theme.lightGrey};
`;
export const InputLabel = styled.div`
  color: ${({ theme }) => theme.white};
`;
export const SummaryInput = styled.input.attrs({ type: "text", size: 30 })`
  width: 80%;
  margin: 5px;
  padding: 5px 10px;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
`;

export const RecommendationsLoadingText = styled.div`
  color: ${({ theme }) => theme.colors.neutralGrey};
  font-weight: bold;
  font-size: 10px;
  margin: 2px 5px;
`;

export const RecommendationsContainer = styled.div`
  padding: 5px;
`;

export const RecommendationItem = styled.div`
  font-size: 12px;
  margin: 5px 0px;
  padding: 5px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.darkGrey};
  color: ${({ theme }) => theme.colors.halfWhite};
`;

export const RecommendationItemSpan = styled.span`
  padding: 0px 5px;
  color: #eee;
`;

export const SummaryTimerInput = styled.input`
  margin: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  transition: all 0.3s ease;
  border: ${(props) =>
    props.error ? "2px solid #F00" : "2px solid transparent"};
  outline: none;
  border-radius: 5px;
`;
export const SummaryDescriptionInput = styled.textarea`
  width: 80%;
  min-height: 20px;
  margin: 10px 5px 0px 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border-radius: 5px;
`;
export const SummaryDurationInput = styled.input.attrs({
  type: "text",
  size: 1,
  maxLength: 2,
})`
  margin: 5px;
  padding: 5px 10px;
  color: ${({ theme }) => theme.darkGrey};
  border: none;
  outline: none;
  border-radius: 5px;
`;
export const VideoTitleContainer = styled(SearchContainer)`
  display: flex;
  padding: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.darkGrey};
  background: ${({ theme }) => theme.white};
  // border-radius: 10px;

  @media only screen and (max-width: 1000px) {
    padding: 0px 5px;
  }
`;
export const VideoTitleText = styled(NoSummariesText)`
  flex: 1;
  font-size: 1em;
  font-weight: normal;
  text-align: left;
  color: inherit;
`;
export const ChannelTitleText = styled(VideoTitleText)`
  color: ${({ theme }) => theme.metallic};
`;
export const CopyContentContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.metallic};
  cursor: pointer;
`;
export const CopyIconContainer = styled.div`
  border-radius: 50%;
  padding: 10px;
`;
export const CopyTextContainer = styled.div`
  flex: 1;
  padding: 10px;
`;

export const MLLoadingContainer = styled(Flex)`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;

  // margin:10px;
  // padding: 20px 0;
  // color: ${({ theme }) => theme.lightGrey};

  // & div {
  // color: ${({ theme }) => theme.grey};
  // 	font-size: 1rem;
  // }
`;
export const Circle = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  height: 180px;
  width: 180px;
  gap: 10px;
  color: ${({ theme }) => theme.white};
  border-radius: 50%;
  border: none;
  background: ${({ theme }) => theme.purple};
`;

export const CopyTextHeading = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
  display: flex;
  align-items: center;
`;

export const CopyTextContent = styled.div`
  font-size: 18px;
  color: ${({ theme }) => theme.metallic};
`;
export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;
export const KeyboardShortcutContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 15px;
`;
export const KeyboardShortcutCard = styled(Flex)`
  flex-direction: column;
  height: 150px;
  width: 15vw;
  margin: 10px;
  background: #2e3438;
  padding: 10px;
  border-radius: 5px;
`;
export const KeyboardShortcutName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: ${({ theme }) => theme.metallic};
  margin-bottom: 20px;
`;
export const KeyboardShortcutKey = styled.div`
  width: ${({ type }) =>
    type === "square" ? "45px" : type === "medium" ? "80px" : "145px"};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  background: ${({ theme }) => theme.darkGrey};
  color: ${({ theme }) => theme.white};
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.metallic};
`;

export const SignupFormContainer = styled(KeyboardShortcutContainer)`
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
`;

export const TextInputLabel = styled.div`
  font-size: 12px;
  margin: 5px;
  font-weight: bold;
  color: ${({ theme }) => theme.white};
`;

export const TextInput = styled.input.attrs({ type: "text" })`
  width: 45%;
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};
  border: 1px solid #ccc;
`;

export const Errors = styled.div`
  width: 45%;
  font-size: 14px;
  color: ${({ theme }) => theme.red};
  margin: 5px 10px;
`;

// export const Button = styled.div`
// 	display: inline-block;
// 	background: ${({theme}) => theme.blue};
// 	padding: 10px;
// 	border-radius: 5px;
// 	color: ${({theme}) => theme.white};
// 	transition: all 0.5s ease;
// 	cursor: pointer;
// 	border: 2px solid transparent;

// 	&:hover {
// 		color: ${({theme}) => theme.blue};
// 		background: ${({theme}) => theme.white};
// 		border: 2px solid ${({theme}) => theme.blue};
// 	}

// 	&:active {
// 		transform: scale(0.8);
// 	}
// `;
export const ClearAllChapterContainer = styled(Flex)`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.6);
  z-index: 10;
`;
export const ClearAllChapterContents = styled(Flex)`
  height: 100px;
  width: 400px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  border: none;
  border-radius: 5px;
  padding: 30px;
  margin-bottom: 200px;
  background: ${({ theme }) => theme.halfWhite};
`;
export const ClearAllChapterText = styled.div`
  width: 100%;
  font-size: 1.2rem;
  text-align: left;
`;

export const ButtonContainer = styled(Flex)`
  width: 150px;
  justify-content: space-between;
`;
export const Button = styled.div`
  width: 50px;
  border: none;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  color: black;
  background: transparent;
  transition: background 300ms linear, color 300ms linear;

  &:nth-child(1):hover {
    background: ${({ hoverbg }) => hoverbg || "grey"};
  }

  &:nth-child(2):hover {
    color: white;
    background: red;
  }
`;
