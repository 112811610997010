import styled from 'styled-components';

const transcriptBackgrounds = [
	"#00a8e8",
	"#ffb703",
	"rgba(0, 0, 255, 0.5)",
]

export const RootContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100vh;
	padding-top: 56px; // top navbar
	box-sizing: border-box;

	audio {
		width: 60%;
	}
`;

export const TranscriptsWrapper = styled.div`
	max-height: 70%;
	width: 80%;
	overflow-y: scroll;
	transition: all 0.8s ease;
`;

export const TranscriptSpeaker = styled.h3`
	text-align: left;
	color: ${({theme}) => theme.colors.halfWhite};
`;

export const TranscriptContainer = styled.div`
	width: 80%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 10px;
	margin-left: 10px;
`;

export const TranscriptText = styled.span`
	padding: 5px;
	background: ${({speaker}) => transcriptBackgrounds[speaker-1]};
	color: ${({theme, speaker}) => speaker === null?theme.colors.halfWhite:theme.colors.pureWhite};
	border-radius: 5px;
`;
export const SwitchContainer = styled.div`
	width: 80%;
	height: 20px;
	text-align: left;
	color: ${({theme}) => theme.colors.halfWhite};

	& span {
		margin-left: 10px;
	}
`;