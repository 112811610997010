import {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useSelector, shallowEqual} from 'react-redux';
import Chapterme_short_logo from '../../logo-white.png';
import {useHistory} from 'react-router-dom';
import {TiHome} from 'react-icons/ti';
import {Modal, VerticalSpace} from 'components';
import {DiGoogleAnalytics} from 'react-icons/di';
import {FaUserCircle, FaMicrophoneAlt} from 'react-icons/fa';
import {MdOutlineSlowMotionVideo} from 'react-icons/md';

const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Navbar = styled(Flex)`
	flex-direction: column;
	justify-content: space-between;
	// min-height: 100vh;
	max-height: 100vh;
	padding: 10px;
	background: ${({theme}) => theme.blue};
	z-index: 1;
`;
const NavHover = styled(Navbar).attrs({
	id: 'nav-hover'
})`
	position: absolute;
	top: 0;
	left: 0;
	align-items: flex-start;
	height: 100vh;
	width: 230px;
	padding: 15px 0 0px 50px;
	padding-left: 50px;
	box-sizing: border-box;
	transform: translateX(-100%);
	transition: transform 300ms ease-in-out, opacity 300ms;
	z-index: -1;


	${Navbar}:hover & {
		transform: translateX(0);
	}
`;
const ChaptermeLogo = styled.img.attrs(props => ({
	src: props.src,
	alt: 'chapterme logo'
}))`
	width: 2rem;
`;
const NavbarMiddleBtnsContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;	
	justify-content: space-between;
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	cursor: pointer;
`;

const ButtonName = styled.div`
	height: 40px;
	margin-bottom: 1.5rem;
	font-size: 22px;
	color: white;
	transition: all 0.3s ease;
	opacity: 0;
	overflow: hidden;
	transition: opacity 300ms;
	cursor:  pointer;

	& sup {
		margin-top: 2px;
		margin-left: 10px;
		font-size: 12px;
		font-weight: bold;
		padding: 2px;
		border-radius: 5px;
		border: 1px solid #fff;
	}
	${Navbar}:hover & {
		opacity: 1;
		transition: opacity 1000ms;
	}
`;

const HomeIcon = styled(TiHome)`
	width: 35px;
	height: 35px;
	color: white;
	margin-bottom: 2rem;
`;

const AnalyticIcon = styled(DiGoogleAnalytics)`
	width: 35px;
	height: 35px;	
	color: white;
	margin-bottom: 2rem;
`;

const TrailersIcon = styled(MdOutlineSlowMotionVideo)`
	width: 35px;
	height: 35px;
	color: white;
	margin-bottom: 2rem;
`;

const PodcastIcon = styled(FaMicrophoneAlt)`
	width: 35px;
	height: 35px;	
	color: white;
	margin-bottom: 2rem;
`;

const UserImage = styled(FaUserCircle)`
	height: 35px;
	width: 35px;
	color: white;
`;


export const ModalContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
`;

export const ModalHeading = styled.div`
	font-size: 24px;
	font-weight: bold;
	color: ${({theme}) => theme.white};
	display: flex;
	align-items: center;
`;

export const ModalContent = styled.div`
	font-size: 18px;
	color: ${({theme}) => theme.metallic};
`;

export const Button = styled.div`
	display: inline-block;
	background: ${({theme}) => theme.blue};
	padding: 10px;
	border-radius: 5px;
	color: ${({theme}) => theme.white};
	transition: all 0.5s ease;
	cursor: pointer;
	align-self: center;
	border: 2px solid transparent;

	&:hover {
		color: ${({theme}) => theme.blue};
		background: ${({theme}) => theme.white};
		border: 2px solid ${({theme}) => theme.blue};
	}

	&:active {
		transform: scale(0.8);
	}
`;

export default ({history}) => {
	const user = useSelector(state => state.authReducer, shallowEqual)
	const [showBetaModal, setshowBetaModal] = useState(false);

	return (
		<Navbar>
			{
			showBetaModal
			?
			<Modal close={() => setshowBetaModal(false)} >
				<ModalContainer>
					<ModalHeading>
						Get beta access!
					</ModalHeading>
					<VerticalSpace count={2} />
					<ModalContent>
						We are currently testing this feature in private beta. If you are interested in joining, click below:
					</ModalContent>
					<VerticalSpace count={2} />
					<Button onClick={() => {window.open('https://bit.ly/chapterme_trailer_beta_gforms', '_blank'); setshowBetaModal(false)}} >Interested →</Button>
				</ModalContainer>
			</Modal>
			:
			null
			}
			<ButtonWrapper style={{cursor: 'default'}} >
				<ChaptermeLogo src={Chapterme_short_logo} />
			</ButtonWrapper>
			<NavbarMiddleBtnsContainer>
					<HomeIcon onClick={() => history.push('/dashboard') }/>
					<PodcastIcon onClick={() => history.push('/podcast') }/>
					<AnalyticIcon onClick={() => user.betaUser?history.push('/analytics'):setshowBetaModal(true) }/>
					<TrailersIcon onClick={() => setshowBetaModal(true) }/>
			</NavbarMiddleBtnsContainer>
			<ButtonWrapper onClick={() => history.push('/profile') } >
				<UserImage/>
			</ButtonWrapper>
			<NavHover>
					<ButtonName >Chapterme</ButtonName>
				<NavbarMiddleBtnsContainer>
						<ButtonName onClick={() => history.push('/dashboard') } >Dashboard</ButtonName>
						<ButtonName onClick={() => history.push('/podcast') } >Podcasts</ButtonName>
						<ButtonName onClick={() => user.betaUser?history.push('/analytics'):setshowBetaModal(true) } >Analytics<sup>Beta</sup></ButtonName>
						<ButtonName onClick={() => setshowBetaModal(true) } >Trailers<sup>Beta</sup></ButtonName>
				</NavbarMiddleBtnsContainer>
					<ButtonName style={{marginBottom: '2px', marginTop: '1.5rem'}} onClick={() => history.push('/profile') } >Profile</ButtonName>
			</NavHover>
		</Navbar>
	)
}