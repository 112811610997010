import {useState, useEffect} from 'react';
import {
	RootWrapper,
	RootContainer,
	PageHeader,
	SearchContainer,
	SearchInput,
	VideoListContainerWrapper,
	VideoListContainerHeader,
	VideoListOverflow,
	VideoListContainer,
	VideoFullListContainer,
	YTLoginContainer,
	YTLoginBtn,
	VideoTile,
	VideoTileTitle,
	TileSpaceFiller,
	VideoTileBottomDiv,
	VideoTileInfo,
	TileActionContainer,
	SearchHighlighter,
	SearchWrapper,
	SearchResultWrapper,
	SearchResultContainer,
	SearchResultThumb,
	SearchResultContent,
	VideoTitle,
	VideoSubtitle,
	ActionButtonWrapper,
	ActionButtonContainer,
	ActionButtonDescription,
	DescriptionChaptersSpan,
	EditButton,
	ShowAllButton,
} from './Podcast.styles';
import {Navbar, VerticalSpace} from 'components';
import {toast} from 'react-toastify';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useHistory} from 'react-router-dom';
import RingLoader from 'react-spinners/RingLoader';
import {authActions} from 'modules/auth';
import {summaryActions} from 'modules/summaries';
import moment from 'moment';



const PodcastDashboard = () => {
	const dispatch = useDispatch()
	const history = useHistory();
	const summaryState = useSelector(state => state.summaryReducer);
	const authState = useSelector(state => state.authReducer)
	const [isSearchFocused, setIsSearchFocused] = useState(false)
	const [currentHoveredActionButton, setCurrentHoveredActionButton] = useState("")
	const [urlInvalid, setUrlInvalid] = useState(false)
	const [showAllPreviousVideos, setShowAllPreviousVideos] = useState(false)
	const [showAllYoutubeVideos, setShowAllYoutubeVideos] = useState(false)
	const [videoUrl, setVideoUrl] = useState('');
	const [videoId, setVideoId] = useState("")

	// console.log("SUMMARY STATE", summaryState)

	const actionButtonDescriptionProvider = () => {
		if(summaryState.summaries && summaryState.summaries.length)
			return <ActionButtonDescription>This video has <DescriptionChaptersSpan length={1} >{summaryState.summaries.length} chapters</DescriptionChaptersSpan></ActionButtonDescription>

		switch(currentHoveredActionButton) {
			case "ai":
				return <ActionButtonDescription>Use our AI to add chapters to your videos faster than ever.</ActionButtonDescription>

			case "import":
				// return `Import and embed the video with chapters in your page or blog in our highly customizable player. ${summaryState.descriptionSummaries.length?`${summaryState.descriptionSummaries.length} Chapters are found.`:"**We can't find chapters from this video description. You can use our AI Tool to generate chapters quickly and efficiently.**"}`
				return <ActionButtonDescription>Import chapters from video description, if available.<br/><br/><DescriptionChaptersSpan length={summaryState.descriptionSummaries.length} >{summaryState.descriptionSummaries.length?`${summaryState.descriptionSummaries.length} chapters found.`: "Chapters are not available in this video's description. You can try our AI tool to create chapters automatically"}</DescriptionChaptersSpan></ActionButtonDescription>

			case "request":
				return <ActionButtonDescription>Need accurate chapters done by human professionals? Request here.</ActionButtonDescription>

			case "diy":
				return <ActionButtonDescription>Add chapters using our simple and intutive tool.</ActionButtonDescription>

			default:
				return <ActionButtonDescription>Please choose one of the options above.</ActionButtonDescription>
		}
	}

	const urlParser = (text) => {
		if (text.length)
			setIsSearchFocused(true);
		else
			setIsSearchFocused(false);
		setVideoUrl(text)
		setUrlInvalid(false)
		
		const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
		var res = regex.test(text);
		if(!res) {
			setUrlInvalid(true)
			return;
		}
		else if(text){
			setUrlInvalid(false)
			dispatch(summaryActions.requestPodcastChaptersAsyncActions.request({url: text, callback: (id) => history.push(`/podcasteditor/${id}`)}))
		}
	}

	const onRequestSummary = () => {
		const body = {
			accountId: authState.accountId,
			videoId,
			videoTitle: summaryState.metadata.title,
			platform: 'youtube',
			createdOn: moment()
		};
		const callback = (status) => {
			const msg = status 
				? 
				`Request received! We will notify you (support@chapterme.co) once chapters are available. Check Spam too 😉`
				:
				'Chapters have already been requested for this video. We will notify (support@chapterme.co) you once the chapters are available. Check spam too 😉';

			toast.success(msg, {
				position: "top-right",
				autoClose: 10000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
		}
		dispatch(summaryActions.createRequestAsyncActions.request({body, callback}))
		setVideoUrl('');
	}

	useEffect(() => {
		dispatch(summaryActions.recentVideosAsyncActions.request());
		dispatch(authActions.getProfileAsyncActions.request(authState.accountId));
		setVideoId('')
		setVideoUrl('')

		window.addEventListener( "pageshow", function ( event ) {
		  var historyTraversal = event.persisted || 
		                         ( typeof window.performance != "undefined" && 
		                              window.performance.navigation.type === 2 );
		  if ( historyTraversal ) {
		    // Handle page restore.
		    window.location.reload();
		  }

		});
		return () => {
			window.removeEventListener("pageshow", null)
		}
	}, [])

	useEffect(() => {
		if (authState.ytAuth) {
			dispatch(authActions.fetchChannelAction.request(authState.accountId));
		}
	}, [authState.ytAuth]);

	return (
		<RootWrapper>
			<Navbar history={history} />
			<RootContainer>
				<PageHeader open={isSearchFocused || videoId} >Podcast Dashboard</PageHeader>

				<SearchWrapper open={isSearchFocused || videoId} >
					<SearchContainer>
						{
							!isSearchFocused && !videoId
							?
							<SearchHighlighter />
							:
							null
						}
						<div>Start Chaptering 👇</div>
						<SearchInput onChange={evt => urlParser(evt.target.value) }  error={urlInvalid} />
					</SearchContainer>
					<SearchResultWrapper>
						{
							summaryState.isLoading
							?
							<ActionButtonDescription>
								Fetching Video
							</ActionButtonDescription>
							:
							summaryState.videoTitle && videoId && !urlInvalid
							?
							<SearchResultContainer>
								<SearchResultThumb src={summaryState.metadata.thumbnail} />
								<SearchResultContent>
									<VideoTitle>{summaryState.videoTitle}</VideoTitle>
									<br/>
									<VideoSubtitle>{moment.duration(summaryState.metadata.duration*1000).format('hh:mm:ss', {trim: false})} | {summaryState.summaries.length?`${summaryState.summaries.length} Chapters`:"Chapters not added yet"}</VideoSubtitle>
									{	
										!(summaryState.summaries && summaryState.summaries.length)
										?
										<ActionButtonWrapper>
											
										</ActionButtonWrapper>
										:
										<ActionButtonWrapper>
											<EditButton onClick={() => window.open(`/editor/${videoId}?platform=youtube`, '_self')} >Edit Chapters</EditButton>
										</ActionButtonWrapper>
									}
									{actionButtonDescriptionProvider()}
								</SearchResultContent>
							</SearchResultContainer>
							:
							urlInvalid
							?
							<ActionButtonDescription>
								My responses are limited. Please give use the right video!
							</ActionButtonDescription>
							:
							null
						}
					</SearchResultWrapper>
				</SearchWrapper>

				<VideoListContainerWrapper>
					<VideoListContainerHeader>
						Previously Chaptered Podcast
						<ShowAllButton  onClick={() => setShowAllPreviousVideos(p => !p)} >{showAllPreviousVideos?"Hide":"Show All"}</ShowAllButton>
					</VideoListContainerHeader>
					
				</VideoListContainerWrapper>
				<VerticalSpace count={5} />
			</RootContainer>
		</RootWrapper>
	)
}

export default PodcastDashboard;
