export const loginValidations = {
	email: {
		presence: {
			allowEmpty: false,
			message: "^Email cannot be empty."
		},
		email: {
			message: "^This doesn’t look like a valid email to us! If it does to you, contact us at support@chapterme.co"
		}
	},
	password: {
		presence: {
			allowEmpty: false,
			message: "^Password cannot be empty."
		}
	}
}

export const registerValidations = [
	{
		email: {
			presence: {
				allowEmpty: false,
				message: "^Please enter your email to proceed! It’s mandatory."
			},
			email: {
				message: "^This doesn’t look like a valid email to us! If it does to you, contact us at support@chapterme.co"
			}
		},
		password: {
			presence: {
				allowEmpty: false,
				message: "^Password cannot be empty."
			}
		},
		name: {
			presence: {
				allowEmpty: false,
				message: "^Name cannot be empty."
			},
			format: {
				pattern: "[a-zA-Z ]+",
				flags: "g",
				message: "^Your name cannot contain special characters or numbers."
			}
		}
	},

	{
		useCase: {
			booleanObjectChecker: {
				restrictedKey: false,
				message: "^You need to select at least one of the above options.",
			}
		}
	},
	{
		channelName: {
			presence: {
				allowEmpty: false,
				message: "^A channel name is required. It can be either your YouTube channel name or your name."
			},
		}
	}
]