import React, {useState, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {RootContainer, TransparentScreen, LoginContainer, InputContainer, Input, SubmitButton, PageHeader, UnderlinedButton, LoaderContainer} from './Signup.styles'
import {authActions} from 'modules/auth';
import {toast} from 'react-toastify';
import PropagateLoader from 'react-spinners/PropagateLoader'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const verifyEmail = (e) => {

  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

	if (!re.test(e.value)) {
		e.parentNode.style.borderColor = 'red';
		return false;
	}
	else {
		e.parentNode.style.borderColor = '#7289da';
		return true;
	}
}

export default ({popLogin, setShowLoginDialog, triggerFetchSummaries, setIsSignUp, postAction, onRequestSummary, welcomeToast}) => {
	const dispatch = useDispatch()
	const history = useHistory();
	const authState = useSelector(state => state.authReducer, shallowEqual);
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [password, setPassword] = useState('');
	const query = useQuery();
	const [queryParams, setQueryParams] = useState('');

	const onVerifyPress = () => {

		if (!name.length || !email.length || !password.length || !verifyEmail(document.querySelector('input[name="signup-email"]')))
		{
			let elem = [...document.getElementsByTagName('input')];
			// console.log(elem.length);
			elem.forEach(arr => {
					if (arr.value.trim() === '')
						arr.parentNode.style.borderColor = 'red';
					else
						arr.parentNode.style.borderColor = '#7289da';	//default purple color

					if (arr.type === 'email' && !verifyEmail(arr))
						toast.error('Invalid email address', {
							position: "top-right",
							autoClose: 8000,
							hideProgressBar: false,
							closeOnClick: false,
							pauseOnHover: true,
							draggable: true,
							progress: undefined,
							toastId: 'requestSummary'
						});
			})
			// toast.dismiss();
			return;
		}
		dispatch(authActions.signupAsyncActions.request({name, email, password}))
	}

	

	useEffect(() => {
		// const goto = query.get('to').replaceAll('|', '&');
		// if(goto) {
		// 	setQueryParams('/?to='+goto)
		// }

		if(authState.loginSuccess) {
			// if(goto) {
			// 	history.push(`${goto}&fetch=false`)
			// 	return;
			// }
			if (popLogin) {
				if (postAction) {
					welcomeToast();
					postAction();
				}
				else if (onRequestSummary)
					onRequestSummary();
				setShowLoginDialog(false);
			}
			else
				history.push(`/dashboard`)
		}
	}, [authState])

	return (
		<RootContainer popLogin={popLogin}>
			{
				popLogin?<TransparentScreen onClick={() => {if (!authState.isLoading)setShowLoginDialog(false)}} />:null
			}
			<LoginContainer>
				<PageHeader>Create Account</PageHeader>
				<InputContainer>
					<Input placeholder="Name (required)" fullWidth onChange={evt => setName(evt.target.value)}  />
				</InputContainer>
				<InputContainer>
					<Input type='email' name='signup-email' placeholder="Email (required)" fullWidth onChange={evt => setEmail(evt.target.value)} onFocus={e => {e.target.parentNode.style.borderColor = '#7289da';}} onBlur={e => {verifyEmail(e.target)}}  />
				</InputContainer>
				<InputContainer>
					<Input placeholder="Password*" onChange={evt => setPassword(evt.target.value)} fullWidth type="password" />
				</InputContainer>
				{
					authState.isLoading
					?
					<PropagateLoader size={15} color="#7289da" />
					:
					<LoaderContainer>
						<SubmitButton onClick={onVerifyPress} >Welcome 🎉</SubmitButton>
					</LoaderContainer>
				}
				<UnderlinedButton onClick={() => {popLogin?setIsSignUp(false):history.push(`/login${queryParams}`)}} >Already have an account? Login Here</UnderlinedButton>
			</LoginContainer>
		</RootContainer>
	)
}