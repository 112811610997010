import styled from 'styled-components';

export const RootContainer = styled.div`
	width: 100vw;
	height: 100vh;
	background: ${({theme}) => theme.metallic};
	display: flex;
	justify-content: center;
	align-items: center;
	background: #0F2027;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const LoginContainer = styled.div`
	width: 40vw;
	height: 80vh;
	border-radius: 20px;
	box-shadow: 5px 5px 10px ${({theme}) => theme.darkGrey};
	background: ${({theme}) => theme.white};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const LogoImage = styled.div`
	width: 40%;
	height: auto;
`;

export const InputContainer = styled.div`
	display: flex;
	width: 80%;
	align-items: center;
	border: 2px solid ${({theme}) => theme.purple};
	border-radius: 5px;
	margin: 10px;
	color: ${({theme}) => theme.lightGrey};
`;

export const Input = styled.input.attrs(props => ({type: props.type||"text"}))`
	border: none;
	outline: none;
	padding: 10px;
	padding-right: 0px;
	text-align: center;
	width: ${props => props.fullWidth?'100%':'50%'};
`;

export const PageHeader = styled.div`
	font-size: 2em;
	color: ${({theme}) => theme.purple};
`;

export const SubmitButton = styled.div`
	padding: 10px;
	display: inline-block;
	cursor: pointer;
	font-size: 1em;
	border-radius: 10px;
	color: ${({theme}) => theme.white};
	transition: all 0.3s ease;
	background: #0F2027;  /* fallback for old browsers */
	background: -webkit-linear-gradient(to bottom, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
	background: linear-gradient(to bottom, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

	&:hover {
		font-size: 1.2em
	}

	&:active {
		font-size: 0.8em
	}
`;

export const UnderlinedButton = styled.div`
	text-align: center;
	color: ${({theme}) => theme.purple};
	font-weight: bold;
	font-size: 12px;
	text-decoration: underline;
	margin: 10px;
	padding: 10px;
	cursor: pointer;
`;

export const LoaderContainer = styled.div`
	margin: 10px;
`;