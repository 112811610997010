
export const urlParser = (text) => {
    
    const regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    var res = regex.test(text);
    if(!text) {
        return {valid: false};
    }

    let platform = text.match(/(https:\/\/)?([a-z]*\.)?(youtube|youtu|wistia|vimeo|wi)(\.[a-z]*)/)
    if(platform === null || !platform.length || (platform[3] !== 'youtube' && platform[3] !== 'youtu')) {
        return {valid: false};
    }

    const urlVideoId = text.match(/(\/|%3D|v=|vi=)([0-9A-z-_]{11})([%#?&]|$)/)
    if(!urlVideoId) {
        return {valid: false};
    }
    
    
    return {valid: true, videoId: urlVideoId[2]};
}

export const status = {
    inProgress: 'IN-PROGRESS',
    queue: 'QUEUE',
    completed: 'COMPLETED',
    saved: 'SAVED',
    error: 'ERROR',
    stopped: 'STOPPED'
}

export function filterComments(comments) {
    const inProgress = [];
    const queue = [];
    const completed = [];
    const saved = [];
    const error = [];
    const stopped = []
    comments.forEach(comment => {
        switch (comment.status) {
            case status.inProgress:
                inProgress.push(comment);
                break;
            case status.queue:
                queue.push(comment);
                break;
            case status.completed:
                completed.push(comment);
                break;
            case status.saved:
                saved.push(comment);
                break;
            case status.error:
                error.push(comment);
                break;
            case status.stopped:
                stopped.push(comment);
                break;
        }

    })

    return {inProgress: inProgress[0] || [], queue, completed, saved, error, stopped};
}