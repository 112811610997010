import {useState, useEffect, useRef} from 'react';
import {
    RootWrapper,
    RootContainer,
    HeaderContainer,
    HeaderImage,
    PodcastDetailContainer,
    PodcastHeading,
    PodcastChapters,
    TabTitleBar,
    TabTitle,
    TabContainer,
    TranscriptsWrapper,
    TranscriptSpeaker,
    TranscriptContainer,
    TranscriptText,
    ChapterContainer,
    ChapterTime,
    ChapterText,
    TranscriptsOverflow
} from './PodcastEmbed.styles';
import {VerticalSpace} from 'components';
import axios from 'axios';
import moment from 'moment';
import {useQuery} from 'utils';

const PodcastEmbed = () => {
    const audioPlayerRef = useRef(null)
    const currentWordRef = useRef(null);
    const transcriptScroller = useRef(null);

    const query = useQuery();

    const [currentTime, setCurrentTime] = useState(0);
    const [apiResponse, setApiResponse] = useState({});
    const [autoScroll, setAutoScroll] = useState(true);
    const [currentScreen, setCurrentScreen] = useState(2)

    const title = query.get('title')
    const id = query.get('id')
    const image = query.get('image')
    const color = query.get('color')

    const getSummaries = async () => {
        const {data} = await axios.get(`https://devserver.chapterme.co/video/podcast/${id}`);
        // console.log("API RESPONSE", data);
        setApiResponse(data.result)
    }

    // console.log("API RESPONSE 2", apiResponse)

    useEffect(() => {
        getSummaries()
    }, [])

    useEffect(() => {
        // console.log("EVENT ADDED OUTSIDE", audioPlayerRef)
        if(audioPlayerRef?.current) {
            // console.log("EVENT ADDED")
            audioPlayerRef.current.addEventListener("timeupdate", () => {
                setCurrentTime(audioPlayerRef.current.currentTime);
            })
        }
    }, [audioPlayerRef])


    useEffect(() => {

        if (autoScroll && currentWordRef.current) {
            const y = (currentWordRef.current?.offsetTop || 0) - 600;
            // console.log(y);
            transcriptScroller.current?.scrollTo({
                top: y,
                behavior: 'smooth'
            })
        }
    }, [autoScroll, currentWordRef.current, transcriptScroller]);

    // console.log("CURRENT TIME", currentTime, audioPlayerRef);

    return (
        <RootWrapper>
            <RootContainer>
                <HeaderContainer>
                    <HeaderImage src={image} />
                    <PodcastDetailContainer>
                        <PodcastHeading>{title.replaceAll("_", " ")}</PodcastHeading>
                        <PodcastChapters>{apiResponse?.summary?.length} Chapters 🔖</PodcastChapters>
                    </PodcastDetailContainer>
                </HeaderContainer>
                    <audio ref={audioPlayerRef} src={apiResponse.podcastUrl} controls={1} style={{width: '100%'}} />
                <TabTitleBar>
                    <TabTitle selected={currentScreen === 1} onClick={() => setCurrentScreen(1)} >Chapters</TabTitle>
                    <TabTitle selected={currentScreen === 2} onClick={() => setCurrentScreen(2)} >Transcript</TabTitle>
                </TabTitleBar>
                {
                    currentScreen === 1
                    ?
                    <TabContainer>
                        {
                            apiResponse?.summary?.map(summary => {
                                return (
                                    <ChapterContainer onClick={() => audioPlayerRef.current.currentTime = summary.start} >
                                        <ChapterTime>{moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })}</ChapterTime>
                                        <ChapterText>{summary.title}</ChapterText>
                                    </ChapterContainer>
                                )
                            })
                        }
                    </TabContainer>
                    :
                    <TabContainer style={{height: '100%'}}>
                    <TranscriptsOverflow  ref={transcriptScroller} >
                        <TranscriptsWrapper>
                            {
                                apiResponse?.transcripts?.length
                                ?
                                apiResponse?.transcripts?.map(transcriptGroup => {
                                    // console.log("TRNASCRIPT GROUP", transcriptGroup)
                                    return (
                                        <>
                                            <TranscriptSpeaker>{transcriptGroup.speaker}:</TranscriptSpeaker>
                                            <TranscriptContainer>
                                            {
                                                transcriptGroup?.transcript?.map(transcript => {
                                                    const speaker = transcript.start <= currentTime && transcript.end >= currentTime;
                                                    return <TranscriptText key={Math.random()} ref={speaker?currentWordRef:null} speaker={speaker?transcript.speakerTag:null} onClick={() => audioPlayerRef.current. currentTime = transcript.start} >{transcript.word}</TranscriptText>
                                                })
                                            }
                                            </TranscriptContainer>
                                        </>
                                    )
                                })
                                :
                                null
                            }
                            <VerticalSpace count={50}  />
                        </TranscriptsWrapper>
                    </TranscriptsOverflow>
                    </TabContainer>
                }
            </RootContainer>
        </RootWrapper>
    )
}

export default PodcastEmbed;