import styled from 'styled-components';

export const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const RootContainer = styled(Flex)`
	position: relative;
	height: 100vh;
	width: 100vw;
	background: white;
`;

export const TitleImage = styled.img`
	width: 300px;
	height: auto;
	margin: 10px 0 30px 0;
	filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, .8));

	@media only screen and (max-width: 700px) {
		width: 60%;
		margin: 5px;
	}
`;

export const TrailerTitle = styled.div`
	font-size: 1.25em;
`;
export const TrailerDescription = styled.div`
	margin: 0 0 20px 0;
`;

const Container = styled(Flex)`
	flex-direction: column;
	margin: 30px;
	margin-bottom: 100px;
	border-radius: 8px;
	box-shadow: 0 10px 15px rgba(0, 0, 0, .7);
	background: #3392ff;
	color: white;
`;

export const InputContainer = styled(Container)`
	height: 90%;
	width: 90%;
	padding: 10px;
	margin-bottom: 30px;
`;

export const FieldContainer = styled(Flex)`
	justify-content: space-between;
	margin: 10px 0;

	& input
	{
		margin: 0 10px;
		color: white;
		font-size: 1em;
		font-weight: bold;
		outline: none;
		border: 0px solid;
		border-bottom: 1px solid white;
		background: transparent;
	}
`;

export const LoginRequestContainer = styled(Container)`
	height: 500px;
	width: 400px;
`;

export const LoginRequest = styled.div`
	font-size: 1.25em;
	margin-bottom: 50px;
`;

export const Button = styled.div`
	padding: 10px;
	cursor: pointer;
	margin: 10px;
	border-radius: 8px;
	background: #214791;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, .4);
	transition: all 300ms;

	@media(hover: hover)
	{
		&:hover
		{
			border-radius: 3px;
			transform: scaleX(1.08);
		}
	}
`;