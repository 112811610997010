import styled from 'styled-components';

export const RootContainer = styled.div`
	display: flex;
	max-height: 100vh;
	height: 100%;
	width: 100%;
	background: ${({theme}) => theme.colors.white};
	color: #575555;
	overflow-y: hidden;
`;

export const RightScreen = styled.div`
	max-height: 100vh;
	overflow-y: scroll;
	width: 100%;
`;

export const AnalyticsHeaderContainer = styled.div`
	font-size: 2rem;
	color: ${({theme}) => theme.colors.lightGrey};
	text-align: left;
`;

export const SectionSelector = styled.div`
	display: flex;
	border-bottom: 1px solid ${({theme}) => theme.colors.darkGrey};
`;

export const SectionTitle = styled.div`
	margin-bottom: -1px;
	padding: 0px 10px;
	font-size: 1.5rem;
	color: ${({theme, selected}) => selected?theme.colors.purple:theme.colors.lightGrey};
	border-bottom: ${({theme, selected}) => selected?`6px solid ${theme.colors.purple}`:`6px solid transparent`};
	cursor: pointer;
	transition: all 0.3s ease;
`;

export const SectionContainer = styled.div`
	position: relative;
	width: 100%;
`;

export const CardContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 100%;
`;

export const Card = styled.div`
	width: 20%;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	background-color: ${({theme}) => theme.colors.pureWhite};
	border-radius: 10px;
	min-height: 150px;
	max-height: 200px;
	box-shadow: 4px 4px 16px rgba(0,0,0,0.3);
	cursor: pointer;
`;

export const CardTitle = styled(SectionTitle)`
	color: ${({theme}) => theme.colors.lightGrey};
	border-bottom: none;
`;

export const CardSubtitle = styled(CardTitle)`
	font-size: 1rem;
`;

export const CardContent = styled(CardTitle)`
	font-size: 4rem;
	font-weight: bold;
`;

export const TrailersListWrapper = styled.div`
	width: calc(100% - 40px);
	background-color: ${({theme}) => theme.colors.pureWhite};
	border-radius: 10px;
	box-shadow: 4px 4px 16px rgba(0,0,0,0.3);
	padding: 10px;
	margin: 0px 10px;
`;

export const TrailersRow = styled.div`
	padding: 10px;
	min-height: 36px;
	width: calc(100% - 20px);
	display: flex;
	align-items: center;
	cursor: pointer;

	&:nth-child(even) {
		background-color: #ccc;
	}
`;

export const TrailerColumnHeading = styled.div`
	font-size: 1rem;
	font-weight: bold;
	color: ${({theme}) => theme.colors.darkGrey};
`;

export const TrailerCell = styled.div`
	flex: 3;
	text-align: left;
	display: flex;
	align-items: center;
`;

export const DataCell = styled.div`
	flex: 1;
	font-size: 1rem;
	text-align: center;
	color: ${({theme}) => theme.colors.darkGrey};
	text-align: center;
`;