import { call, put, takeLeading, takeLatest, select } from "redux-saga/effects";
import {
  fetchSummariesAsyncActions,
  postSummariesAsyncActions,
  recentVideosAsyncActions,
  createRequestAsyncActions,
  getRequestAsyncActions,
  fetchRequestAsyncActions,
  searchVideosAsyncActions,
  useMlAsyncActions,
  getSummaryRecommendationAsyncAction,
  requestPodcastChaptersAsyncActions,
  requestPodcastTranscriptsAsyncActions,
} from "./actions";
import {
  fetchSummaries,
  upsertSummaries,
  recentVideos,
  createRequest,
  getRequest,
  searchVideos,
  useMl,
  getRecommendations,
  requestPodcastChapters,
  requestPodcastTranscripts,
} from "./api";
import { toast } from "react-toastify";
import moment from "moment";
import _ from "lodash";

function* getSummariesSaga(action) {
  try {
    const response = yield call(fetchSummaries, action.payload);
    yield put(
      fetchSummariesAsyncActions.success({
        metadata: {
          ...response.data.result.metadata,
          duration:
            action.payload.platform !== "vimeo"
              ? moment
                  .duration(response.data.result.metadata.duration)
                  .asSeconds()
              : response.data.result.metadata.duration,
        },
        summaries: response.data.result.summary || {},
        autoChapters: response.data.result.autoChapters,
      })
    );
  } catch (err) {
    console.error("Something went wrong while retrieving chapters", err);
    if (action.payload.platform === "vimeo")
      toast.error(
        "If you see the error, whitelist chapterme.co. If the issue persists, email support@chapterme.co ASAP",
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
        }
      );
    yield put(fetchSummariesAsyncActions.error());
  }
}

function* recentVideosSaga(action) {
  try {
    const accountId = yield select((state) => state.authReducer.accountId);
    const response = yield call(recentVideos, accountId);
    yield put(recentVideosAsyncActions.success(response.data.result));
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(recentVideosAsyncActions.error());
  }
}

function* upsertSummariesSaga(action) {
  try {
    const accountId = yield select((state) => state.authReducer.accountId);
    const response = yield call(upsertSummaries, action.payload.videoId, {
      ...action.payload.requestBody,
      accountId,
    });
    toast.success("Chapters added successfully", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    toast.dismiss("ml-email-toast");
    yield put(postSummariesAsyncActions.success(response.data.result));
    yield* recentVideosSaga();
  } catch (err) {
    console.log(err);
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(postSummariesAsyncActions.error());
  }
}

function* createRequestSaga(action) {
  try {
    toast.dismiss();
    const response = yield call(createRequest, action.payload.body);
    action.payload.callback(true);
    yield put(createRequestAsyncActions.success());
  } catch (err) {
    if (err.response.data.result.status === 409) action.payload.callback(false);
    else
      toast.error(err.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    yield put(createRequestAsyncActions.error());
  }
}

function* getRequestSaga(action) {
  try {
    const accountId = yield select((state) => state.authReducer.accountId);
    const response = yield call(getRequest, accountId);
    yield put(getRequestAsyncActions.success(response.data.result));
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(getRequestAsyncActions.error());
  }
}

function* searchVideosSaga(action) {
  try {
    const response = yield call(searchVideos, action.payload);
    const array = [
      ...response.data.result.youtubeData,
      ...response.data.result.vimeoData,
    ];
    yield put(searchVideosAsyncActions.success(_.shuffle(array)));
  } catch (err) {
    console.error("SEARCH_VIEOS", err);
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(searchVideosAsyncActions.error());
  }
}

function* useMlSaga(action) {
  try {
    const isPaid = yield select((state) => state.authReducer.paid);
    const response = yield call(useMl, action.payload, isPaid);
    yield put(useMlAsyncActions.success());
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    yield put(useMlAsyncActions.error());
  }
}

function* getSummaryRecommendationSaga(action) {
  try {
    const response = yield call(getRecommendations, action.payload);
    yield put(
      getSummaryRecommendationAsyncAction.success(response.data.recommendations)
    );
  } catch (err) {
    yield put(getSummaryRecommendationAsyncAction.error());
  }
}

function* requestPodcastChaptersSaga(action) {
  try {
    const response = yield call(requestPodcastChapters, action.payload.url);
    action.payload.callback(response.data.uniqueId);
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("ERROR", err);
  }
}

function* requestPodcastTranscriptsSaga(action) {
  try {
    const response = yield call(requestPodcastTranscripts, action.payload.url);
    const arrayOfTranscripts = response.data.wordTranscripts.word.map(
      (word, ind) => ({
        word,
        start: response.data.wordTranscripts.start[ind],
        end: response.data.wordTranscripts.end[ind],
        speakerTag: response.data.wordTranscripts.speakerTag[ind],
      })
    );
    // console.log("RESPONSE FOR PODCAST TRANSCRIPTS", arrayOfTranscripts);
    action.payload.callback(arrayOfTranscripts);
  } catch (err) {
    toast.error("Something went wrong", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    console.log("PODCAST ERROR", err);
  }
}

function* mainSaga() {
  yield takeLeading(
    fetchSummariesAsyncActions.actionNames.request,
    getSummariesSaga
  );
  yield takeLeading(
    postSummariesAsyncActions.actionNames.request,
    upsertSummariesSaga
  );
  yield takeLeading(
    recentVideosAsyncActions.actionNames.request,
    recentVideosSaga
  );
  yield takeLeading(
    createRequestAsyncActions.actionNames.request,
    createRequestSaga
  );
  yield takeLeading(getRequestAsyncActions.actionNames.request, getRequestSaga);
  yield takeLatest(
    searchVideosAsyncActions.actionNames.request,
    searchVideosSaga
  );
  yield takeLatest(
    getSummaryRecommendationAsyncAction.actionNames.request,
    getSummaryRecommendationSaga
  );
  yield takeLatest(useMlAsyncActions.actionNames.request, useMlSaga);
  yield takeLeading(
    requestPodcastChaptersAsyncActions.actionNames.request,
    requestPodcastChaptersSaga
  );
  yield takeLeading(
    requestPodcastTranscriptsAsyncActions.actionNames.request,
    requestPodcastTranscriptsSaga
  );
}

export default mainSaga;
