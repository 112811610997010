
import { Button } from "components/Comment";
import styled from "styled-components";


const RootContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;
const Image = styled.img`
    height: 80px;
    width: 80px;
    margin-right: 1rem;
`;
const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: space-between;
`;
const Content = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    margin: 0.3rem 0;
`;
const Title = styled.h4`
    margin: 0;
    text-align: left;
`;
const ProgressText = styled.div`
    position: absolute;
    padding: 10px;
    left: 1.5rem;
    bottom: -20px;
    background-color: orange;
    border: 0;
    border-radius: 25px;
    color: black;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
`;

export default function InProgress({ data, stop }) {

    function formatDuration(duration) {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const match = regex.exec(duration);
        const hours = match[1] || '0';
        const minutes = match[2] || '0';
        const seconds = match[3] || '0';
        return `${hours !== '0' ? hours + ':' : ''}${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
    }

    return (
        <>
            {
                data.videoId &&
                <RootContainer>
                    <Image src={data.thumbnail} />
                    <ContentContainer>
                        <Content>
                            <Title>{data.title}</Title>
                            <Button onClick={() => stop(data.videoId)} >stop</Button>
                        </Content>
                        <Content>{formatDuration(data.duration)}</Content>
                        <Content>
                            <div>
                                {data.url}
                            </div>
                            <div>
                                Approx. Processing Time - 9 mins
                            </div>
                        </Content>
                    </ContentContainer>
                    <ProgressText>In Progress</ProgressText>
                </RootContainer>
            }
        </>
    );
}