import styled from "styled-components";

const GlassyCover = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
`;

const Container = styled.div`
  width: ${({ large, maxContent }) =>
    large ? "80vw" : maxContent ? maxContent : "60vw"};
  height: auto;
  max-height: 85vh;
  overflow-y: auto;
  background: ${({ theme }) => theme.darkGrey};
  border-radius: 10px;
  padding: 15px;
  padding-top: 0;

  @media only screen and (orientation: portrait) {
    width: 90vw;
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 3rem;
  color: ${({ theme }) => theme.white};
  cursor: pointer;
`;

const Modal = ({ children, close, large, maxContent }) => (
  <GlassyCover onClick={close}>
    <Container
      large={large}
      maxContent={maxContent}
      onClick={(e) => e.stopPropagation()}
    >
      <Flex>
        <CloseButton onClick={close}>&times;</CloseButton>
      </Flex>
      {children}
    </Container>
  </GlassyCover>
);

export default Modal;
