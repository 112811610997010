import axios from "axios";
import { serverURL } from "../../constants";

export const getAnalyticalNumbers = (channelName, video, sort, order) => {
  return axios.get(
    `${serverURL}/admin/analytics/${
      video ? "video" : "trailer"
    }/${channelName}?sort=${sort || "createdOn"}&order=${order || -1}`
  );
};

export const getVideoAnalyticalNumbers = (accountId) => {
  return axios.get(`${serverURL}/admin/analytics/video/${accountId}`);
};

export const getSessionNumbers = (videoId) => {
  return axios.get(serverURL + "/admin/analytics/sessions/video/" + videoId);
};

export const trailerVideos = (channelName) => {
  return axios.get(serverURL + "/trailer/getTrailers/" + channelName);
};

export const getTrailerSessionNumbers = (trailerId, time, sort, order) => {
  return axios.get(
    serverURL +
      "/admin/analytics/sessions/trailer/" +
      trailerId +
      `?time=${time}&sort=${sort || "createdOn"}&order=${order || -1}`
  );
};

export const getSpecificTrailerAnalytics = (videoId) => {
  return axios.get(serverURL + "/admin/analytics/specific/trailer/" + videoId);
};
