import { useState, useEffect } from "react";
import {
  RootContainer,
  BannerContainer,
  BannerTitle,
  BannerSubtitle,
  FormContainer,
  SectionSelectorContainer,
  SectionSelector,
  SectionContainer,
  SectionTitle,
  SectionTitle1,
  TextInputLabel,
  TextInput,
  Button,
  CircularStepsContainer,
  CircularStepsContainerPassLine,
  RoundedStepNumberContainer,
  RoundedStepNameContainer,
  RoundedStepName,
  Errors,
  UseCaseWrapper,
  UseCaseContainer,
  UseCaseTitle,
  UseCaseSubtitle,
  TestPilotContainer,
} from "./Auth.styles";
import { authActions } from "modules/auth";
import { animated, useTransition } from "react-spring";
import { VerticalSpace } from "components";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SquareLoader from "react-spinners/SquareLoader";
import { useHistory, useLocation } from "react-router-dom";
import validate from "validate.js";
import { loginValidations, registerValidations } from "./constraints";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

validate.validators.booleanObjectChecker = function (
  value,
  options,
  key,
  attributes
) {
  // console.log("VALIDATOR", value, options, key, attributes);

  if (Object.values(value).every((ent) => ent === options.restrictedKey)) {
    return options.message;
  }
};

const Input = ({ placeholder, onChange, label, value, type }) => {
  return (
    <>
      <TextInputLabel>{label}</TextInputLabel>
      <TextInput
        placeholder={placeholder}
        onChange={(evt) => onChange(evt.target.value)}
        value={value}
        type={type}
      />
    </>
  );
};

const Auth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authState = useSelector((state) => state.authReducer, shallowEqual);
  const query = useQuery();

  const currentPage = query.get("source");

  const [currentScreen, setCurrentScreen] = useState(
    currentPage === "sign-up" ? 2 : 1
  );
  const [currentStage, setCurrentStage] = useState(1);
  const [createTestPilot, setCreateTestPilot] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [channelName, setChannelName] = useState("");
  const [errors, setErrors] = useState({ email: [], password: [] });
  const [useCase, setUseCase] = useState({ video: false, trailer: false });
  const [loading, setLoading] = useState(false);

  const screenTransitions = useTransition(currentScreen, {
    from: { position: "absolute", x: -250, y: 0, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1, width: "100%" },
    leave: { x: 250, y: 0, opacity: 0 },
  });

  const stageTransitions = useTransition(currentStage, {
    from: { position: "absolute", y: 250, x: 0, opacity: 0 },
    enter: { x: 0, y: 0, opacity: 1, width: "100%" },
    leave: { y: -250, x: 0, opacity: 0 },
  });

  const onRegisterSubmit = () => {
    const payload = { name, email, password, useCase, channelName };
    if (currentStage < 3) {
      const registerErrors = validate(
        payload,
        registerValidations[currentStage - 1]
      );

      if (registerErrors) {
        setErrors(registerErrors);
        return;
      }

      setCurrentStage((p) => p + 1);
      return;
    }

    const registerErrors = validate(payload, registerValidations[2]);

    if (registerErrors) {
      setErrors(registerErrors);
      return;
    }

    dispatch(authActions.signupAsyncActions.request(payload));
  };

  const onLoginSubmit = () => {
    const loginErrors = validate({ email, password }, loginValidations);

    // console.log("ERRORS", loginErrors);

    if (loginErrors) {
      setErrors(loginErrors);
      return null;
    }
    setLoading(true);
    dispatch(authActions.loginAsyncActions.request({ email, password }));
  };

  const onTestPilotAccountSubmit = () => {
    const loginErrors = validate(
      { email, password: "zjdshvsljdvbksdv" },
      loginValidations
    );

    // console.log("ERRORS", loginErrors);

    if (loginErrors) {
      setErrors(loginErrors);
      return null;
    }
    dispatch(
      authActions.createTestPilotAsyncActions.request({
        data: { email },
        callback: () => {
          setCurrentScreen(1);
          setCurrentStage(1);
        },
      })
    );
  };

  useEffect(() => {
    setErrors({});
  }, [name, email, password, useCase, channelName]);

  useEffect(() => {
    if (!authState.isLoading && loading) setLoading(false);
  }, [authState.isLoading]);

  useEffect(() => {
    if (authState.loginSuccess) {
      history.push(`/dashboard`);
    }
  }, [authState]);

  return (
    <RootContainer>
      <BannerContainer>
        <BannerTitle>Did you know?</BannerTitle>
        <BannerSubtitle>
          80% of visitors drop off after watching the first 10 secs of your
          video! Videos with chapters increase your video watch time by a
          whopping 220%
        </BannerSubtitle>
      </BannerContainer>
      <FormContainer>
        <SectionSelectorContainer>
          <SectionSelector
            onClick={() => {
              setCurrentScreen(1);
              setCreateTestPilot(false);
            }}
            selected={currentScreen === 1}
          >
            Sign in
          </SectionSelector>
          <SectionSelector
            onClick={() => setCurrentScreen(2)}
            selected={currentScreen === 2}
          >
            Sign up
          </SectionSelector>
        </SectionSelectorContainer>
        {screenTransitions((style, item) =>
          item === 1 ? (
            <animated.div style={style}>
              <SectionContainer>
                <SectionTitle1>Sign in</SectionTitle1>
                <Input
                  onChange={setEmail}
                  placeholder="example@domain.com"
                  type="email"
                  name="email"
                />
                <Errors>{errors.email && errors.email[0]}</Errors>
                <VerticalSpace count={2} />
                <Input
                  onChange={setPassword}
                  placeholder="*********"
                  type="password"
                  name="password"
                />
                <Errors>{errors.password && errors.password[0]}</Errors>
                <VerticalSpace count={2} />
                {loading ? (
                  <SquareLoader size={35} color="#138DCA" />
                ) : (
                  <Button onClick={onLoginSubmit}>Sign in</Button>
                )}
              </SectionContainer>
            </animated.div>
          ) : createTestPilot ? (
            <animated.div style={style}>
              <SectionContainer>
                <SectionTitle>Test drive ChapterMe</SectionTitle>
                <VerticalSpace count={1} />
                <TestPilotContainer>
                  Let us know your email ID and we will create an account for
                  you. You will recieve a email from <b>support@chapterme.co</b>{" "}
                  with login credentials. Please check spam too.
                </TestPilotContainer>
                <VerticalSpace count={1} />
                <Input
                  onChange={setEmail}
                  placeholder="example@domain.com* (required)"
                  type="email"
                  name="email"
                />
                <Errors>{errors.email && errors.email[0]}</Errors>
                <VerticalSpace count={2} />
                {authState.isLoading ? (
                  <SquareLoader size={35} color="#138DCA" />
                ) : (
                  <>
                    <Button onClick={onTestPilotAccountSubmit}>Create</Button>
                    &nbsp;
                    <Button onClick={() => setCreateTestPilot(false)}>
                      Back
                    </Button>
                  </>
                )}
              </SectionContainer>
            </animated.div>
          ) : (
            <animated.div style={style}>
              <SectionContainer>
                <SectionTitle>Create Account</SectionTitle>
                <VerticalSpace count={1} />
                <TestPilotContainer>
                  Experience our app without creating an account.
                  <br />
                  <br />
                  <Button onClick={() => setCreateTestPilot(true)}>
                    Test drive ChapterMe
                  </Button>
                </TestPilotContainer>
                <VerticalSpace count={2} />
                <VerticalSpace count={1} />
                <CircularStepsContainer>
                  <CircularStepsContainerPassLine />
                  <RoundedStepNumberContainer
                    clickable={currentStage > 1}
                    onClick={() =>
                      currentStage > 1 ? setCurrentStage(1) : null
                    }
                    highlight={currentStage === 1}
                  >
                    1
                  </RoundedStepNumberContainer>
                  <RoundedStepNameContainer highlight={currentStage === 1}>
                    <RoundedStepName>Basic Details</RoundedStepName>
                  </RoundedStepNameContainer>
                  <RoundedStepNumberContainer
                    clickable={currentStage > 2}
                    onClick={() =>
                      currentStage > 2 ? setCurrentStage(2) : null
                    }
                    highlight={currentStage === 2}
                  >
                    2
                  </RoundedStepNumberContainer>
                  <RoundedStepNameContainer highlight={currentStage === 2}>
                    <RoundedStepName>Use Case</RoundedStepName>
                  </RoundedStepNameContainer>
                  <RoundedStepNumberContainer
                    clickable={currentStage > 3}
                    onClick={() =>
                      currentStage > 3 ? setCurrentStage(3) : null
                    }
                    highlight={currentStage === 3}
                  >
                    3
                  </RoundedStepNumberContainer>
                  <RoundedStepNameContainer highlight={currentStage === 3}>
                    <RoundedStepName>Create Channel</RoundedStepName>
                  </RoundedStepNameContainer>
                </CircularStepsContainer>
                {stageTransitions((style, stage) =>
                  stage === 1 ? (
                    <animated.div style={style}>
                      <VerticalSpace count={1} />
                      <Input
                        onChange={setName}
                        placeholder="John Doe* (Cannot contain special characters or numbers)"
                        label="Name"
                      />
                      <Errors>{errors.name && errors.name[0]}</Errors>
                      <VerticalSpace count={1} />
                      <Input
                        onChange={setEmail}
                        placeholder="example@domain.com* (required)"
                        type="email"
                        name="email"
                      />
                      <Errors>{errors.email && errors.email[0]}</Errors>
                      <VerticalSpace count={1} />
                      <Input
                        onChange={setPassword}
                        placeholder="*********"
                        type="password"
                        name="password"
                      />
                      <Errors>{errors.password && errors.password[0]}</Errors>
                      <VerticalSpace count={1} />
                      <Button onClick={onRegisterSubmit}>Next</Button>
                      <VerticalSpace count={5} />
                    </animated.div>
                  ) : stage === 2 ? (
                    <animated.div style={style}>
                      <UseCaseWrapper>
                        <UseCaseContainer
                          onClick={() =>
                            setUseCase((p) => ({ ...p, video: !p.video }))
                          }
                          selected={useCase.video}
                        >
                          <UseCaseTitle selected={useCase.video}>
                            Chapters
                          </UseCaseTitle>
                          <UseCaseSubtitle selected={useCase.video}>
                            Add timestamped chapters for your videos
                          </UseCaseSubtitle>
                        </UseCaseContainer>
                        <UseCaseContainer
                          onClick={() =>
                            setUseCase((p) => ({ ...p, trailer: !p.trailer }))
                          }
                          selected={useCase.trailer}
                        >
                          <UseCaseTitle selected={useCase.trailer}>
                            Trailers
                          </UseCaseTitle>
                          <UseCaseSubtitle selected={useCase.trailer}>
                            Auto-create short trailers from your long videos
                          </UseCaseSubtitle>
                        </UseCaseContainer>
                        <UseCaseContainer
                          onClick={() =>
                            setUseCase((p) => ({
                              ...p,
                              video: !p.video,
                              trailer: !p.video,
                            }))
                          }
                          selected={useCase.video && useCase.trailer}
                        >
                          <UseCaseTitle
                            selected={useCase.video && useCase.trailer}
                          >
                            All
                          </UseCaseTitle>
                          <UseCaseSubtitle
                            selected={useCase.video && useCase.trailer}
                          >
                            I want to do both
                          </UseCaseSubtitle>
                        </UseCaseContainer>
                      </UseCaseWrapper>
                      <Errors>{errors.useCase && errors.useCase[0]}</Errors>
                      <Button onClick={onRegisterSubmit}>Next</Button>
                      <VerticalSpace count={5} />
                    </animated.div>
                  ) : (
                    <animated.div style={style}>
                      <VerticalSpace count={1} />
                      <Input
                        onChange={setChannelName}
                        placeholder={`${name}'s Channel`}
                        label="Channel Name"
                      />
                      <Errors>
                        {errors.channelName && errors.channelName[0]}
                      </Errors>
                      <VerticalSpace count={2} />
                      {authState.isLoading ? (
                        <SquareLoader size={35} color="#138DCA" />
                      ) : (
                        <Button onClick={onRegisterSubmit}>Finish</Button>
                      )}
                      <VerticalSpace count={5} />
                    </animated.div>
                  )
                )}
              </SectionContainer>
            </animated.div>
          )
        )}
      </FormContainer>
    </RootContainer>
  );
};

export default Auth;
