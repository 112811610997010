import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
	RootContainer,
	TitleImage,
	TrailerTitle,
	Flex,
	TrailerDescription,
	LoginRequestContainer,
	LoginRequest,
	Button,
	InputContainer,
	FieldContainer
} from './Trailer.style.js';

export default () => {

	const authState = useSelector(state => state.authReducer);
	const history = useHistory();

	const [uploadType, setUploadType] = useState(undefined);
	const [selectedFile, setSelectedFile] = useState({selected: false});

	const openDialog = () => {
		let dialog = document.getElementById('file_dialog');
		dialog.click();
		dialog.onchange = () => {
		   var fileList = dialog.files;
		   setSelectedFile({
		   	selected: true,
		   	file: fileList[0]
		   })
		   // console.log(fileList[0]);
		}
	}

	// useEffect(() => {
	// 	if (!authState.loginSuccess)
	// 		history.replace('/panel');
	// }, []);

	return (
		<RootContainer id='root-container' >
			{
				authState.loginSuccess
				?
				uploadType === undefined?
				<InputContainer>
					<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
					<TrailerDescription>A small striking words</TrailerDescription>
					<Button onClick={() => setUploadType('single')} >Single video</Button>
					<Button onClick={() => setUploadType('bulk')} >Bulk upload</Button>
				</InputContainer>
				:
					uploadType === 'single'
					?
					<InputContainer>
						<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
						<TrailerTitle>Single video</TrailerTitle>
						<FieldContainer>
							Video Id: <input type="text" />
						</FieldContainer>
						<FieldContainer>
							Start: <input type="text" size='1' minLength='1' maxLength='4' placeholder='seconds' />
							End: <input type="text" size='1' minLength='1' maxLength='4' placeholder='seconds' />
						</FieldContainer>
						<Flex>
							<Button onClick={() => setUploadType(undefined)} >Cancel</Button>
							<Button>Submit</Button>
						</Flex>
					</InputContainer>
					:
					<InputContainer>
						<TitleImage src="https://chapterme.co/assets/img/logo-white.svg" onClick={() => window.open("https://chapterme.co", "_blank") }/>
						<TrailerTitle>Bulk upload</TrailerTitle>
						<Flex>
							<Button onClick={() => {setUploadType(undefined); setSelectedFile({selected: false})}} >Cancel</Button>
							{
								selectedFile.selected
								?<Button onClick={() => {}} >Submit</Button>
								:<Button onClick={() => openDialog()} >Choose a file</Button>
							}
							{/*<Button>Submit</Button>*/}
						</Flex>
						<input id='file_dialog' type='file' accept='.xls, .csv' style={{display: 'none'}} multiple={false} />
					</InputContainer>
				:
				<LoginRequestContainer>
					<LoginRequest>You must login to create a trailer</LoginRequest>					
					<Button onClick={() => {history.replace('/login')}} >Login</Button>
				</LoginRequestContainer>
			}			
		</RootContainer>
	);
}