import {useState, useEffect, useRef} from 'react';
import moment from 'moment';
import {useSelector, shallowEqual} from 'react-redux';
import {
	SummaryCard,
	SummaryTitle,
	SummaryDescription,
	SummaryCardTop,
	SummaryCardBottom,
	SummaryCardLeft,
	SummaryCardRight,
	SummaryTime,
	EditChapterBtn,
	DeleteChapterBtn,
	BtnContainerOnEdit,
	SaveBtn,
	CancelBtn,
	SummaryCardTopBar,
	SummaryCardTopBarLeftPiece,
	SummaryCardTopBarRightPiece,
	SummaryCardButtonContainer,
	SummaryInput,
	SummaryDescriptionInput,
	InputContainer,
	InputLabel,
	AddChapterButton,
	CopyContentContainer,
	CopyIconContainer,
	CopyTextContainer,
	CopyTextHeading,
	CopyTextContent,
} from './Garage.styles';
import { Modal} from 'components';
import DurationInput from './DurationInput'
import {toast} from 'react-toastify';
import { MdEdit, MdDelete, MdShare } from "react-icons/md";
import { BiLink, BiPaste } from "react-icons/bi";
import copy from 'clipboard-copy';
import './duration-styles.css';

let shiftPressed = false;

const Summary = ({summary, videoId, setCurrentTime, currentTime, summaryArray, index, updateChapterContent, onSaveEdit, onEditSummary, onDeleteSummary, onChangesCancel, currentChapterCard, isMutated, isLoggedIn}) => {
	const [selected, setSelected] = useState(false)
	const titleInputRef = useRef(null)
	const descriptionInputRef = useRef(null)
	const durationInputRef = useRef(null)
	const [title, setTitle] = useState('');
	const [titleFocused, setTitleFocused] = useState(false);
	const [description, setDescription] = useState('');
	const [descriptionFocused, setDescriptionFocused] = useState(false);
	const [durationFocused, setDurationFocused] = useState(false);
	const [showDialog, setShowDialog] = useState(false);
	const playerState = useSelector(state => state.playerReducer, shallowEqual)

	useEffect(() => {
		if(summaryArray.length === 1) {
			setSelected(true);
		}
		else if(index === summaryArray.length-1 && summaryArray[index].start <= playerState.currentPlayingTime) {
			setSelected(true);
		}
		else if(summaryArray[index].start <= playerState.currentPlayingTime && summaryArray[index+1].start > playerState.currentPlayingTime) {
			setSelected(true);
		}
		else {
			setSelected(false)
		}
	}, [summary.start, playerState]);



	const onSave = () => {
		const {type, ...restOfSummary} = summary;
		const editedSummary = {...restOfSummary, title, description}
		onSaveEdit(index, editedSummary);
	}

	// const onEnterPress = () => {
	// 	if(titleFocused && descriptionInputRef && descriptionInputRef.current)
	// 		descriptionInputRef.current.focus()
	// 	if(descriptionFocused && durationInputRef && durationInputRef.current)
	// 		durationInputRef.current.focus()
	// 	if(durationFocused && descriptionInputRef && descriptionInputRef.current)
	// 		onSave()
	// }

	const onKeyPress = e => {
		if (e.key === 'Shift')
			shiftPressed = true;
		else if(e.key === "Escape")
			onChangesCancel()
		else if(e.key === "Enter" && !shiftPressed)
			onSave()
	}

	const onCopyLink = () => {
		if(isMutated) {
			toast.warning('Save changes to enable sharing', {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
			});
			return;
		}
		const link = `https://api.chapterme.co/share/video/${videoId}/chapter/${summary.start}`
		copy(link)
		toast.success('Chapter link copied', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	const onCopyText = () => {
		const text = `${moment.duration(summary.start*1000).format("hh:mm:ss", {trim: false})} - ${summary.title}: ${summary.description}`
		copy(text);
		toast.success('Full chapter copied successfully', {
			position: "top-right",
			autoClose: 5000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	}

	const autoFocusOnMount = () => {
		if(titleInputRef && titleInputRef.current)
			titleInputRef.current.focus()
	}

	useEffect(() => {
		setTimeout(autoFocusOnMount, 200)
		setTitle(summary.title?summary.title:summary.summary);
		setDescription(summary.description);
	}, [summary.type]);

	useEffect(() => {
		if (selected)
		{
			let y = document.getElementById('current-chapter').offsetTop - 105;
			document.getElementById('garage-summarylistcontainer').scrollTo({
				top: y,
				behavior: 'smooth'
			});
		}
	}, [selected])

	if(summary.type === 'INSERT' || summary.type === 'EDIT') {
		return (
			<SummaryCard id={selected?'current-chapter':null} focused={selected} className="summary-card" ref={selected?currentChapterCard:null} onKeyDown={onKeyPress} onKeyUp={(e) => {if (e.key === 'Shift') shiftPressed = false;}} >
				<div style={{flex: 1}} >
					<SummaryTitle>
						<SummaryInput ref={titleInputRef} onFocus={() => setTitleFocused(true)} onBlur={() => setTitleFocused(false)}  placeholder="Chapter title" value={title || ''} onChange={evt => setTitle(evt.target.value)} />
					</SummaryTitle>
					<DurationInput
						onFocus={() => setDurationFocused(true)} onBlur={() => setDurationFocused(false)} 
						value={ summary.start }
						onChange={ new_duration => updateChapterContent(index, 'start', new_duration) }
					/>
					<SummaryDescription >
						<SummaryDescriptionInput onFocus={() => setDescriptionFocused(true)} onBlur={() => setDescriptionFocused(false)}  value={description} placeholder="Chapter description" onChange={evt => setDescription(evt.target.value)} />
						<SummaryCardTopBarLeftPiece> &nbsp; &nbsp;<b>Shift+Enter</b> for a new line</SummaryCardTopBarLeftPiece>
					</SummaryDescription>
					<BtnContainerOnEdit>
						<SaveBtn onClick={onSave}>Enter</SaveBtn>
						<CancelBtn onClick={() => onChangesCancel()}>Cancel (Esc)</CancelBtn>
					</BtnContainerOnEdit>
				</div>
			</SummaryCard>
		)
	}

	return (
		<SummaryCard id={selected?'current-chapter':null} focused={selected} className="summary-card" ref={selected?currentChapterCard:null} onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >

			<SummaryCardTop>
				<SummaryCardLeft>
					<SummaryTime onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >
						{
							moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })
						} 	{summary.subpoints && summary.subpoints.length ? summary.subpoints.length + ' Subpoints' : null} 
					</SummaryTime>
				</SummaryCardLeft>
				<SummaryCardRight>
					{/*<EditChapterBtn onClick={() => onEditSummary(index)}>Edit</EditChapterBtn>
					<DeleteChapterBtn onClick={() => onDeleteSummary(index)}>Delete</DeleteChapterBtn>*/}
					<EditChapterBtn  onClick={() => onEditSummary(index)} />
					<DeleteChapterBtn onClick={() => onDeleteSummary(summary.start)} />
				</SummaryCardRight>
			</SummaryCardTop>
			<SummaryCardBottom>
				<SummaryTitle onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}}>
					{
						summary.title
						?
						summary.title
						:
						summary.summary
					}
				</SummaryTitle>
				<SummaryDescription onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}}>
					{
						summary.description
						?
						summary.description.substring(0, 100)+'...'
						:
						null
					}
				</SummaryDescription>
			</SummaryCardBottom>
			{/*<SummaryCardTopBar>
				<SummaryCardTopBarLeftPiece onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >
					{
						moment.duration(summary.start, "seconds").format("hh:mm:ss", { trim: false })
					} 	{summary.subpoints && summary.subpoints.length ? summary.subpoints.length + ' Subpoints' : null} 
				</SummaryCardTopBarLeftPiece>
				<SummaryCardTopBarRightPiece>
					{
						isLoggedIn
						?
						<SummaryCardButtonContainer>
							<MdShare color='#99aab5' onClick={onCopyLink} />
						</SummaryCardButtonContainer>
						:
						null
					}
					<SummaryCardButtonContainer onClick={() => onEditSummary(index)} >
						<MdEdit color='#99aab5' />
					</SummaryCardButtonContainer>
					<SummaryCardButtonContainer onClick={() => onDeleteSummary(index)} >
						<MdDelete color='#99aab5' />
					</SummaryCardButtonContainer>
				</SummaryCardTopBarRightPiece>
			</SummaryCardTopBar>
			<SummaryTitle onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >
				{
					summary.title
					?
					summary.title
					:
					summary.summary
				}
			</SummaryTitle>
			<SummaryDescription onClick={() => {setCurrentTime(summary.start===currentTime?currentTime+1:summary.start);}} >
				{
					summary.description
					?
					summary.description.substring(0, 100)+'...'
					:
					null
				}
			</SummaryDescription>*/}
			{/*
				selected
				?
				summary.subpoints && summary.subpoints.length
				?
				summary.subpoints.map(subpoint => (
					<SummaryCard onClick={() => setCurrentTime(subpoint.start===currentTime?currentTime+1:subpoint.start)} >
						<SummaryCardTopBar>
							<SummaryCardTopBarLeftPiece>
								{
									moment.duration(subpoint.start, "seconds").format("hh:mm:ss", { trim: false })
								}
							</SummaryCardTopBarLeftPiece>
							<SummaryCardTopBarRightPiece />
						</SummaryCardTopBar>
						<SummaryDescription>
							{
								subpoint.title
							}
						</SummaryDescription>
					</SummaryCard>
				))
				:
				null
				:
				null
			}*/}
			{
				showDialog
				?
				<Modal close={() => setShowDialog(false)} >
					<CopyContentContainer onClick={onCopyLink} >
						<CopyIconContainer>
							<BiLink color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Link</CopyTextHeading>
							<CopyTextContent>This link opens player with that specific time and chapter</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
					<CopyContentContainer onClick={onCopyText} >
						<CopyIconContainer>
							<BiPaste color='#7289da' size={36} />
						</CopyIconContainer>
						<CopyTextContainer>
							<CopyTextHeading>Copy Chapter Text</CopyTextHeading>
							<CopyTextContent>Paste this chapter at your video description</CopyTextContent>
						</CopyTextContainer>
					</CopyContentContainer>
				</Modal>
				:
				null
			}
		</SummaryCard>
	)
}

export default Summary;